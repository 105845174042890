<template>
  <svg
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i10)">
      <path
        d="M6 4.02762V18.7488C6 20.4412 7.97125 21.3686 9.27514 20.2895L18.5558 12.609C19.549 11.787 19.5166 10.2539 18.4895 9.47478L9.20877 2.43424C7.89172 1.4351 6 2.37447 6 4.02762Z"
        :fill="color"
      />
    </g>
    <g filter="url(#filter1_i10)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.70882 20.8014C0.765021 20.8014 -8.09954e-05 20.128 -8.09129e-05 19.2973L-7.93443e-05 3.5041C-7.92617e-05 2.6734 0.765023 1.99999 1.70882 1.99999C2.65262 1.99999 3.41773 2.6734 3.41773 3.5041L3.41772 19.2973C3.41772 20.128 2.65262 20.8014 1.70882 20.8014Z"
        :fill="color"
      />
    </g>
    <defs>
      <filter
        id="filter0_i10"
        x="5"
        y="2.02399"
        width="14.2807"
        height="20.7286"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
      <filter
        id="filter1_i10"
        x="-1.00012"
        y="2"
        width="4.41781"
        height="20.8014"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
    </defs>
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "play-pause",
  mixins: [IconMixin],
});
</script>
