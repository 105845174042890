<template>
  <svg
    width="38"
    height="60"
    viewBox="0 0 38 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.34473 52.6093C2.34458 29.647 4.85643 17.1875 9.31854 8.1228"
      :stroke="color"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.5701 55.259C33.57 44.0298 34.1899 36.9606 36.372 32.5278"
      :stroke="color"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.1912 57.0719C26.191 34.1096 27.0282 28.0651 33.57 16.4432"
      :stroke="color"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5728 55.2591C10.5728 29.8781 12.9445 13.8407 23.2642 2.26581"
      :stroke="color"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5216 58.1877C17.6849 34.6196 19.4978 16.7693 34.8392 2.26581"
      :stroke="color"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "eyelashes",
  mixins: [IconMixin],
});
</script>
