<template>
  <svg
    width="75"
    height="28"
    viewBox="0 0 75 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="3" cy="23.2267" r="1" :fill="color" />
    <circle cx="3" cy="27.2267" r="1" :fill="color" />
    <circle cx="7" cy="27.2267" r="1" :fill="color" />
    <circle cx="5" cy="21.2267" r="1" :fill="color" />
    <circle cx="1" cy="21.2267" r="1" :fill="color" />
    <circle cx="7" cy="23.2267" r="1" :fill="color" />
    <circle cx="9" cy="21.2267" r="1" :fill="color" />
    <circle cx="11" cy="23.2267" r="1" :fill="color" />
    <circle cx="13" cy="21.2267" r="1" :fill="color" />
    <circle cx="3" cy="19.2267" r="1" :fill="color" />
    <circle cx="5" cy="17.2267" r="1" :fill="color" />
    <circle cx="1" cy="17.2267" r="1" :fill="color" />
    <circle cx="7" cy="19.2267" r="1" :fill="color" />
    <circle cx="9" cy="17.2267" r="1" :fill="color" />
    <circle cx="11" cy="19.2267" r="1" :fill="color" />
    <circle cx="13" cy="17.2267" r="1" :fill="color" />
    <circle cx="15" cy="23.2267" r="1" :fill="color" />
    <circle cx="1" cy="25.2267" r="1" :fill="color" />
    <circle cx="5" cy="25.2267" r="1" :fill="color" />
    <circle cx="9" cy="25.2267" r="1" :fill="color" />
    <circle cx="13" cy="25.2267" r="1" :fill="color" />
    <circle cx="17" cy="21.2267" r="1" :fill="color" />
    <circle cx="20" cy="21.2267" r="1" :fill="color" />
    <circle cx="15" cy="19.2267" r="1" :fill="color" />
    <circle cx="17" cy="17.2267" r="1" :fill="color" />
    <circle cx="19" cy="19.2267" r="1" :fill="color" />
    <circle cx="21" cy="17.2267" r="1" :fill="color" />
    <circle cx="23" cy="19.2267" r="1" :fill="color" />
    <circle cx="25" cy="17.2267" r="1" :fill="color" />
    <circle cx="3" cy="15.2267" r="1" :fill="color" />
    <circle cx="5" cy="13.2267" r="1" :fill="color" />
    <circle cx="7" cy="15.2267" r="1" :fill="color" />
    <circle cx="9" cy="13.2267" r="1" :fill="color" />
    <circle cx="11" cy="15.2267" r="1" :fill="color" />
    <circle cx="13" cy="13.2267" r="1" :fill="color" />
    <circle cx="3" cy="11.2267" r="1" :fill="color" />
    <circle cx="5" cy="9.22675" r="1" :fill="color" />
    <circle cx="7" cy="11.2267" r="1" :fill="color" />
    <circle cx="9" cy="9.22675" r="1" :fill="color" />
    <circle cx="11" cy="11.2267" r="1" :fill="color" />
    <circle cx="13" cy="9.22675" r="1" :fill="color" />
    <circle cx="15" cy="15.2267" r="1" :fill="color" />
    <circle cx="17" cy="13.2267" r="1" :fill="color" />
    <circle cx="19" cy="15.2267" r="1" :fill="color" />
    <circle cx="21" cy="13.2267" r="1" :fill="color" />
    <circle cx="23" cy="15.2267" r="1" :fill="color" />
    <circle cx="25" cy="13.2267" r="1" :fill="color" />
    <circle cx="15" cy="11.2267" r="1" :fill="color" />
    <circle cx="17" cy="9.22675" r="1" :fill="color" />
    <circle cx="19" cy="11.2267" r="1" :fill="color" />
    <circle cx="21" cy="9.22675" r="1" :fill="color" />
    <circle cx="23" cy="11.2267" r="1" :fill="color" />
    <circle cx="25" cy="9.22675" r="1" :fill="color" />
    <circle cx="27" cy="15.2267" r="1" :fill="color" />
    <circle cx="29" cy="13.2267" r="1" :fill="color" />
    <circle cx="31" cy="15.2267" r="1" :fill="color" />
    <circle cx="33" cy="13.2267" r="1" :fill="color" />
    <circle cx="37" cy="13.2267" r="1" :fill="color" />
    <circle cx="27" cy="11.2267" r="1" :fill="color" />
    <circle cx="29" cy="9.22675" r="1" :fill="color" />
    <circle cx="31" cy="11.2267" r="1" :fill="color" />
    <circle cx="33" cy="9.22675" r="1" :fill="color" />
    <circle cx="35" cy="11.2267" r="1" :fill="color" />
    <circle cx="37" cy="9.22675" r="1" :fill="color" />
    <circle cx="41" cy="13.2267" r="1" :fill="color" />
    <circle cx="39" cy="11.2267" r="1" :fill="color" />
    <circle cx="41" cy="9.22675" r="1" :fill="color" />
    <circle cx="43" cy="11.2267" r="1" :fill="color" />
    <circle cx="45" cy="9.22675" r="1" :fill="color" />
    <circle cx="47" cy="11.2267" r="1" :fill="color" />
    <circle cx="49" cy="9.22675" r="1" :fill="color" />
    <circle cx="73" cy="21.2267" r="1" :fill="color" />
    <circle cx="69" cy="17.2267" r="1" :fill="color" />
    <circle cx="71" cy="19.2267" r="1" :fill="color" />
    <circle cx="73" cy="17.2267" r="1" :fill="color" />
    <circle cx="53" cy="13.2267" r="1" :fill="color" />
    <circle cx="57" cy="13.2267" r="1" :fill="color" />
    <circle cx="61" cy="13.2267" r="1" :fill="color" />
    <circle cx="51" cy="11.2267" r="1" :fill="color" />
    <circle cx="53" cy="9.22675" r="1" :fill="color" />
    <circle cx="55" cy="11.2267" r="1" :fill="color" />
    <circle cx="57" cy="9.22675" r="1" :fill="color" />
    <circle cx="59" cy="11.2267" r="1" :fill="color" />
    <circle cx="61" cy="9.22675" r="1" :fill="color" />
    <circle cx="63" cy="15.2267" r="1" :fill="color" />
    <circle cx="65" cy="13.2267" r="1" :fill="color" />
    <circle cx="67" cy="15.2267" r="1" :fill="color" />
    <circle cx="69" cy="13.2267" r="1" :fill="color" />
    <circle cx="71" cy="15.2267" r="1" :fill="color" />
    <circle cx="63" cy="11.2267" r="1" :fill="color" />
    <circle cx="65" cy="9.22675" r="1" :fill="color" />
    <circle cx="67" cy="11.2267" r="1" :fill="color" />
    <circle cx="75" cy="19.2267" r="1" :fill="color" />
    <circle cx="11" cy="7.22675" r="1" :fill="color" />
    <circle cx="7" cy="7.22675" r="1" :fill="color" />
    <circle cx="15" cy="7.22675" r="1" :fill="color" />
    <circle cx="19" cy="7.22675" r="1" :fill="color" />
    <circle cx="23" cy="7.22675" r="1" :fill="color" />
    <circle cx="43" cy="7.22675" r="1" :fill="color" />
    <circle cx="47" cy="7.22675" r="1" :fill="color" />
    <circle cx="51" cy="7.22675" r="1" :fill="color" />
    <circle cx="55" cy="7.22675" r="1" :fill="color" />
    <circle cx="59" cy="7.22675" r="1" :fill="color" />
    <circle cx="63" cy="7.22675" r="1" :fill="color" />
    <circle cx="27" cy="7.22675" r="1" :fill="color" />
    <circle cx="31" cy="7.22675" r="1" :fill="color" />
    <circle cx="35" cy="7.22675" r="1" :fill="color" />
    <circle cx="39" cy="7.22675" r="1" :fill="color" />
    <circle cx="13" cy="5.22675" r="1" :fill="color" />
    <circle cx="17" cy="5.22675" r="1" :fill="color" />
    <circle cx="21" cy="5.22675" r="1" :fill="color" />
    <circle cx="25" cy="5.22675" r="1" :fill="color" />
    <circle cx="45" cy="5.22675" r="1" :fill="color" />
    <circle cx="49" cy="5.22675" r="1" :fill="color" />
    <circle cx="53" cy="5.22675" r="1" :fill="color" />
    <circle cx="57" cy="5.22675" r="1" :fill="color" />
    <circle cx="61" cy="5.22675" r="1" :fill="color" />
    <circle cx="29" cy="5.22675" r="1" :fill="color" />
    <circle cx="23" cy="3.22675" r="1" :fill="color" />
    <circle cx="19" cy="3.22675" r="1" :fill="color" />
    <circle cx="27" cy="3.22675" r="1" :fill="color" />
    <circle cx="31" cy="3.22675" r="1" :fill="color" />
    <circle cx="35" cy="3.22675" r="1" :fill="color" />
    <circle cx="39" cy="3.22675" r="1" :fill="color" />
    <circle cx="43" cy="3.22675" r="1" :fill="color" />
    <circle cx="47" cy="3.22675" r="1" :fill="color" />
    <circle cx="51" cy="3.22675" r="1" :fill="color" />
    <circle cx="55" cy="3.22675" r="1" :fill="color" />
    <circle cx="59" cy="3.22675" r="1" :fill="color" />
    <circle cx="29" cy="1.22675" r="1" :fill="color" />
    <circle cx="33" cy="1.22675" r="1" :fill="color" />
    <circle cx="37" cy="1.22675" r="1" :fill="color" />
    <circle cx="41" cy="1.22675" r="1" :fill="color" />
    <circle cx="45" cy="1.22675" r="1" :fill="color" />
    <circle cx="49" cy="1.22675" r="1" :fill="color" />
    <circle cx="53" cy="1.22675" r="1" :fill="color" />
    <circle cx="33" cy="5.22675" r="1" :fill="color" />
    <circle cx="37" cy="5.22675" r="1" :fill="color" />
    <circle cx="41" cy="5.22675" r="1" :fill="color" />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "eyebrows2",
  mixins: [IconMixin],
});
</script>
