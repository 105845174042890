<template>
  <div :class="'roller-picker'" :style="cssVars">
    <slot :sensitivity="100"> </slot>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RollerPicker extends Vue {
  get cssVars(): any {
    return {
      "--color": this.$store.getters.currentThemeShade.color,
    };
  }
}
</script>

<style lang="scss">
.select-wrap {
  ul {
    list-style: none;
  }
  position: relative;
  // top: 200px;
  height: 100%;
  // perspective: 1200px;
  text-align: center;
  overflow: hidden;
  font-size: 20px;
  color: #ddd;
  &:before,
  &:after {
    position: absolute;
    z-index: 1;
    display: block;
    content: "";
    width: 100%;
    height: 50%;
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }

  .select-options {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
    transform-style: preserve-3d;
    margin: 0 auto;
    display: block;
    transform: translateZ(-150px) rotateX(0deg);
    -webkit-font-smoothing: subpixel-antialiased;
    color: #666;
    .select-option {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;

      -webkit-font-smoothing: subpixel-antialiased;
      @for $i from 1 through 100 {
        &:nth-child(#{$i}) {
          transform: rotateX(-18deg * ($i - 1)) translateZ(150px);
        }
      }
    }
  }
}

.highlight {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  // background-color: black;
  font-size: 30px;
  opacity: 1;
  overflow: hidden;
  @supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
    margin-top: -1px;
    letter-spacing: -2px; 
  }
}

.highlight-list {
  // display: none;
  position: absolute;
  width: 100%;
}

/* date */
.roller-picker {
  perspective: 2000px;
  align-items: stretch;
  justify-content: space-between;
  // height: 200px;
  height: 25vh;
  width: calc_width(85);
  display: flex;
  > div {
    flex: 1;
  }

  .select-wrap {
    font-size: 5vh;
    font-family: Jura-Regular;
    font-weight: bold;
    height: 19vh;
    // ul li {
    //   // opacity: 0.5;
    // }
  }

  .roller {
    .select-wrap {
      color: var(--color);
      .select-options .select-option {
        color: var(--color);
        font-weight: initial;
      }
    }
  }

  .highlight {
    font-size: 32px;
  }
}
.v-application {
  .roller-picker ul {
    padding-left: 0px;
    margin-top: -2px;
  }
}

.v-application.cordova {
  .roller-picker {
    ul {
      margin-top: 0px;
    }
    .select-wrap, .highlight {
      font-size: 32px;
    }
  }
}
</style>
