<template>
  <svg
    preserveAspectRatio="none"
    height="100%"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    fill-rule="evenodd"
    clip-rule="evenodd"
  >
  <g
     filter="url(#filter0_i5)"
     id="g46"
     transform="translate(1.00085,6.10352e-5)">
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5339,3.50391 c 0.5523,0 1,0.44771 1,1 V 26.682 c 0,3.617 -3.3164,6.318 -7.1048,6.318 -3.79055,0 -7.09841,-2.7089 -7.09841,-6.318 0,-0.0096 1.4e-4,-0.0193 4.2e-4,-0.0289 0.04186,-1.4445 0.59335,-2.8263 1.55504,-3.9269 0.96075,-1.0994 2.27441,-1.8544 3.72658,-2.1564 0.54072,-0.1124 1.07021,0.2348 1.18264,0.7755 C 9.90781,21.886 9.56062,22.4155 9.01991,22.5279 7.98037,22.7441 7.05719,23.2812 6.39216,24.0423 5.7314,24.7984 5.36207,25.7327 5.33072,26.6964 5.33981,28.9482 7.48493,31 10.4291,31 c 2.9527,0 5.1048,-2.0565 5.1048,-4.318 V 4.50391 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path1" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 10.9151,16.0803 c -0.6,-0.1508 -1.21874,-0.2269 -1.84037,-0.2262 H 9.07354 c -3.69616,0 -6.44997,2.5986 -6.45295,5.4997 0.00847,0.8136 0.2169,1.6167 0.61192,2.3461 0.39595,0.7312 0.96965,1.3702 1.67972,1.8628 0.45377,0.3149 0.56642,0.9379 0.25161,1.3917 C 4.84902,27.4082 4.22596,27.5208 3.77218,27.206 2.81125,26.5393 2.02281,25.6661 1.47382,24.6523 0.924665,23.6382 0.63146,22.5134 0.620634,21.368 l -9e-5,-0.0095 h 4.5e-5 c 0,-4.251 3.910311,-7.5041 8.452351,-7.5044 0.78604,-9e-4 1.56916,0.0954 2.32966,0.2866 0.5357,0.1346 0.8607,0.678 0.7261,1.2136 -0.1347,0.5356 -0.678,0.8607 -1.2136,0.726 z M 9.07294,13.8541 c 2e-4,0 4e-4,0 6e-4,0 v 1 l -0.0012,-1 c 2e-4,0 4e-4,0 6e-4,0 z"
       :fill="color"
       id="path2" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5338,3.80212 c 0.5523,0 1,0.44772 1,1 V 26.9743 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.80212 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path3" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4839,3.80212 c 0.5523,0 1,0.44772 1,1 V 26.9743 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.80212 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path4" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4839,3.50391 c 0.5523,0 1,0.44771 1,1 V 26.682 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.50391 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path5" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4839,3.80212 c 0.5523,0 1,0.44772 1,1 V 26.9743 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.80212 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path6" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5338,3.80212 c 0.5523,0 1,0.44772 1,1 V 26.9743 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.80212 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path7" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.3897,3.21234 c 0.5523,0 1,0.44772 1,1 V 26.3845 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.21234 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path8" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.434,3.21234 c 0.5523,0 1,0.44772 1,1 V 26.3845 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.21234 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path9" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.434,2.91412 c 0.5523,0 1,0.44772 1,1 V 26.0922 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 3.91412 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path10" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.434,3.21234 c 0.5523,0 1,0.44772 1,1 V 26.3845 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.21234 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path11" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.3897,3.21234 c 0.5523,0 1,0.44772 1,1 V 26.3845 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.21234 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path12" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 15.7273,1.33532 c 1.0895,1.04749 1.7065,2.56101 1.8051,4.09447 0.0354,0.55115 -0.3827,1.02665 -0.9338,1.06207 C 16.0474,6.52728 15.5719,6.1092 15.5365,5.55805 15.4649,4.44444 15.0173,3.42715 14.3411,2.777 13.6946,2.15535 12.8041,1.82765 11.6561,2.09235 11.6443,2.09507 11.6325,2.09757 11.6206,2.09986 10.7541,2.2668 9.97918,2.69747 9.41112,3.31439 8.84395,3.93035 8.51382,4.69753 8.46013,5.49548 8.45757,5.5336 8.45282,5.57155 8.44591,5.60913 8.33732,6.19989 8.38561,6.80721 8.58772,7.3779 8.70153,7.69927 8.64378,8.05648 8.43449,8.32561 8.22521,8.59473 7.89323,8.7387 7.55373,8.70755 6.2102,8.58429 4.87628,8.96708 3.84047,9.76093 2.80678,10.5532 2.15705,11.6864 2.01054,12.9086 c -0.10214,0.8797 0.605,1.9176 1.98585,2.6309 0.49069,0.2535 0.683,0.8567 0.42955,1.3474 -0.25346,0.4907 -0.85671,0.683 -1.3474,0.4295 C 1.43598,16.468 -0.225679,14.8118 0.0242144,12.6751 l 3.032e-4,-0.0026 C 0.237101,10.8946 1.17816,9.28153 2.62386,8.17352 3.71845,7.33462 5.03827,6.83338 6.41114,6.71316 6.36908,6.24484 6.38795,5.77147 6.46879,5.30402 6.56558,4.0606 7.08534,2.88765 7.93984,1.95964 8.80251,1.02277 9.95787,0.387276 11.2245,0.139425 c 1.818,-0.413376 3.3874,0.123396 4.5028,1.195895 z"
       :fill="color"
       id="path13" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4452,3.09241 c 0.5523,0 1,0.44771 1,1 V 26.2705 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.09241 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path14" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.3897,4.0174 c 0.5523,0 1,0.44771 1,1 v 22.1781 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 5.0174 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path15" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.434,4.0174 c 0.5523,0 1,0.44771 1,1 v 22.1781 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 5.0174 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path16" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.434,3.72479 c 0.5523,0 1,0.44772 1,1 V 26.8969 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.72479 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path17" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.434,4.0174 c 0.5523,0 1,0.44771 1,1 v 22.1781 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 5.0174 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path18" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.3897,4.0174 c 0.5523,0 1,0.44771 1,1 v 22.1781 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 5.0174 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path19" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5338,3.42651 c 0.5523,0 1,0.44772 1,1 V 26.6046 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.42651 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path20" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4839,3.42651 c 0.5523,0 1,0.44772 1,1 V 26.6046 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.42651 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path21" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4839,3.13501 c 0.5523,0 1,0.44771 1,1 V 26.3072 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.13501 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path22" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4839,3.42651 c 0.5523,0 1,0.44772 1,1 V 26.6046 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.42651 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path23" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.434,3.50391 c 0.5523,0 1,0.44771 1,1 V 26.682 c 0,2.2615 2.1521,4.318 5.1048,4.318 2.9442,0 5.0893,-2.0518 5.0984,-4.3036 -0.0314,-0.9637 -0.4007,-1.898 -1.0615,-2.6541 -0.665,-0.7611 -1.5882,-1.2982 -2.6277,-1.5144 -0.5407,-0.1124 -0.8879,-0.6419 -0.7755,-1.1826 0.1125,-0.5407 0.6419,-0.8879 1.1827,-0.7755 1.4521,0.302 2.7658,1.057 3.7265,2.1564 0.9617,1.1006 1.5132,2.4824 1.5551,3.9269 3e-4,0.0096 4e-4,0.0193 4e-4,0.0289 0,3.6091 -3.3079,6.318 -7.0984,6.318 -3.7884,0 -7.1048,-2.701 -7.1048,-6.318 V 4.50391 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path24" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 21.5638,14.1407 c 0.7606,-0.1912 1.5437,-0.2875 2.3297,-0.2866 4.5409,3e-4 8.4587,3.2524 8.4587,7.5044 v 0.0107 h -1e-4 c -0.0122,1.1458 -0.3068,2.2706 -0.8571,3.2845 -0.5501,1.0136 -1.3396,1.8865 -2.3013,2.5527 -0.454,0.3145 -1.077,0.2014 -1.3915,-0.2526 -0.3145,-0.454 -0.2014,-1.077 0.2526,-1.3915 0.7109,-0.4924 1.2855,-1.1314 1.6824,-1.8627 0.3959,-0.7294 0.6054,-1.5325 0.615,-2.3464 -0.0034,-2.8999 -2.7622,-5.4991 -6.4593,-5.4991 h -0.0012 c -0.6216,-7e-4 -1.2403,0.0754 -1.8403,0.2262 -0.5356,0.1347 -1.079,-0.1904 -1.2136,-0.726 -0.1347,-0.5356 0.1904,-1.079 0.726,-1.2136 z m 2.3297,-0.2866 c 2e-4,0 4e-4,0 6e-4,0 l -0.0012,1 v -1 c 2e-4,0 4e-4,0 6e-4,0 z"
       :fill="color"
       id="path25" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.434,3.80212 c 0.5523,0 1,0.44772 1,1 V 26.9743 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.80212 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path26" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4839,3.80212 c 0.5523,0 1,0.44772 1,1 V 26.9743 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.80212 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path27" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4839,3.50391 c 0.5523,0 1,0.44771 1,1 V 26.682 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.50391 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path28" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4839,3.80212 c 0.5523,0 1,0.44772 1,1 V 26.9743 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.80212 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path29" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.434,3.80212 c 0.5523,0 1,0.44772 1,1 V 26.9743 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.80212 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path30" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5781,3.21234 c 0.5522,0 1,0.44772 1,1 V 26.3845 c 0,0.5523 -0.4478,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.21234 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path31" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5338,3.21234 c 0.5523,0 1,0.44772 1,1 V 26.3845 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.21234 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path32" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5338,2.91412 c 0.5523,0 1,0.44772 1,1 V 26.0922 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 3.91412 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path33" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5338,3.21234 c 0.5523,0 1,0.44772 1,1 V 26.3845 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.21234 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path34" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5781,3.21234 c 0.5522,0 1,0.44772 1,1 V 26.3845 c 0,0.5523 -0.4478,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.21234 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path35" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 18.6352,2.77852 c -0.6787,0.64969 -1.1261,1.66603 -1.1976,2.7795 -0.0355,0.55115 -0.511,0.96923 -1.0621,0.93382 -0.5512,-0.03542 -0.9692,-0.51093 -0.9338,-1.06207 0.0985,-1.5336 0.7158,-3.04806 1.8105,-4.09601 1.1214,-1.073416 2.6985,-1.606963 4.529,-1.194304 1.2648,0.250269 2.4177,0.887194 3.278,1.824444 0.8512,0.92738 1.3686,2.09822 1.4649,3.33903 0.082,0.46757 0.102,0.94121 0.0609,1.40993 1.374,0.11975 2.695,0.62111 3.7904,1.46064 1.4454,1.10778 2.3864,2.7204 2.5992,4.4979 0.2592,2.1417 -1.4153,3.7987 -3.0539,4.645 -0.4907,0.2535 -1.0939,0.0612 -1.3474,-0.4295 -0.2534,-0.4907 -0.0611,-1.094 0.4296,-1.3474 1.3853,-0.7156 2.0921,-1.7533 1.9862,-2.628 l -2e-4,-0.0016 C 30.8427,11.6873 30.1929,10.5534 29.1588,9.76091 28.123,8.96705 26.7891,8.58426 25.4455,8.70752 25.1065,8.73862 24.775,8.59512 24.5656,8.32667 24.3563,8.05822 24.2979,7.7017 24.4106,7.38048 24.6111,6.80945 24.6577,6.20215 24.5475,5.61174 24.5403,5.57311 24.5354,5.5341 24.5328,5.49489 24.4796,4.69823 24.1509,3.93206 23.5857,3.31631 23.0197,2.6996 22.2471,2.26817 21.3825,2.09936 21.3722,2.09734 21.3619,2.09517 21.3517,2.09284 20.1837,1.82635 19.2842,2.15729 18.6352,2.77852 Z"
       :fill="color"
       id="path36" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5338,3.09241 c 0.5523,0 1,0.44771 1,1 V 26.2705 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.09241 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path37" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5781,4.0174 c 0.5522,0 1,0.44771 1,1 v 22.1781 c 0,0.5523 -0.4478,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 5.0174 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path38" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5338,4.0174 c 0.5523,0 1,0.44771 1,1 v 22.1781 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 5.0174 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path39" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5338,3.72479 c 0.5523,0 1,0.44772 1,1 V 26.8969 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.72479 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path40" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5338,4.0174 c 0.5523,0 1,0.44771 1,1 v 22.1781 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 5.0174 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path41" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.5781,4.0174 c 0.5522,0 1,0.44771 1,1 v 22.1781 c 0,0.5523 -0.4478,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 5.0174 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path42" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.434,3.42651 c 0.5523,0 1,0.44772 1,1 V 26.6046 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.42651 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path43" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4839,3.42651 c 0.5523,0 1,0.44772 1,1 V 26.6046 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.42651 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path44" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4839,3.13501 c 0.5523,0 1,0.44771 1,1 V 26.3072 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.13501 c 0,-0.55229 0.4477,-1 1,-1 z"
       :fill="color"
       id="path45" />
    <path
       fill-rule="evenodd"
       clip-rule="evenodd"
       d="m 16.4839,3.42651 c 0.5523,0 1,0.44772 1,1 V 26.6046 c 0,0.5523 -0.4477,1 -1,1 -0.5523,0 -1,-0.4477 -1,-1 V 4.42651 c 0,-0.55228 0.4477,-1 1,-1 z"
       :fill="color"
       id="path46" />
  </g>
  <defs
     id="defs47">
    <filter
       id="filter0_i5"
       x="-1.00085"
       y="-6.10352e-05"
       width="34.002102"
       height="34.000099"
       filterUnits="userSpaceOnUse"
       color-interpolation-filters="sRGB">
      <feFlood
         flood-opacity="0"
         result="BackgroundImageFix"
         id="feFlood46" />
      <feBlend
         mode="normal"
         in="SourceGraphic"
         in2="BackgroundImageFix"
         result="shape"
         id="feBlend46" />
      <feColorMatrix
         in="SourceAlpha"
         type="matrix"
         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
         result="hardAlpha"
         id="feColorMatrix46" />
      <feOffset
         dx="-1"
         dy="1"
         id="feOffset46" />
      <feGaussianBlur
         stdDeviation="0.5"
         id="feGaussianBlur46" />
      <feComposite
         in2="hardAlpha"
         operator="arithmetic"
         k2="-1"
         k3="1"
         id="feComposite46"
         k1="0"
         k4="0" />
      <feColorMatrix
         type="matrix"
         values="0 0 0 0 0.566667 0 0 0 0 0.000944495 0 0 0 0 0.544262 0 0 0 0.45 0"
         id="feColorMatrix47" />
      <feBlend
         mode="normal"
         in2="shape"
         result="effect1_innerShadow"
         id="feBlend47" />
    </filter>
  </defs>
</svg>

</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "brain",
  mixins: [IconMixin]
});
</script>
