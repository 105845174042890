<template>
  <div class="rotor-main">
    <div class="roller-container">
      <div class="rotor-bg">
        <div ref="rotor1-bg" class="rotor1-bg" :style="activeRotor == 1
            ? 'visibility: initial;'
            : 'visibility: hidden'
          " />
        <div class="rotor2-bg" ref="rotor2-bg" :style="activeRotor == 2
            ? 'visibility: initial;'
            : 'visibility: hidden'
          " />
        <div ref="rotor3-bg" class="rotor3-bg" :style="activeRotor == 3
            ? 'visibility: initial;'
            : 'visibility: hidden'
          " />
      </div>
      <rollerPickerBox :key="rotorKey" class="rotor1-box" :class="activeRotor == 1 && 'active'">
        <rollerPicker ref="rotor1" class="rotor1" @touchstart="activeRotor = 1" :data="values" :index="r1Index"
          @change="roller1OnChange" :layer="false" :options="options"></rollerPicker>
      </rollerPickerBox>
      <rollerPickerBox :key="rotorKey" class="rotor2-box" :class="activeRotor == 2 && 'active'">
        <rollerPicker ref="rotor2" class="rotor2" @touchstart="activeRotor = 2" :data="values" :index="r2Index"
          @change="roller2OnChange" :layer="false" :options="options"></rollerPicker>
      </rollerPickerBox>
      <rollerPickerBox :key="rotorKey" class="rotor3-box" :class="activeRotor == 3 && 'active'">
        <rollerPicker :key="rotorKey" ref="rotor3" class="rotor3" @touchstart="activeRotor = 3" :data="values"
          :index="r3Index" @change="roller3OnChange" :layer="false" :options="options"></rollerPicker>
      </rollerPickerBox>
    </div>
    <div class="fanContainer">
      <div ref="fan1" class="fan1Container" v-on:click="rotorStart(1)" :class="getFanClass(1)">
        <fan color="#207676" />
      </div>
      <div ref="fan2" class="fan2Container" v-on:click="rotorStart(2)" :class="getFanClass(2)">
        <fan color="#6F6B19" />
      </div>
      <div ref="fan2" class="fan3Container" v-on:click="rotorStart(3)" :class="getFanClass(3)">
        <fan color="#6C211D" />
      </div>
    </div>
    <transition name="fade">
      <img v-if="direction" class="toogle-direction" v-on:click="toogleDirection(false)"
        src="/i/r/rotate_right_btn-l.png" />
      <img v-else class="toogle-direction" v-on:click="toogleDirection(true)" src="/i/r/rotate_left_btn-l.png" />
    </transition>
    <div class="timer">
      <timer :active="$store.state.pedalOn" />
    </div>
    <div class="clock">
      <clock />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { RotorMixin } from "./RotorMixin";


export default Vue.extend({
  name: "RotorBlack",
  mixins: [RotorMixin],
});
</script>

<style lang="scss">
$rotor-height: 470px;

.theme-rotor-white {


  .rotor-main {
    max-width: 430px;
  }


  .roller-container {
    @media (orientation: landscape) {
      width: 40vw !important;
    }

    @media (orientation: landscape) and (min-height: 700px) {
      padding-top: 110px;
    }
  }

  .fanContainer .active {
    margin: 2px;
  }

  .roller-picker-item {
    font-size: 35px;
    padding-right: 3px;
  }

  .rotor1-box {
    .roller-picker-box-select {
      border-top: 2px solid #207676;
      border-bottom: 2px solid #207676;
    }
  }

  .rotor2-box {
    .roller-picker-box-select {
      border-top: 2px solid #6F6B19;
      border-bottom: 2px solid #6F6B19;
    }
  }

  .rotor3-box {
    .roller-picker-box-select {
      border-top: 2px solid #6C211D;
      border-bottom: 2px solid #6C211D;
    }
  }

  .roller-picker-box {
    margin-top: 0vh;

    @media (orientation: portrait) and (min-height: 670px) {
      margin-top: 10vh;
    }
  }

  .presets-icon {
    display: none;
  }

  .gear-bg {
    background-image: none;
  }

  &.v-application.portrait {
    background-image: url(/i/r/bg-light.svg);
    background-size: cover;
  }

  &.v-application.landscape {
    background-image: url(/i/r/bg-h-light.svg);
    background-size: cover;
  }

  .toogle-direction {
    height: 11vw;
    top: 72vh;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;

    @media (orientation: landscape) {
      height: 12vh;
      top: 36%;
      left: 13%;
    }
  }

  .roller-container {
    width: 70vw;
    max-width: 400px;
    justify-content: center;
    display: flex;

    @media (orientation: landscape) {
      top: 0px;
      position: fixed;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    .rotor1 .roller-picker-item {
      color: #207676 !important;
    }

    .rotor2 .roller-picker-item {
      color: #6f6b19 !important;
    }

    .rotor3 .roller-picker-item {
      color: #6c211d !important;
    }

    .rotor-bg {
      position: fixed;
      top: 0px;
      justify-content: space-between;
      width: 70vw;
      max-width: 400px;

      @media screen and (max-width: 330px) {
        width: 75vw;
      }

      display: flex;
      height: 410px;

      @media (orientation: landscape) {
        height: 245px;
      }

      margin-left: 2px;

      .rotor1-bg,
      .rotor2-bg,
      .rotor3-bg {
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        // @media (orientation: landscape) {
        //   background-size: cover;
        //   background-position: center bottom;
        // }
        background-size: cover;
        background-position: center bottom;

        @media (orientation: landscape) and (min-height: 760px) {
          height: 400px !important;
        }
      }

      .rotor1-bg {
        background-image: url(/i/r/picker1-bgl.svg);
      }

      .rotor2-bg {
        background-image: url(/i/r/picker2-bgl.svg);
      }

      .rotor3-bg {
        background-image: url(/i/r/picker3-bgl.svg);
      }

      @media (orientation: landscape) {

        width: 40vw;
        
        .rotor1-bg {
          background-image: url(/i/r/picker1-bgl-l.svg);
        }

        .rotor2-bg {
          background-image: url(/i/r/picker2-bgl-l.svg);
        }

        .rotor3-bg {
          background-image: url(/i/r/picker3-bgl-l.svg);
        }
      }

      @media (orientation: portrait) and (min-height: 1000px) {
        height: 55vh;
      }
    }
  }

  .fanContainer {
    width: 100%;
    display: flex;
    margin-top: 6vh;
    text-align: center;
    justify-content: space-around;

    div {
      width: 100%;
      display: flex;
    }

    .fan1Container.active {
      background: linear-gradient(180deg,
          rgba(255, 255, 255, 0.7) -30%,
          rgba(145, 242, 242, 0.7) 13.68%,
          rgba(96, 238, 238, 0.7) 79.19%,
          rgba(54, 199, 199, 0.7) 116.43%);
    }

    .fan2Container.active {
      background: linear-gradient(180deg,
          rgba(255, 255, 255, 0) -27.86%,
          rgba(228, 216, 100, 0.7) 19.6%,
          rgba(242, 218, 88, 0.7) 86.57%,
          rgba(255, 241, 103, 0) 120%);
    }

    .fan3Container.active {
      background: linear-gradient(360deg,
          rgba(255, 225, 234, 0.7) -22.14%,
          rgba(255, 121, 162, 0.7) 23.5%,
          rgba(255, 133, 176, 0.7) 71.16%,
          rgba(255, 255, 255, 0.7) 115.71%);
    }

    .active {
      background-blend-mode: lighten;
      box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50%;

      svg {
        -webkit-animation: spin 1s linear infinite;
        -moz-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
      }

      &.spin-back {
        svg {
          -webkit-animation: spin-back 1s linear infinite;
          -moz-animation: spin-back 1s linear infinite;
          animation: spin-back 1s linear infinite;
        }
      }

      // width: 12vh;
      // height: 12vh;
    }

    @media (orientation: landscape) {
      width: 40vw;
      justify-content: space-between;
      margin: auto;
      bottom: 4vh;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    svg {
      height: calc(4vh + 5vw);
      width: calc(4vh + 5vw);
      margin: auto;
    }
  }

  .timer,
  .clock {
    left: 0px;
    bottom: 10vh;
    color: #1f1f1f;
    width: 35vw;
    position: absolute;
    font-size: 6vw;
    text-align: center;
    font-family: Jura-Bold;
    border-bottom: 1px solid;

    @media (orientation: landscape) {
      font-size: 4vw;
      width: 20vw;
    }
  }

  .clock {
    right: 0px;
    left: auto;
  }

  @media screen and (orientation: portrait) and (aspect-ratio: 390 / 844) {
    .roller-container {
      .rotor-bg div{
        margin: -7px;
      }
    }
    .fanContainer {
      height: 94px!important;
    }
  }

  @media screen and (orientation: portrait) and (min-aspect-ratio: 1024 / 1366) {
    .roller-container {
      .rotor-bg {
        width: 60vw;
        height: 90vh;
      }

      .roller-picker {
        width: 60vw;
        height: 31vh;
      }
    }

    .fanContainer {
      width: 60vw;
      max-width: 400px;
    }

    .toogle-direction {
      bottom: 10vh;
      top: auto;
    }
  }
}

.cordova-app {
  body {
    width: var(--app-width) !important;
    height: 100vh;

    .v-application {
      height: 100vh;
    }
  }
}
</style>