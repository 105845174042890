<template>
  <svg
    viewBox="0 0 39 39"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.56511 32.3687C0.597167 26.4542 0.588947 20.926 1.34111 17.6379C2.43441 12.8701 7.26795 7.9996 13.9346 4.21825C18.8709 1.41924 21.3904 1 21.3904 1L24.6375 7.21867C24.6375 7.21867 17.6543 10.215 14.4032 13.3551C11.152 16.4953 10.1122 19.619 10.778 22.3687C11.2877 24.4731 13.0633 25.9075 13.0633 25.9075"
      stroke="url(#paint0_linear)"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.9349 4.21825L18.0204 10.6589"
      stroke="url(#paint1_linear)"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.69418 31.4891C11.5717 37.4899 17.104 37.531 20.3962 36.7953C25.1681 35.7267 30.0674 30.9219 33.8857 24.2758C36.7135 19.3559 37.1451 16.8405 37.1451 16.8405L30.947 13.5565C30.947 13.5565 27.9137 20.5232 24.753 23.7538C21.5923 26.9844 18.6083 28.2051 15.8668 27.5228C13.7665 27.0008 12.1965 25.0238 12.1965 25.0238"
      stroke="url(#paint2_linear)"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.8854 24.28L27.4654 20.1575"
      stroke="url(#paint3_linear)"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="12.8187"
        y1="1"
        x2="12.8187"
        y2="54.0855"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop offset="1" :stop-color="color" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="15.9777"
        y1="4.21825"
        x2="15.9777"
        y2="15.1177"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop offset="1" :stop-color="color" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="21.4196"
        y1="13.5565"
        x2="21.4196"
        y2="53.4343"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop offset="1" :stop-color="color" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="30.6754"
        y1="20.1575"
        x2="30.6754"
        y2="27.134"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop offset="1" :stop-color="color" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "horseshoe",
  mixins: [IconMixin],
});
</script>
