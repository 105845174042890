<template>
  <svg
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 109 108"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M25.15 17.07C33.55 17.07 41.45 22.25 44.51 30.59C45.66 33.72 45.99 36.95 45.63 40.05C43.69 41.19 42.1 42.76 40.93 44.58C37.66 42.53 33.85 41.39 29.93 41.39C27.58 41.39 25.19 41.8 22.85 42.65C20.23 43.61 17.93 45.05 16 46.83C14.7 48.03 13.1 48.59 11.53 48.59C9.02001 48.59 6.60002 47.14 5.67002 44.48C5.62002 44.35 5.58002 44.22 5.54002 44.08C2.24002 33.99 7.37001 22.77 17.16 18.67C19.78 17.58 22.49 17.07 25.15 17.07ZM25.15 13.07C21.88 13.07 18.67 13.72 15.62 15C3.88 19.91 -2.21 33.24 1.74 45.34C1.79 45.5 1.84 45.65 1.9 45.81C3.34 49.94 7.12001 52.61 11.53 52.61C14.19 52.61 16.74 51.61 18.71 49.79C20.31 48.32 22.16 47.18 24.23 46.43C26.07 45.75 27.99 45.41 29.93 45.41C33.06 45.41 36.13 46.3 38.81 47.98L42.16 50.09L44.3 46.75C45.17 45.4 46.3 44.31 47.66 43.51L49.37 42.5L49.6 40.53C50.05 36.68 49.6 32.87 48.26 29.22C44.72 19.56 35.43 13.07 25.15 13.07Z"
      ></path>
      <path
        d="M70.65 3.99998C78.76 3.99998 86.52 8.83998 89.78 16.62C94.35 27.54 88.86 39.94 77.87 43.97C75.54 44.83 73.15 45.23 70.8 45.23C70 45.23 69.2 45.18 68.41 45.09C67.27 43.15 65.7 41.56 63.88 40.39C67.16 35.17 68.09 28.54 65.8 22.31C64.84 19.69 63.4 17.39 61.62 15.46C58.51 12.09 59.64 6.63999 63.97 5.12998C64.1 5.07998 64.23 5.03998 64.37 4.99998C66.44 4.31998 68.55 3.99998 70.65 3.99998ZM70.65 -1.52588e-05C68.09 -1.52588e-05 65.56 0.399985 63.13 1.19998C62.97 1.24998 62.82 1.29998 62.66 1.35998C59.43 2.48998 57.01 5.13999 56.18 8.45998C55.32 11.9 56.26 15.54 58.69 18.18C60.16 19.78 61.3 21.63 62.05 23.7C63.83 28.56 63.27 33.87 60.5 38.28L58.39 41.63L61.73 43.77C63.08 44.64 64.17 45.77 64.97 47.13L65.98 48.84L67.95 49.07C68.9 49.18 69.86 49.24 70.81 49.24C73.69 49.24 76.54 48.73 79.26 47.73C85.63 45.39 90.63 40.68 93.35 34.45C96.06 28.23 96.11 21.35 93.48 15.07C89.69 6.05998 80.52 -1.52588e-05 70.65 -1.52588e-05Z"
      ></path>
      <path
        d="M97.76 58.77C100.27 58.77 102.69 60.22 103.62 62.88C103.67 63.01 103.71 63.14 103.75 63.28C107.05 73.37 101.92 84.59 92.13 88.69C89.51 89.79 86.8 90.3 84.14 90.3C75.74 90.3 67.84 85.12 64.78 76.78C63.63 73.65 63.3 70.42 63.66 67.32C65.6 66.18 67.19 64.61 68.36 62.79C71.63 64.84 75.44 65.98 79.36 65.98C81.71 65.98 84.1 65.57 86.44 64.72C89.06 63.76 91.36 62.32 93.29 60.54C94.59 59.34 96.19 58.77 97.76 58.77ZM97.76 54.77C95.1 54.77 92.55 55.77 90.58 57.59C88.98 59.06 87.13 60.2 85.06 60.95C83.22 61.63 81.3 61.97 79.36 61.97C76.23 61.97 73.16 61.08 70.48 59.4L67.13 57.29L64.99 60.63C64.12 61.98 62.99 63.07 61.63 63.87L59.92 64.88L59.69 66.85C59.24 70.7 59.69 74.51 61.03 78.16C64.57 87.82 73.87 94.31 84.15 94.31C87.42 94.31 90.63 93.66 93.68 92.38C105.42 87.47 111.51 74.14 107.56 62.04C107.51 61.88 107.46 61.73 107.4 61.57C105.95 57.44 102.17 54.77 97.76 54.77Z"
      ></path>
      <path
        d="M38.72 62.77C39.52 62.77 40.32 62.82 41.11 62.91C42.25 64.85 43.82 66.44 45.64 67.61C42.36 72.83 41.43 79.46 43.72 85.69C44.68 88.31 46.12 90.61 47.9 92.54C51.01 95.91 49.88 101.36 45.55 102.87C45.42 102.92 45.29 102.96 45.15 103C43.08 103.68 40.97 104 38.87 104C30.76 104 23 99.16 19.74 91.38C15.17 80.46 20.66 68.06 31.65 64.03C33.99 63.18 36.37 62.77 38.72 62.77ZM38.72 58.77C35.84 58.77 32.99 59.28 30.27 60.28C23.9 62.62 18.9 67.33 16.18 73.56C13.47 79.78 13.42 86.66 16.05 92.94C19.83 101.94 29 108 38.88 108C41.44 108 43.97 107.6 46.4 106.8C46.56 106.75 46.71 106.7 46.87 106.64C50.1 105.51 52.52 102.86 53.35 99.54C54.21 96.1 53.27 92.46 50.84 89.82C49.37 88.22 48.23 86.37 47.48 84.3C45.7 79.44 46.26 74.13 49.03 69.72L51.14 66.37L47.8 64.23C46.45 63.36 45.36 62.23 44.56 60.87L43.55 59.16L41.58 58.93C40.63 58.83 39.67 58.77 38.72 58.77Z"
      ></path>
      <path
        d="M54.64 68.31C62.7365 68.31 69.3 61.7465 69.3 53.65C69.3 45.5535 62.7365 38.99 54.64 38.99C46.5435 38.99 39.98 45.5535 39.98 53.65C39.98 61.7465 46.5435 68.31 54.64 68.31Z"
        stroke-width="5"
        stroke-miterlimit="10"
        :stroke = "color"
        fill="none"
      ></path>
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "fan",
  mixins: [IconMixin],
});
</script>
