<template>
  <div>
    <div style="display: none">
      <sinus
        ref="sin"
        :s1="s1"
        :s2="s2"
        :s3="s3"
        :s4="s4"
        :sinWidth="20"
        :showAxis="false"
      />
    </div>
    <img class="curve-left" src="/i/f/curve-left.png" />
    <img class="curve-right" src="/i/f/curve-right.png" />
    <div class="indicator-button-group">
      <div class="indicator-container">
        <div class="indicator-picker">
          <icon name="freq" />
          <picker
            theme="black"
            from="1"
            to="150"
            :value="freq"
            :onChange="
              (val) => {
                freqValue = val;
              }
            "
          />
        </div>
        <div class="indicator-picker">
          <icon name="duty" />
          <picker
            theme="black"
            from="1"
            to="100"
            :value="duty"
            :onChange="
              (val) => {
                dutyValue = val;
              }
            "
          />
        </div>
        <div class="indicator-picker">
          <icon name="level" />
          <picker
            theme="black"
            from="1"
            to="240"
            :value="level"
            :onChange="(val) => changeLevel(val)"
          />
        </div>
      </div>
    </div>
    <div class="start-button-group">
      <div class="start-button-container1">
        <div
          class="start-button-container2"
          :class="startButton1Active && 'active'"
          v-on:click="startButtonClick(0)"
        >
          <div class="start-button-container3">
            <icon v-if="startButton1Active" name="pause" style="height: 5vh; width: 4vh;"/>
            <icon v-else name="play" style="height: 5vh; width: 4vh;"/>
          </div>
        </div>
      </div>
      <div class="start-button-container1 right">
        <div
          class="start-button-container2"
          :class="startButton2Active && 'active'"
          v-on:click="startButtonClick(1)"
        >
          <div class="start-button-container3">
            <icon name="pepette" style="height: 5vh; width: 4vh;"/>
          </div>
        </div>
      </div>
    </div>
    <div class="slider-box">
      <div style="width: 40%">
        <slider :max="100" :min="1" v-model="duty1Value">
          <div class="thumb">
            <icon name="duty1" />
            <div />
          </div>
        </slider>
      </div>
      <div style="width: 40%">
        <slider :max="100" :min="0" v-model="duty2Value">
          <div class="thumb">
            <icon name="duty2" />
            <div />
          </div>
        </slider>
      </div>
      <div style="width: 20%">
        <div style="padding-bottom: 11vh"></div>
        <icon name="scale" style="height: 62.7vh; margin-top: 1vh;"/>
      </div>
      <div></div>
    </div>
    <div class="right-toogle-group">
      <div
        class="toogle-button-container left"
        v-on:click="InductionRotorToogle(0)"
        :class="fanActive == 0 && 'active'"
      >
        <icon name="fan" :color="fanActive === 0 && '#fbdd73'" />
      </div>
      <div
        class="toogle-button-container right"
        v-on:click="InductionRotorToogle(1)"
        :class="fanActive == 1 && 'active'"
      >
        <icon name="horseshoe" :color="fanActive === 1 && '#fbdd73'" />
      </div>
    </div>
    <div class="time-panel">
      <div class="c1">
        <div class="c2"></div>
        <div class="timer">
          <timer :active="startButton1Active" />
        </div>
        <div class="clock"><clock /></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Clock from "../clock.vue";
import Icon from "../icons/Icon.vue";
import Slider from "../slider.vue";
import Timer from "../timer.vue";
import { FalloutMixin } from "./FalloutMixin";
import Picker from "../Picker.vue";



export default Vue.extend({
  name: "FallOutBlack",
  mixins: [FalloutMixin],
  components: { Picker, Icon, Clock, Timer, Slider },
});
</script>

<style lang="scss" >
.v-application.theme-fallout-black.fallout {
  .drawer {
    padding-top: 15vh;
  }
  .v-btn--icon.v-size--default {
    height: inherit;
    width: inherit;
  }
  .settings-icon,
  .presets-icon {
    margin: 2.5vh 3vh 3vh 3vh;
    @media screen and (min-width: 700px) and (min-height: 400px) {
      margin: 4vh 3vh 3vh 3vh;
    }
  }
  .gear-bg,
  .brain-bg {
    background: radial-gradient(
        81.67% 81.67% at 13.33% 81.67%,
        #313131 0%,
        #2d2d2d 81.68%
      ),
      #c4c4c4;
    border-radius: 50%;
    display: flex;
    height: 10vh;
    width: 10vh;
    @media screen and (min-width: 700px) and (min-height: 400px) {
      height: 7vh;
      width: 7vh;
    }
    padding: initial;
    justify-content: center;
    align-items: center;
    svg {
      margin-left: 0px;
      height: 7vh;
      width: 7vh;
      @media screen and (min-width: 700px) and (min-height: 400px) {
        height: 5vh;
        width: 5vh;
      }
      filter: drop-shadow(0px 0px 4px #f4a11d);
    }
  }

  .indicator-picker svg {
    height: 20px;
    width: 20px;
    @media screen and (min-width: 700px) and (min-height: 500px) {
      height: 4vw;
      width: 4vw;
      margin-bottom: 1vw;
    }
  }

  background-image: url("/i/f/bg-h.png");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  div[name="fallOut-black"] {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
  }
  .v-main__wrap {
    position: initial;
  }
  .curve-left {
    top: 7.34vh;
    width: 55vw;
    height: 100vh;
    position: absolute;
  }
  .curve-right {
    top: 7.34vh;
    right: 0;
    width: 23vw;
    height: 92.7vh;
    position: absolute;
  }
  .indicator-button-group {
    top: 10vh;
    left: 10vw;
    width: 43vw;
    height: 54vh;
    position: absolute;
    .indicator-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      .indicator-picker {
        text-align: center;
      }
      .indicator-button {
        display: flex;
        height: calc(10vw + 27vh);
        width: 10vw;
        margin: 0 1.5vw 0 1.5vw;
        .divider {
          width: 100%;
          display: flex;
          justify-content: center;
          div {
            width: 2px;
            height: 15px;
            background: rgba(0, 0, 0, 0.45);
          }
        }
        .buttons-container {
          height: 100%;
          margin: 0px 10px 2vh;
          background: linear-gradient(360deg, #070707 6%, #121212 93%), #010101;
          border-radius: 7vh;
          .button-up {
            border: 0.4vh solid;
            height: 49%;
            background: linear-gradient(
              -200deg,
              rgba(255, 255, 255, 0.6) -28.18%,
              rgba(181, 181, 181, 0) 37.87%
            );
            touch-action: none;
            border-radius: 7vh 7vh 0px 0px;
            .btn-nested {
              height: calc(100% - 0.2vh);
              position: relative;
              background: linear-gradient(
                  55deg,
                  rgb(40, 40, 40) 4%,
                  rgb(52, 52, 52) 99%
                ),
                rgb(196, 196, 196);
              margin-top: 0.4vh;
              border-radius: 7vh 7vh 0px 0px;
              display: flex;
              justify-content: center;
              &.active {
                background: linear-gradient(-70deg, #202020 9.04%, #151515 86%),
                  #c4c4c4;
                margin-top: 0.1vh;
                height: calc(100% - 0.1vh);
              }
              img {
                width: 8vh;
              }
            }
          }
          .button-down {
            border: 0.4vh solid;
            height: 49%;
            border-radius: 0px 0px 7vh 7vh;
            .btn-nested {
              background: linear-gradient(
                  165deg,
                  rgb(49, 49, 49) 14%,
                  rgb(26, 26, 26) 113%
                ),
                rgb(196, 196, 196);
              box-shadow: rgb(0 0 0 / 65%) 0px 4px 5px;
              border-radius: 0px 0px 7vh 7vh;
              height: 100%;
              display: flex;
              justify-content: center;
              &.active {
                background: linear-gradient(-70deg, #202020 9.04%, #151515 86%),
                  #c4c4c4;
                margin-top: 0.1vh;
                height: calc(100% - 0.1vh);
              }
              img {
                width: 8vh;
              }
            }
          }
        }
        background: linear-gradient(
            180deg,
            #393939 0%,
            rgba(91, 91, 91, 0.570363) 38%,
            rgba(72, 72, 72, 0.41) 100%
          ),
          #000000;
        box-shadow: 0px -2px 4px rgb(122 122 122 / 90%),
          1px 2px 3px rgb(7 7 7 / 95%);
        border-radius: 5px 5px 9vh 9vh;
        flex-direction: column;
        .indicator {
          color: #fbdd73;
          margin: 8px auto 0px;
          width: 9vw;
          font-size: calc(3vh + 3vw);
          background: linear-gradient(224.6deg, #282828 15%, #343434 100%),
            #c4c4c4;
          box-shadow: inset -1px -2px 2px rgb(96 96 96 / 85%),
            inset 2px 2px 3px rgb(7 7 7 / 95%);
          text-align: center;
          font-family: Iceland;
          border-radius: 5px;
          line-height: 13vh;
        }
      }
    }
  }
  .start-button-group {
    bottom: 4vh;
    left: 18vw;
    width: calc(15vw + 20vh);
    height: calc(5vw + 11vh);
    display: flex;
    position: absolute;
    background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0) 2.63%,
        rgba(20, 20, 20, 0.54) 40.81%,
        rgba(0, 0, 0, 0) 96.49%
      ),
      linear-gradient(
        180deg,
        #343434 0%,
        rgba(66, 66, 66, 0.570363) 38.12%,
        rgba(49, 49, 49, 0.41) 100%
      ),
      #000000;
    box-shadow: 0px -2px 4px rgb(82 82 82 / 80%), 1px 2px 3px rgb(7 7 7 / 95%);
    align-items: center;
    border-radius: 40px;
    .start-button-container1 {
      width: 16vh;
      height: 16vh;
      padding: 0.5vh;
      background: linear-gradient(172.41deg, #232323 -5.98%, #252525 98.53%);
      box-shadow: inset 0px 1px 2px 1px rgb(0 0 0 / 75%);
      border-radius: 50%;
      left: 1vw;
      &.right {
        right: 1vw;
        left: auto;
      }
      position: absolute;
      .start-button-container2 {
        height: 100%;
        padding: 5%;
        background: linear-gradient(
            rgb(67, 67, 67) 0%,
            rgb(35, 35, 35) 45.6%,
            rgb(28, 28, 28) 100%
          ),
          rgb(0, 0, 0);
        box-shadow: rgb(0 0 0 / 70%) 0px 4px 4px,
          rgb(14 14 15 / 70%) 0px -3px 9px inset;
        border-radius: 50%;
        .start-button-container3 {
          width: 100%;
          height: 100%;
          display: flex;
          background: radial-gradient(
              100% 100% at 50% 0%,
              #141414 0%,
              #202020 50.71%,
              #2f2f2f 100%
            ),
            linear-gradient(180deg, #222224 0%, #424145 100%), #757575;
          box-shadow: 0px -1px 1px rgb(20 20 20 / 15%);
          align-items: center;
          border-radius: 50%;
          justify-content: center;
        }
        &.active {
          background: radial-gradient(
            134.78% 134.78% at 50% 105.43%,
            rgba(235, 161, 79, 0.8) 0%,
            rgba(251, 221, 115, 0.8) 100%
          );
          box-shadow: rgba(232, 198, 97, 0.9) 0px 0px 4px,
            rgba(0, 0, 0, 0.7) 0px 4px 4px;
        }
      }
    }
  }
  .slider-box {
    top: 4vh;
    left: 56vw;
    width: 20vw;
    height: 73vh;
    display: flex;
    position: absolute;
    justify-content: center;
  }
  .right-toogle-group {
    top: 20vh;
    right: 1vw;
    width: 20vw;
    height: calc(4vh + 7vw);
    display: flex;
    padding: 2px;
    position: absolute;
    background: #000000;
    box-shadow: inset 0px 0px 2px 1px rgb(19 19 19 / 75%);
    align-items: center;
    border-radius: 10vh;
    .toogle-button-container {
      width: 50%;
      height: 100%;
      display: flex;
      background: linear-gradient(
          352.74deg,
          rgb(32, 32, 32) 27.76%,
          rgb(46, 46, 46) 96.1%
        ),
        rgb(196, 196, 196);
      text-align: center;
      align-items: center;
      &.active {
        svg {
          filter: drop-shadow(rgb(244, 161, 29) 0px 0px 4px);
        }
      }
      &.left {
        margin-right: 0.2vw;
        border-radius: 10vh 0px 0px 10vh;
        &.active {
          background: linear-gradient(
                150deg,
                rgb(18, 19, 19) 21.92%,
                rgb(36, 36, 36) 91.34%
              )
              0% 0% repeat scroll,
            none 0% 0% repeat scroll rgb(196, 196, 196);
          box-shadow: none;
        }
      }
      &.right {
        margin-left: 0.2vw;
        border-radius: 0px 10vh 10vh 0px;
        &.active {
          background: linear-gradient(
                150deg,
                rgb(18, 19, 19) 21.92%,
                rgb(36, 36, 36) 91.34%
              )
              0% 0% repeat scroll,
            none 0% 0% repeat scroll rgb(196, 196, 196);
          box-shadow: none;
        }
      }
      justify-content: center;
      box-shadow: rgba(0, 0, 0, 0.65) 0px 5px 6px;
      svg {
        height: 6vh;
      }
    }
  }
  .time-panel {
    bottom: 14vh;
    right: 3vw;
    width: 16vw;
    height: 16vw;
    display: flex;
    position: absolute;
    background: #000000;
    box-shadow: inset 0px 0px 2px 1px rgb(44 44 44 / 45%);
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    .c1 {
      width: 90%;
      height: 90%;
      position: relative;
      background: linear-gradient(180deg, #0f0f0f 0%, #111111 100%);
      box-shadow: 0px 1px 3px rgba(248, 200, 85, 0.9),
        inset 0px 2px 9px 3px rgba(0, 0, 0, 0.45);
      border-radius: 50%;
      .c2 {
        top: 60%;
        right: -10%;
        width: 120%;
        height: 120%;
        position: absolute;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 85.7%,
          rgba(255, 255, 255, 0.52) 204.59%,
          rgba(255, 255, 255, 0.52) 170.59%
        );
        border-radius: 50%;
      }
      .timer {
        top: 50%;
        left: 50%;
        color: rgb(251, 221, 115);
        position: absolute;
        font-size: 3.6vw;
        transform: translate(-50%, -50%);
        font-family: Iceland;
      }
      .clock {
        left: 50%;
        color: rgb(251, 221, 115);
        bottom: 3.5vh;
        position: absolute;
        font-size: 2.1vw;
        transform: translateX(-50%);
        font-family: Iceland;
      }
    }
  }
  .slider-container {
    .slider {
      width: 1.5vw!important;
      height: 65vh;
      margin: 0 auto;
      background: black;
      box-shadow: inset 0px 0px 1px 4px rgb(34 34 34 / 90%);
      border-radius: 18px;
      .rail {
        display: flex;
        justify-content: center;
        // width: 4px !important;
        background: none;
        .track {
          width: 1vw!important;
          background: linear-gradient(270deg, #db6229 -66.67%, #fbdd73 83.33%),
            #83ffc3 !important;
          box-shadow: 0px 0px 7px #f4a11d;
          // margin-left: 2.3px;
          border-radius: 18px;
          margin-bottom: 2px;
        }
      }
      .thumb-container {
        .thumb {
          border-radius: 17px;
          display: flex;
          justify-content: center;
          width: calc(3vw + 5vh);
          height: 4vh;
          top: 50%;
          left: 50%; 
          transform: translate(-50%, 0%);
          position: inherit;
          background: linear-gradient(
            -20deg,
            rgba(0, 0, 0, 0.9) 35.85%,
            rgba(52, 51, 51, 0.9) 72.49%,
            rgba(88, 88, 88, 0.9) 97.08%
          ),
          #404040;
        box-shadow: 1px 5px 7px rgb(0 0 0 / 40%),
          inset -2px -2px 4px 1px rgb(0 0 0 / 55%);
          svg {
            width: 3vh;
            align-self: center;
          }          
        }
      }
    }
    .value {
      color: rgb(251, 221, 115) !important;
      font-family: Iceland;
      padding-bottom: 4vh;
      font-size: 5vh;
      text-align: center;
      height: auto;
      width: inherit;
    }
  }
  .footer {
    .arrow {
      height: 7vh;
      width: 7vh;
      position: absolute;
      z-index: 100;
      left: 27px;
      bottom: 4vh;
    }
    .trash,
    .trash-open {
      bottom: 4vh;
      height: 13vh;
      right: 2vw;
      @media screen and (min-width: 700px) {
        width: 12vh;
      }
    }
    
  }
  .presets {
    background: url(/i/f/bg-h.png);
    background-size: cover;
    background-color: transparent;
    .container-main {
      .list {
        margin-top: 0px;
      }
      .icon-bg {
        background: radial-gradient(
            163% 163% at -28% -74%,
            #595959 0%,
            #000000 93.23%
          ),
          rgba(2, 2, 2, 0.69);
        border: 2px solid #000000;
        box-sizing: border-box;
        box-shadow: inset 0px 0px 3px 3px rgb(0 0 0 / 50%);
        border-radius: 50%;
        height: 50px;
        width: 50px;
      }
    }
    .v-list-item--active {
      .icon-bg,
      .preset {
        box-shadow: 0px 0px 5px #f0bc1f,
          inset 0px 0px 3px 3px rgba(0, 0, 0, 0.5);
      }
    }
    .preset {
      font-family: "Iceland";
      box-shadow: inset 1px 1px 1px rgb(0 0 0 / 50%);
      background: #060606;
      height: 40px;
      border-radius: 50px 50px 50px 50px;
      margin-left: 5px;
    }
  }
  .cordova-app {
    body {
      width: var(--app-width)!important;
      height: 100vh;
    }
  }
}
</style>