<template>
  <div class="settings-item" :class="isWebsite && 'web-site'">
    <h3
      v-if="orientation == 'landscape'"
      style="text-align: center;font-weight"
    >
      {{ $t("themes") }}
    </h3>
    <div class="themes">
      <v-radio-group v-model="$store.state.themes.shade" row>
        <v-radio
          :label="$t('white')"
          value="white"
          v-on:click="themeShadeSelect('white')"
        ></v-radio>
        <v-radio
          :label="$t('black')"
          value="black"
          v-on:click="themeShadeSelect('black')"
        ></v-radio>
      </v-radio-group>
      <!-- <hr /> -->
      <div class="theme-list">
        <div>

          <v-list nav dense style="width: 100%">
            <v-list-item-group active-class="active">
              <v-list-item v-if="!isWebsite && orientation == 'portrait'"
                v-on:click="beginnerThemeSelect"
                :class="
                  $store.state.themes.current == 'beginner'
                    ? 'v-list-item--active'
                    : 'none'
                "
              >
                <div style="margin-right: 10px">
                  <strong style="white-space: nowrap">{{
                    $t("beginner")
                  }}</strong>
                </div>
                <div
                  style="justify-content: flex-end; display: flex; width: 100%"
                >
                  <div
                    @click="
                      dialog = true;
                      dialogTheme = 'beginner';
                      currentHEX =
                        $store.state.themes.beginner[
                          $store.state.themes.shade
                        ].color;
                    "
                    class="color-picker"
                    :style="
                      'background-color:' +
                      $store.state.themes.beginner[
                        $store.state.themes.shade
                      ].color
                    "
                  ></div>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-list nav dense style="width: 100%">
            <v-list-item-group active-class="active">
              <v-list-item
                v-on:click="professionalThemeSelect"
                :class="
                  $store.state.themes.current == 'professional'
                    ? 'v-list-item--active'
                    : 'none'
                "
              >
                <div style="margin-right: 10px">
                  <strong style="white-space: nowrap">{{
                    $t("professional")
                  }}</strong>
                </div>
                <div
                  style="justify-content: flex-end; display: flex; width: 100%"
                >
                  <div
                    @click="
                      dialog = true;
                      dialogTheme = 'professional';
                      currentHEX =
                        $store.state.themes.professional[
                          $store.state.themes.shade
                        ].color;
                    "
                    class="color-picker"
                    :style="
                      'background-color:' +
                      $store.state.themes.professional[
                        $store.state.themes.shade
                      ].color
                    "
                  ></div>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list nav dense style="width: 100%">
            <v-list-item-group active-class="active">
              <v-list-item
                v-on:click="guruThemeSelect"
                :class="
                  $store.state.themes.current == 'guru'
                    ? 'v-list-item--active'
                    : 'none'
                "
              >
                <div style="margin-right: 10px">
                  <strong style="white-space: nowrap">{{ $t("guru") }}</strong>
                </div>
                <div
                  style="justify-content: flex-end; display: flex; width: 100%"
                >
                  <div
                    style="
                      justify-content: flex-end;
                      display: flex;
                      width: 100%;
                    "
                  >
                    <div
                      @click="
                        dialog = true;
                        dialogTheme = 'guru';
                        currentHEX =
                          $store.state.themes.guru[$store.state.themes.shade]
                            .color;
                      "
                      class="color-picker"
                      :style="
                        'background-color:' +
                        $store.state.themes.guru[$store.state.themes.shade]
                          .color
                      "
                    ></div>
                  </div>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list v-if="!isWebsite  && orientation == 'portrait'" nav dense style="width: 100%; float: left;">
            <v-list-item-group active-class="active">
              <v-list-item
                v-on:click="rotorThemeSelect"
                :class="
                  $store.state.themes.current == 'rotor'
                    ? 'v-list-item--active'
                    : 'none'
                "
              >
                <div>
                  <strong>{{ $t("rotor") }}</strong>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list v-if="(isWebsite && orientation == 'landscape') || !isWebsite" nav dense style="width: 100%; float: left;">
            <v-list-item-group active-class="active">
              <v-list-item
                v-on:click="fallOutThemeSelect"
                :class="
                  $store.state.themes.current == 'fallOut'
                    ? 'v-list-item--active'
                    : 'none'
                "
              >
                <div>
                  <strong>{{ $t("fallOut") }}</strong>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
    </div>
    <v-overlay :opacity="0.9" v-model="dialog">
      <v-dialog v-model="dialog" persistent hide-overlay max-width="290">
        <v-card>
          <v-card-title class="text-h5" style="padding-left: 25px">
            {{ $t("selectColor") }}
          </v-card-title>
          <v-card-text style="margin-top: 20px">
            <color-picker
              :hex="currentHEX"
              :onChange="(color) => onColorChange(color)"
            />
          </v-card-text>
          <v-card-actions style="padding: 8px 19px">
            <v-btn color="green darken-1" text @click="dialog = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="applyClick()">
              {{ $t("apply") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import ColorPicker from "../ColorPicker.vue";
import { DeviceOrientationMixin } from "../mixins";
import { isWebSite } from "@/helpers";

export default Vue.extend({
  name: "ThemesSettings",
  mixins: [DeviceOrientationMixin],
  components: {
    ColorPicker,
  },
  data: () => ({
    dialog: false,
    dialogTheme: null,
    currentHEX: "",
    color: null,
    isWebsite: isWebSite()
  }),
  methods: {
    applyClick() {
      if (this.color == null) return;
      this.$store.dispatch("changeThemeColor", [
        this.dialogTheme,
        this.$store.state.themes.shade,
        this.color,
      ]);
      this.dialog = false;
    },
    onColorChange(color: string) {
      this.color = color;
    },
    themeShadeSelect(shade: string) {
      this.$vuetify.theme.dark = shade == "black";
      this.$store.dispatch("changeThemeShade", shade);
    },
    professionalThemeSelect() {
      // console.log("professionalThemeSelect", this.windowWidth);
      // if (this.windowWidth < 800) {
      //   this.lockScreenOrientation("portrait");
      // }
      this.$store.dispatch("changeTheme", "professional");
    },
    beginnerThemeSelect() {
      // console.log("professionalThemeSelect", this.windowWidth);
      // if (this.windowWidth < 800) {
      //   this.lockScreenOrientation("portrait");
      // }
      this.$store.dispatch("changeTheme", "beginner");
    },
    guruThemeSelect() {
      // console.log("guruThemeSelect", this.windowWidth);
      // if (this.windowWidth < 800) {
      //   this.lockScreenOrientation("portrait");
      // }
      this.$store.dispatch("changeTheme", "guru");
    },
    rotorThemeSelect() {
      this.$store.dispatch("changeTheme", "rotor");
    },
    fallOutThemeSelect() {
      this.$store.dispatch("changeTheme", "fallOut");
    },
  },
});
</script>

<style lang="scss" >
#preview {
  background-color: rgb(192, 134, 234);
  position: absolute;
  height: 40px;
  width: 40px;
  top: 10px;
  right: 28px;
}
.color-picker {
  height: 40px;
  width: 40px;
  align-self: center;
  margin: 5px;
  border: 1px solid gray;
}
.settings-item {
  overflow: scroll;
  @media (orientation: landscape) {
    height: 70vh;
  }
}
.web-site {
  overflow: hidden;
}
.settings {
  .themes {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    text-align: center;
    input {
      cursor: pointer;
    }
    .theme--light.v-list-item--active,
    .theme--dark.v-list-item--active {
      &.none::before {
        opacity: 0 !important;
      }
    }
  }
  .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
    background: none;
  }
  input[role="radio"] {
    border-radius: 50%;
  }
  @supports (-webkit-touch-callout: none) {
    *::-webkit-scrollbar {
      width: 5px;
    }
  }
}
</style>
