
<template>
  <div>
    <h3
      v-if="orientation == 'landscape'"
      style="text-align: center;font-weight"
    >
      {{ $t("calibrationSettings") }}
    </h3>
    <div class="row justify-space-around">
      <div style="margin: 25px">
        <input type="checkbox" name="calibrationOn" @change="(e)=>{
          this.$store.dispatch('updateCalibrationToogle', e.target.checked);
        }"/>
        <label for="calibrationOn"> {{ $t("calibrationOn") }}</label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { DeviceOrientationMixin } from "../mixins";

export default Vue.extend({
  name: "CalibrationSettings",
  mixins: [DeviceOrientationMixin],
});
</script>
