import { render, staticRenderFns } from "./Brain.vue?vue&type=template&id=77e8f4bb"
import script from "./Brain.vue?vue&type=script&lang=ts"
export * from "./Brain.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports