<template>
  <div>
    <div style="display: none">
      <sinus
        ref="sin"
        :s1="s1"
        :s2="s2"
        :s3="s3"
        :s4="s4"
        :sinWidth="20"
        :showAxis="false"
      />
    </div>
    <img class="curve-left" src="/i/f/curve-left.png" />
    <img class="curve-right" src="/i/f/curve-right.png" />
    <div class="indicator-button-group">
      <div class="indicator-container">
        <div class="indicator-picker">
          <icon name="freq" />
          <picker
            theme="white"
            from="1"
            to="150"
            :value="freq"
            :onChange="
              (val) => {
                freqValue = val;
              }
            "
          />
        </div>
        <div class="indicator-picker">
          <icon name="duty" />
          <picker
            theme="white"
            from="1"
            to="100"
            :value="duty"
            :onChange="
              (val) => {
                dutyValue = val;
              }
            "
          />
        </div>
        <div class="indicator-picker">
          <icon name="level" />
          <picker
            theme="white"
            from="1"
            to="240"
            :value="level"
            :onChange="(val) => changeLevel(val)"
          />
        </div>
      </div>
    </div>
    <div class="start-button-group">
      <div class="start-button-container1">
        <div
          class="start-button-container2"
          :class="startButton1Active && 'active'"
          v-on:click="startButtonClick(0)"
        >
          <div class="start-button-container3">
            <icon
              v-if="startButton1Active"
              name="pause"
              style="height: 6vh; width: 4vh"
            />
            <icon v-else name="play" style="height: 5vh; width: 4vh" />
          </div>
        </div>
      </div>
      <div class="start-button-container1 right">
        <div
          class="start-button-container2"
          :class="startButton2Active && 'active'"
          v-on:click="startButtonClick(1)"
        >
          <div class="start-button-container3">
            <icon name="pepette" style="height: 5vh; width: 4vh" />
          </div>
        </div>
      </div>
    </div>
    <div class="slider-box">
      <div style="width: 40%">
        <slider :max="100" :min="0" v-model="duty1Value">
          <div class="thumb">
            <icon name="duty1" />
          </div>
        </slider>
      </div>
      <div style="width: 40%">
        <slider :max="100" :min="0" v-model="duty2Value">
          <div class="thumb">
            <icon name="duty2" />
          </div>
        </slider>
      </div>
      <div style="width: 20%">
        <div style="padding-bottom: 11vh"></div>
        <icon name="scale" style="height: 62.7vh; margin-top: 1vh" />
      </div>
      <div></div>
    </div>
    <div class="right-toogle-group">
      <div
        class="toogle-button-container left"
        v-on:click="InductionRotorToogle(0)"
        :class="fanActive == 0 && 'active'"
      >
        <icon name="fan" :color="fanActive === 0 && '#e6b1ff'" />
      </div>
      <div
        class="toogle-button-container right"
        v-on:click="InductionRotorToogle(1)"
        :class="fanActive == 1 && 'active'"
      >
        <icon name="horseshoe" :color="fanActive === 1 && '#e6b1ff'" />
      </div>
    </div>
    <div class="time-panel">
      <div class="c1">
        <div class="c2"></div>
        <div class="timer">
          <timer :active="startButton1Active" />
        </div>
        <div class="clock"><clock /></div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import Vue from "vue";
import Clock from "../clock.vue";
import Icon from "../icons/Icon.vue";
import Slider from "../slider.vue";
import Timer from "../timer.vue";
import Picker from "../Picker.vue";
import { FalloutMixin } from "./FalloutMixin";


export default Vue.extend({
  name: "FallOutWhite",
  mixins: [FalloutMixin],
  components: { Picker, Icon, Clock, Timer, Slider },
});
</script>

<style lang="scss" >
.v-application.theme-fallout-white.fallout {
  .drawer {
    padding-top: 15vh;
  }
  .v-btn--icon.v-size--default {
    height: inherit;
    width: inherit;
  }
  .indicator-picker svg {
    height: 20px;
    width: 20px;
    @media screen and (min-width: 700px) and (min-height: 500px) {
      height: 4vw;
      width: 4vw;
      margin-bottom: 1vw;
    }
  }
  .settings-icon,
  .presets-icon {
    margin: 2.5vh 3vh 3vh 3vh;
    @media screen and (min-width: 700px) and (min-height: 400px) {
      margin: 4vh 3vh 3vh 3vh;
    }
  }
  .gear-bg,
  .brain-bg {
    background: radial-gradient(
        81.67% 81.67% at 13.33% 81.67%,
        #d9d9d9 0%,
        #c4c4c4 100%
      ),
      #c4c4c4;
    border: 1px solid #6f6f6f;
    border-radius: 50%;
    display: flex;
    height: 10vh;
    width: 10vh;
    @media screen and (min-width: 700px) and (min-height: 400px) {
      height: 7vh;
      width: 7vh;
    }
    padding: initial;
    justify-content: center;
    align-items: center;
    svg {
      height: 7vh;
      width: 7vh;
      @media screen and (min-width: 700px) and (min-height: 400px) {
        height: 5vh;
        width: 5vh;
      }
      filter: drop-shadow(0px 0px 3px rgba(196, 28, 255, 0.8));
      margin-left: -1px;
    }
  }
  background: radial-gradient(
      20.69% 37.78% at 75.39% 62.22%,
      rgba(197, 197, 197, 0.056) 0%,
      rgba(73, 73, 73, 0.044) 48.27%,
      rgba(0, 0, 0, 0) 98.91%
    ),
    radial-gradient(
      102.5% 102.5% at 43.2% -2.5%,
      rgba(187, 187, 187, 0.295) 0%,
      rgba(151, 151, 151, 0.0507991) 50.87%,
      rgba(176, 176, 176, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(168, 174, 177, 0.1) -6.39%,
      rgba(151, 151, 151, 0.1) 9.05%,
      rgba(191, 192, 192, 0.1) 21.72%,
      rgba(221, 227, 227, 0.1) 31.22%,
      rgba(89, 87, 90, 0.059) 50.46%,
      rgba(113, 113, 113, 0.1) 69.69%,
      rgba(188, 188, 188, 0.1) 85.98%,
      rgba(110, 112, 116, 0.1) 100%
    ),
    #c4c4c4;
  .v-application--wrap {
    background: radial-gradient(
        50.56% 51.6% at 70.78% 72.92%,
        rgba(255, 255, 255, 0.1875) 0%,
        rgba(216, 216, 216, 0.2925) 40.99%,
        rgba(153, 153, 153, 0.09) 67.52%,
        rgba(168, 168, 168, 0) 100%
      ),
      radial-gradient(
        89.86% 89.86% at 38.13% -2.92%,
        rgba(255, 255, 255, 0.558) 0%,
        rgba(216, 216, 216, 0.351) 40.99%,
        rgba(153, 153, 153, 0.108) 67.52%,
        rgba(168, 168, 168, 0) 100%
      ),
      radial-gradient(
        102.5% 102.5% at 43.2% -2.5%,
        rgba(233, 233, 233, 0.343) 0%,
        rgba(53, 51, 51, 0.0711188) 50.87%,
        rgba(77, 74, 74, 0) 100%
      );
  }
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  div[name="fallOut-white"] {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
  }
  .v-main__wrap {
    position: initial;
  }
  .curve-left {
    top: 7.34vh;
    width: 55vw;
    height: 100vh;
    position: absolute;
  }
  .curve-right {
    top: 7.34vh;
    right: 0;
    width: 23vw;
    height: 92.7vh;
    position: absolute;
  }
  .indicator-button-group {
    top: 10vh;
    left: 10vw;
    width: 43vw;
    height: 54vh;
    position: absolute;
    .indicator-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      .indicator-picker {
        text-align: center;
      }
      .indicator-button {
        display: flex;
        height: calc(10vw + 27vh);
        margin: 0 1.5vw 0 1.5vw;
        width: 10vw;
        .divider {
          width: 100%;
          display: flex;
          justify-content: center;
          div {
            width: 2px;
            height: 15px;
            background: rgba(0, 0, 0, 0.45);
          }
        }
        .buttons-container {
          height: 100%;
          margin: 0px 0.7vw 1vh;
          background: linear-gradient(
            0deg,
            #adadad 6.04%,
            #616161 34.23%,
            #b8b8b8 93.32%
          );
          // box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.4);
          border-radius: 7vh;
          .button-up {
            border: 0.4vh solid rgba(0, 0, 0, 0.45);
            height: 50%;
            background: linear-gradient(
              -200deg,
              rgba(255, 255, 255, 0.6) -28.18%,
              rgba(181, 181, 181, 0) 37.87%
            );
            touch-action: none;
            border-radius: 7vh 7vh 0px 0px;
            .btn-nested {
              margin-top: 0.2vh;
              height: calc(100% - 0.2vh);
              position: relative;
              background: linear-gradient(90deg, #d2d2d2 -8.03%, #ededed 98.62%),
                #c4c4c4;
              border-radius: 7vh 7vh 0px 0px;
              display: flex;
              justify-content: center;
              &.active {
                background: linear-gradient(
                    285.3deg,
                    rgba(219, 216, 216, 0.8) 14.78%,
                    rgba(193, 193, 193, 0.8) 59.19%,
                    rgba(167, 167, 167, 0.8) 89.25%
                  ),
                  #c4c4c4;
                margin-bottom: 0.1vh;
                height: calc(100% - 0.1vh);
              }
              img {
                width: 8vh;
              }
            }
          }
          .button-down {
            border: 0.4vh solid rgba(0, 0, 0, 0.45);
            height: 50%;
            border-radius: 0px 0px 7vh 7vh;
            padding-bottom: 1px;
            .btn-nested {
              background: linear-gradient(
                  139deg,
                  #eeeeee 13.68%,
                  #ababab 112.95%
                ),
                #c4c4c4;
              box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.45);
              border-radius: 0px 0px 7vh 7vh;
              height: 100%;
              display: flex;
              justify-content: center;
              &.active {
                background: linear-gradient(
                    75.7deg,
                    #e4e4e4 -0.89%,
                    #b6b6b6 73.79%
                  ),
                  #c4c4c4;
                margin-top: 0.1vh;
                height: calc(100% - 0.1vh);
              }
              img {
                width: 8vh;
              }
            }
          }
        }
        background: linear-gradient(
            180deg,
            #ececec 0%,
            rgba(226, 226, 226, 0.570363) 38.12%,
            rgba(182, 182, 182, 0.41) 100%
          ),
          linear-gradient(
            180deg,
            #ababab 0%,
            rgba(215, 215, 215, 0.570363) 38.12%,
            rgba(207, 207, 207, 0.41) 100%
          ),
          #d5d5d5;
        box-shadow: 0px -1px 4px rgba(122, 122, 122, 0.7),
          1px 2px 4px rgba(7, 7, 7, 0.65);
        border-radius: 0px 0px 7vh 7vh;
        flex-direction: column;
        .indicator {
          color: #ffffff;
          width: 9vw;
          text-shadow: 0px 0px 4px #ad00ff;
          margin: 8px auto 0px;
          font-size: calc(3vh + 3vw);
          background: linear-gradient(
              180deg,
              #9f9f9f 0%,
              rgba(240, 240, 240, 0.71) 42.96%,
              rgba(255, 255, 255, 0.79) 100%
            ),
            #000000;
          box-shadow: inset -1px -2px 2px rgba(96, 96, 96, 0.8),
            inset 1px 2px 3px rgba(7, 7, 7, 0.65);
          border-radius: 5px;
          text-align: center;
          font-family: Iceland;
        }
      }
    }
  }
  .start-button-group {
    bottom: 4vh;
    left: 18vw;
    width: calc(15vw + 20vh);
    height: calc(5vw + 11vh);
    display: flex;
    position: absolute;
    background: linear-gradient(
        270deg,
        rgba(213, 213, 213, 0) 2.63%,
        rgba(213, 213, 213, 0.54) 40.81%,
        rgba(155, 155, 155, 0) 96.49%
      ),
      linear-gradient(
        180deg,
        #d6d6d6 0%,
        rgba(255, 255, 255, 0.570363) 30.49%,
        rgba(172, 172, 172, 0.41) 100%
      ),
      #969696;
    box-shadow: 0px -1px 4px rgba(54, 54, 54, 0.4),
      1px 2px 3px rgba(7, 7, 7, 0.45);
    align-items: center;
    border-radius: 40px;
    .start-button-container1 {
      width: 16vh;
      height: 16vh;
      padding: 0.5vh;
      background: linear-gradient(172.41deg, #d1d1d1 -5.98%, #cacaca 98.53%),
        linear-gradient(172.41deg, #d8d8d8 -5.98%, #252525 98.53%);
      box-shadow: inset 0px 1px 2px 1px rgba(0, 0, 0, 0.25);

      border-radius: 50%;
      left: 1vw;
      &.right {
        right: 1vw;
        left: auto;
      }
      position: absolute;
      .start-button-container2 {
        height: 100%;
        padding: 5%;

        background: linear-gradient(
            180deg,
            #dbdbdb 0%,
            #cecece 45.6%,
            #d5d5d5 100%
          ),
          linear-gradient(180deg, #434343 0%, #232323 45.6%, #1c1c1c 100%),
          #000000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);

        border-radius: 50%;
        .start-button-container3 {
          width: 100%;
          height: 100%;
          display: flex;

          background: radial-gradient(
              100% 100% at 50% 0%,
              #b8b8b8 0%,
              #c9c9c9 44.37%,
              #e4e4e4 100%
            ),
            radial-gradient(
              100% 100% at 50% 0%,
              #141414 0%,
              #202020 50.71%,
              #2f2f2f 100%
            ),
            linear-gradient(180deg, #222224 0%, #424145 100%), #757575;

          align-items: center;
          border-radius: 50%;
          justify-content: center;
        }
        &.active {
          background: #b98dce;
          // box-shadow: inset 1px 2px 2px rgba(42, 1, 62, 0.3);
        }
      }
    }
  }
  .slider-box {
    top: 4vh;
    left: 56vw;
    width: 20vw;
    height: 73vh;
    display: flex;
    position: absolute;
    justify-content: center;
  }
  .right-toogle-group {
    top: 20vh;
    right: 1vw;
    width: 20vw;
    height: calc(4vh + 7vw);
    display: flex;
    padding: 2px;
    position: absolute;
    background: #959595;
    box-shadow: inset 0px -1px 2px 1px rgba(19, 19, 19, 0.5);
    align-items: center;
    border-radius: 10vh;
    .toogle-button-container {
      width: 50%;
      height: 100%;
      display: flex;
      background: linear-gradient(352.74deg, #d8d8d8 27.76%, #efefef 96.1%),
        #c4c4c4;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.45);
      border-radius: 35px 0px 0px 35px;
      text-align: center;
      align-items: center;
      &.active {
        svg {
          // box-shadow: inset 0px 1px 1px rgba(244, 226, 255, 0.5);
          filter: drop-shadow(0px 0px 4px rgba(182, 27, 255, 0.7));
        }
      }
      &.left {
        margin-right: 0.1vw;
        border-radius: 10vh 0px 0px 10vh;
        &.active {
          background: linear-gradient(141.13deg, #b0b0b0 21.92%, #cbcbcb 91.34%),
            #c4c4c4;
          box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
        }
      }
      &.right {
        margin-left: 0.2vw;
        border-radius: 0px 10vh 10vh 0px;
        &.active {
          background: linear-gradient(141.13deg, #b0b0b0 21.92%, #cbcbcb 91.34%),
            #c4c4c4;
          box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
        }
      }
      justify-content: center;
      box-shadow: rgba(0, 0, 0, 0.65) 0px 5px 6px;
      svg {
        height: 9vh;
      }
    }
  }
  .time-panel {
    bottom: 14vh;
    right: 3vw;
    width: 16vw;
    height: 16vw;
    display: flex;
    position: absolute;
    background: #e6dde9;
    box-shadow: inset 0px 0px 3px 2px rgba(44, 44, 44, 0.4);
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    .c1 {
      width: 90%;
      height: 90%;
      position: relative;
      background: linear-gradient(180deg, #e3e3e3 0%, #abaaaa 86.85%);
      box-shadow: 0px 1px 3px rgba(174, 3, 255, 0.7),
        inset 0px 2px 7px 1px rgba(0, 0, 0, 0.35);
      border-radius: 50%;
      .c2 {
        top: 60%;
        right: -10%;
        width: 120%;
        height: 120%;
        position: absolute;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 85%,
          rgba(255, 255, 255, 0.416) 99.59%,
          rgba(255, 255, 255, 0.416) 99.59%
        );
        background-blend-mode: overlay;
        border-radius: 50%;
      }
      .timer {
        top: 50%;
        left: 50%;
        color: white;
        text-shadow: 0px 0px 3px rgba(174, 3, 255, 0.9),
          0px 1px 3px rgba(0, 0, 0, 0.45);
        position: absolute;
        font-size: 3.6vw;
        transform: translate(-50%, -50%);
        font-family: Iceland;
      }
      .clock {
        left: 50%;
        color: white;
        text-shadow: 0px 0px 3px rgba(174, 3, 255, 0.9),
          0px 1px 3px rgba(0, 0, 0, 0.45);
        bottom: 3vh;
        position: absolute;
        font-size: 2.1vw;
        transform: translateX(-50%);
        font-family: Iceland;
      }
    }
  }
  .slider-container {
    .slider {
      width: 1.5vw !important;
      height: 65vh;
      margin: 0 auto;
      // background: rgba(33, 32, 32, 0.8);
      box-shadow: inset 0px 0px 1px 1px rgba(9, 9, 9, 0.55),
        inset 1px 0px 1px 1px rgba(107, 107, 107, 0.55),
        inset 1px 0px 1px 1px rgba(107, 107, 107, 0.55);
      border-radius: 18px;
      .rail {
        display: flex;
        justify-content: center;
        // width: 4px !important;
        background: none;
        .track {
          width: 1vw !important;
          background: linear-gradient(270deg, #f2beff 0%, #f9e2ff 83.33%);
          box-shadow: 0px 0px 7px rgba(219, 0, 255, 0.85),
            0px 0px 5px rgba(255, 255, 255, 0.3);
          // margin-left: 2.3px;
          border-radius: 18px;
          margin-bottom: 2px;
        }
      }
      .thumb-container {
        .thumb {
          display: flex;
          justify-content: center;
          width: calc(3vw + 5vh);
          height: 4vh;
          top: 50%;
          left: 50%;
          transform: translate(-50%, 0%);
          background: linear-gradient(
              53.97deg,
              rgba(215, 215, 215, 0.9) 35.85%,
              rgba(234, 234, 234, 0.9) 72.49%,
              rgba(255, 255, 255, 0.9) 97.08%
            ),
            #c2c2c2;
          box-shadow: 1px 2px 4px #000000,
            inset -2px -2px 4px 1px rgb(0 0 0 / 35%);
          position: inherit;
          border-radius: 17px;
          svg {
            width: 3vh;
            align-self: center;
          }
        }
      }
    }
    .value {
      // color: rgba(219, 0, 255, 0.85) !important;
      width: inherit;
      color: #ffffff !important;
      text-shadow: 0px 0px 3px rgba(174, 3, 255, 0.85),
        0px 0px 3px rgba(255, 255, 255, 0.5);
      font-family: Iceland;
      padding-bottom: 4vh;
      font-size: 5vh;
      text-align: center;
      height: auto;
    }
  }
  .footer {
    .arrow {
      height: 7vh;
      width: 7vh;
      position: absolute;
      z-index: 100;
      left: 27px;
      bottom: 4vh;
      filter: drop-shadow(0px 0px 4px rgba(182, 27, 255, 0.7));
    }
    .trash,
    .trash-open {
      bottom: 4vh;
      height: 13vh;
      right: 2vw;
      @media screen and (min-width: 700px) {
        width: 12vh;
      }
      filter: drop-shadow(0px 0px 4px rgba(182, 27, 255, 0.7));
    }
  }
  .presets {
    .icon-dialog {
      .container {
        background: radial-gradient(
            20.69% 37.78% at 75.39% 62.22%,
            rgba(197, 197, 197, 0.056) 0%,
            rgba(73, 73, 73, 0.044) 48.27%,
            rgba(0, 0, 0, 0) 98.91%
          ),
          radial-gradient(
            102.5% 102.5% at 43.2% -2.5%,
            rgba(187, 187, 187, 0.295) 0%,
            rgba(151, 151, 151, 0.0507991) 50.87%,
            rgba(176, 176, 176, 0) 100%
          ),
          linear-gradient(
            180deg,
            rgba(168, 174, 177, 0.1) -6.39%,
            rgba(151, 151, 151, 0.1) 9.05%,
            rgba(191, 192, 192, 0.1) 21.72%,
            rgba(221, 227, 227, 0.1) 31.22%,
            rgba(89, 87, 90, 0.059) 50.46%,
            rgba(113, 113, 113, 0.1) 69.69%,
            rgba(188, 188, 188, 0.1) 85.98%,
            rgba(110, 112, 116, 0.1) 100%
          ),
          #c4c4c4;
        .item-bg {
          background: none;
        }
      }
    }
    background: radial-gradient(
        20.69% 37.78% at 75.39% 62.22%,
        rgba(197, 197, 197, 0.056) 0%,
        rgba(73, 73, 73, 0.044) 48.27%,
        rgba(0, 0, 0, 0) 98.91%
      ),
      radial-gradient(
        102.5% 102.5% at 43.2% -2.5%,
        rgba(187, 187, 187, 0.295) 0%,
        rgba(151, 151, 151, 0.0507991) 50.87%,
        rgba(176, 176, 176, 0) 100%
      ),
      linear-gradient(
        180deg,
        rgba(168, 174, 177, 0.1) -6.39%,
        rgba(151, 151, 151, 0.1) 9.05%,
        rgba(191, 192, 192, 0.1) 21.72%,
        rgba(221, 227, 227, 0.1) 31.22%,
        rgba(89, 87, 90, 0.059) 50.46%,
        rgba(113, 113, 113, 0.1) 69.69%,
        rgba(188, 188, 188, 0.1) 85.98%,
        rgba(110, 112, 116, 0.1) 100%
      ),
      #c4c4c4;
    // height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    .container-main {
      .icon-bg {
        text-shadow: 0px 0px 4px #ad00ff;
        background: linear-gradient(
            180deg,
            #9f9f9f 0%,
            rgba(240, 240, 240, 0.71) 42.96%,
            rgba(255, 255, 255, 0.79) 100%
          ),
          #000000;
        box-shadow: inset -1px -2px 2px rgb(96 96 96 / 80%),
          inset 1px 2px 3px rgb(7 7 7 / 65%);
        box-sizing: border-box;
        border-radius: 50%;
        height: 50px;
        width: 50px;
      }
    }
    .v-list-item--active {
      .icon-bg,
      .preset {
        box-shadow: 0px 0px 5px #b630f5, inset 0px 0px 3px 3px rgb(0 0 0 / 50%);
      }
    }
    .preset {
      font-family: "Iceland";
      height: 40px;
      border-radius: 50px 50px 50px 50px;
      margin-left: 5px;
      text-shadow: 0px 0px 4px #ad00ff;
      padding-bottom: 5px;
      padding-top: 3px;
      background: linear-gradient(
          180deg,
          #9f9f9f 0%,
          rgba(240, 240, 240, 0.71) 42.96%,
          rgba(255, 255, 255, 0.79) 100%
        ),
        #000000;
      box-shadow: inset -1px -2px 2px rgb(96 96 96 / 80%),
        inset 1px 2px 3px rgb(7 7 7 / 65%);
    }
  }
}
.cordova-app {
  body {
    width: var(--app-width)!important;
    height: 100vh;
  }
}
</style>