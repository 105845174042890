<template>
  <keep-alive>
    <component
      v-bind:is="name"
      :name="name"
      :class="name"
      :color="color || $store.getters.currentThemeShade.color"
      :active="active"
    ></component>
  </keep-alive>
</template>

<script lang="ts">
import Vue from "vue";
import Arrow from "./Arrow.vue";
import Eye3 from "./Eye3.vue";
import Eye1 from "./Eye1.vue";
import Eye2 from "./Eye2.vue";
import Brain from "./Brain.vue";
import Gear from "./Gear.vue";
import Freq from "./Freq.vue";
import Rocket from "./Rocket.vue";
import Duty from "./Duty.vue";
import Level from "./Level.vue";
import Duty1 from "./Duty1.vue";
import Duty2 from "./Duty2.vue";
import Start from "./Start.vue";
import Face from "./Face.vue";
import Disk from "./Disk.vue";
import Eyelashes from "./Eyelashes.vue";
import Clover from "./Clover.vue";
import Lips from "./Lips.vue";
import Lips1 from "./Lips1.vue";
import Eyebrows from "./Eyebrows.vue"
import Eyebrows1 from "./Eyebrows1.vue"
import Eyebrows2 from "./Eyebrows2.vue"
import Trash from "./Trash.vue"
import TrashOpen from "./TrashOpen.vue"
import PlayPause from "./PlayPause.vue"
import Play from "./Play.vue"
import PlayPauseMD from "./PlayPauseMD.vue";
import Pause from "./Pause.vue"
import Fan from "./Fan.vue"
import Scale from "./Scale.vue"
import Pepette from "./Pepette.vue"
import Horseshoe from "./Horseshoe.vue"
import AlignLeft from "./AlignLeft.vue"
import StartBtnDark from "./StartBtnDark.vue"
import StartBtnLight from "./StartBtnLight.vue"


export default Vue.extend({
  name: "icon",
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
    active: {
      type: Boolean
    },
  },
  components: {
    Arrow,
    Eye3,
    Eye1,
    Eye2,
    Brain,
    Gear,
    Freq,
    Duty,
    Level,
    Duty1,
    Duty2,
    Start,
    Face,
    Disk,
    Eyelashes,
    Clover,
    Lips,
    Lips1,
    Eyebrows,
    Eyebrows1,
    Eyebrows2,
    Trash,
    TrashOpen,
    Fan,
    Horseshoe,
    AlignLeft,
    Rocket,
    PlayPause,
    Play,
    PlayPauseMD,
    Pause,
    Pepette,
    Scale,
    StartBtnDark,
    StartBtnLight,
  },
});
</script>