<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path :fill="color" d="M3 5v14l8-7m2 7h3V5h-3m5 0v14h3V5"/></svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "play-pause-md",
  mixins: [IconMixin],
});
</script>
