<template>
    <span />
</template>

<script lang="ts">
import Vue from "vue";
import { Capacitor } from '@capacitor/core';
import { wordsToNumber } from "@/helpers";
import { voiceCommandToAction } from "@/helpers";

const sampleRate = 16000;
const wsURL = 'wss://tattootoys.ru/ws/';

export default Vue.extend({
    name: "SpeechRecognition",
    data() {
        return {
            context: null,
            source: null,
            processor: null,
            streamLocal: null,
            webSocket: null,
            inputArea: null,
            initComplete: false
        };
    },
    mounted() {
        if (!Capacitor.isNativePlatform() && this.$store.state.micOn) {
            console.log("INITIALIZE SPR");
            const lang = this.$store.state.locale;
            this.webSocket = new WebSocket(wsURL + "?lang=" + lang + "&mode=" + this.$store.state.voiceMode1);
            this.webSocket.binaryType = "arraybuffer";

            this.webSocket.onopen = function () {
                console.log('New connection established');
            };

            this.webSocket.onerror = function (event) {
                console.error(event.data);
            };

            const that = this;

            this.webSocket.onmessage = function (event) {
                if (event.data) {
                    let parsed = JSON.parse(event.data);
                    if (parsed.cmd == "LEVEL" || parsed.cmd == "FREQ") {
                        parsed.val = wordsToNumber(parsed.val)
                    }
                    console.log("SR RESPONSE:", parsed, parsed.val);
                    voiceCommandToAction(that, parsed.cmd, parsed.val)
                }
            };
            navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    channelCount: 1,
                    sampleRate
                }, video: false
            })
                .then(this.handleWebSuccess);
            this.initComplete = true;
        }
    },
    methods: {
        handleWebSuccess(stream) {
            this.streamLocal = stream;
            this.context = new AudioContext({ sampleRate: sampleRate });
            this.source = this.context.createMediaStreamSource(stream);
            this.processor = this.context.createScriptProcessor(this.bufferSize, 1, 1);

            this.source.connect(this.processor);
            this.processor.connect(this.context.destination);

            // if (document.documentElement.requestFullscreen) {
            //     document.documentElement.requestFullscreen();
            //     this._lockScreenOrientation();
            // }
            // this.$store.dispatch("requestFullScreen", false);
            const that = this;
            this.processor.onaudioprocess = function (audioDataChunk) {
                that.sendAudio(audioDataChunk);
            };
        },
        sendAudio(audioDataChunk) {
            if (this.webSocket.readyState === WebSocket.OPEN) {
                // convert to 16-bit payload
                const inputData = audioDataChunk.inputBuffer.getChannelData(0) || new Float32Array(this.bufferSize);
                const targetBuffer = new Int16Array(inputData.length);
                for (let index = inputData.length; index > 0; index--) {
                    targetBuffer[index] = 32767 * Math.min(1, inputData[index]);
                }
                this.webSocket.send(targetBuffer.buffer);
            }
        },
        stopRecording() {
            if (this.initComplete === true) {

                this.webSocket.send('{"eof" : 1}');
                this.webSocket.close();
                console.log("WebSocket closed")
                this.source.disconnect(this.processor);
                this.processor.disconnect(this.context.destination);
                if (this.streamLocal.active) {
                    this.streamLocal.getTracks()[0].stop();
                }
                this.initComplete = false;
                console.log("Stop Recording")
            }
        }
    }
})
</script>

<style lang="scss"></style>