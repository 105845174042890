<template>
  <div class="indicator-button">
    <div class="indicator">{{ currentValue }}</div>
    <div class="divider">
      <div />
    </div>
    <div class="buttons-container" @contextmenu.prevent="">
      <div class="button-up" @touchstart="start(0)" @mouseleave="stop()" @mouseup="stop()" @touchend="stop()"
        @touchcancel="stop()">
        <div class="btn-nested" :class="active == 0 && 'active'">
          <img v-if="theme=='white'" src="/i/f/arrow-up-l.svg" alt="" />
          <img v-else src="/i/f/arrow-up.svg" alt="" />
        </div>
      </div>
      <div class="button-down" @touchstart="start(1)" @mouseleave="stop()" @mouseup="stop()" @touchend="stop()"
        @touchcancel="stop()">
        <div class="btn-nested" :class="active == 1 && 'active'">
          <img v-if="theme=='white'" src="/i/f/arrow-down-l.svg" alt="" />
          <img v-else src="/i/f/arrow-up.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import Vue from "vue";

export default Vue.extend({
  name: "picker",
  props: ["theme", "from", "to", "value", "onChange"],
  data: function () {
    return {
      timer: null,
      currentValue: 0,
      active: -1,
      counter: 0,
    };
  },
  mounted() {
    this.currentValue = this.value;
  },
  watch: {
    value: function (newVal) {
      this.currentValue = newVal;
    },
  },
  methods: {
    start(num) {
      this.active = num;
      if (num == 0) {
        if (+this.currentValue < +this.to) this.currentValue++;
      } else {
        if (+this.currentValue > +this.from) this.currentValue--;
      }
      this.timer = setInterval(() => {
        this.counter++;
        if (this.counter > 5) {
          if (num == 0) {
            if (+this.currentValue < +this.to) this.currentValue++;
          } else {
            if (+this.currentValue > +this.from) this.currentValue--;
          }
          this.onChange(this.currentValue);
        }
      }, 100);
      this.onChange(this.currentValue);
    },
    stop() {
      this.active = -1;
      clearInterval(this.timer);
      this.counter = 0;
    },
  }
});
</script>

<style lang="scss" ></style>