<template>
  <svg fill="none" viewBox="0 0 22 35">
    <g filter="url(#filter0_i6)">
      <path
        :stroke="color"
        stroke-linecap="square"
        stroke-linejoin="round"
        stroke-width="3"
        d="M18.274 2L2 19.467h6.041L3.295 33 20 15.467h-6.164L18.274 2z"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_i6"
        width="22"
        height="36"
        x="-0.5"
        y="0.5"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-1" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
        ></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow"></feBlend>
      </filter>
    </defs>
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "level",
  mixins: [IconMixin],
});
</script>
