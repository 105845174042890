var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"settings-item",class:_vm.isWebsite && 'web-site'},[(_vm.orientation == 'landscape')?_c('h3',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("themes"))+" ")]):_vm._e(),_c('div',{staticClass:"themes"},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.$store.state.themes.shade),callback:function ($$v) {_vm.$set(_vm.$store.state.themes, "shade", $$v)},expression:"$store.state.themes.shade"}},[_c('v-radio',{attrs:{"label":_vm.$t('white'),"value":"white"},on:{"click":function($event){return _vm.themeShadeSelect('white')}}}),_c('v-radio',{attrs:{"label":_vm.$t('black'),"value":"black"},on:{"click":function($event){return _vm.themeShadeSelect('black')}}})],1),_c('div',{staticClass:"theme-list"},[_c('div',[_c('v-list',{staticStyle:{"width":"100%"},attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"active"}},[(!_vm.isWebsite && _vm.orientation == 'portrait')?_c('v-list-item',{class:_vm.$store.state.themes.current == 'beginner'
                  ? 'v-list-item--active'
                  : 'none',on:{"click":_vm.beginnerThemeSelect}},[_c('div',{staticStyle:{"margin-right":"10px"}},[_c('strong',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t("beginner")))])]),_c('div',{staticStyle:{"justify-content":"flex-end","display":"flex","width":"100%"}},[_c('div',{staticClass:"color-picker",style:('background-color:' +
                    _vm.$store.state.themes.beginner[
                      _vm.$store.state.themes.shade
                    ].color),on:{"click":function($event){_vm.dialog = true;
                    _vm.dialogTheme = 'beginner';
                    _vm.currentHEX =
                      _vm.$store.state.themes.beginner[
                        _vm.$store.state.themes.shade
                      ].color;}}})])]):_vm._e()],1)],1),_c('v-list',{staticStyle:{"width":"100%"},attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"active"}},[_c('v-list-item',{class:_vm.$store.state.themes.current == 'professional'
                  ? 'v-list-item--active'
                  : 'none',on:{"click":_vm.professionalThemeSelect}},[_c('div',{staticStyle:{"margin-right":"10px"}},[_c('strong',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t("professional")))])]),_c('div',{staticStyle:{"justify-content":"flex-end","display":"flex","width":"100%"}},[_c('div',{staticClass:"color-picker",style:('background-color:' +
                    _vm.$store.state.themes.professional[
                      _vm.$store.state.themes.shade
                    ].color),on:{"click":function($event){_vm.dialog = true;
                    _vm.dialogTheme = 'professional';
                    _vm.currentHEX =
                      _vm.$store.state.themes.professional[
                        _vm.$store.state.themes.shade
                      ].color;}}})])])],1)],1),_c('v-list',{staticStyle:{"width":"100%"},attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"active"}},[_c('v-list-item',{class:_vm.$store.state.themes.current == 'guru'
                  ? 'v-list-item--active'
                  : 'none',on:{"click":_vm.guruThemeSelect}},[_c('div',{staticStyle:{"margin-right":"10px"}},[_c('strong',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t("guru")))])]),_c('div',{staticStyle:{"justify-content":"flex-end","display":"flex","width":"100%"}},[_c('div',{staticStyle:{"justify-content":"flex-end","display":"flex","width":"100%"}},[_c('div',{staticClass:"color-picker",style:('background-color:' +
                      _vm.$store.state.themes.guru[_vm.$store.state.themes.shade]
                        .color),on:{"click":function($event){_vm.dialog = true;
                      _vm.dialogTheme = 'guru';
                      _vm.currentHEX =
                        _vm.$store.state.themes.guru[_vm.$store.state.themes.shade]
                          .color;}}})])])])],1)],1),(!_vm.isWebsite  && _vm.orientation == 'portrait')?_c('v-list',{staticStyle:{"width":"100%","float":"left"},attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"active"}},[_c('v-list-item',{class:_vm.$store.state.themes.current == 'rotor'
                  ? 'v-list-item--active'
                  : 'none',on:{"click":_vm.rotorThemeSelect}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("rotor")))])])])],1)],1):_vm._e(),((_vm.isWebsite && _vm.orientation == 'landscape') || !_vm.isWebsite)?_c('v-list',{staticStyle:{"width":"100%","float":"left"},attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"active"}},[_c('v-list-item',{class:_vm.$store.state.themes.current == 'fallOut'
                  ? 'v-list-item--active'
                  : 'none',on:{"click":_vm.fallOutThemeSelect}},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t("fallOut")))])])])],1)],1):_vm._e()],1)])],1),_c('v-overlay',{attrs:{"opacity":0.9},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-dialog',{attrs:{"persistent":"","hide-overlay":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5",staticStyle:{"padding-left":"25px"}},[_vm._v(" "+_vm._s(_vm.$t("selectColor"))+" ")]),_c('v-card-text',{staticStyle:{"margin-top":"20px"}},[_c('color-picker',{attrs:{"hex":_vm.currentHEX,"onChange":(color) => _vm.onColorChange(color)}})],1),_c('v-card-actions',{staticStyle:{"padding":"8px 19px"}},[_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.applyClick()}}},[_vm._v(" "+_vm._s(_vm.$t("apply"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }