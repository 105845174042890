<template>
  <v-app :class="(isNative == undefined ? '' : 'cordova ') +
    $store.state.themes.current.toLowerCase() +
    ' theme-' +
    selectedScreenComponent.toLowerCase() +
    ' ' +
    orientation +
    (settingsDrawerShown ? ' settings-open' : '') +
    (presetsDrawerShown ? ' presets-open' : '')
    ">
    <v-banner v-if="deferredPrompt && $store.state.useBluetooth && !$store.state.bleDeviceID" style="z-index: 100000; top: 0px; position: fixed; width: 100%;"
      color="#4a98d5" dark class="text-left">
      Получите наше приложение. Оно не займет много места и может работать в автономном режиме!

      <template v-slot:actions>
        <v-btn text @click="dismiss">Отмена</v-btn>
        <v-btn text @click="install">Установить</v-btn>
      </template>
    </v-banner>
    <SpeechRecognition ref="spr" :key="spr" v-if="$store.state.useBluetooth && $store.state.bleDeviceID" />
    
    <div v-show="!isWebSite">
      <div ref="lockScreen" :class="'lockScreen ' + orientation">
        <table>
          <tr style="text-align: center">
            <td>
              <img src="/i/screen_rotation.svg" />
            </td>
          </tr>
          <tr>
            <td>
              <span> {{ $t("lockscreen") }} </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- <div class="fullScreen full-screen-bg" v-if="!$store.state.useBluetooth && $store.state.requestFullScreen && !$cordova"
      @click="startFullScreen()" style="opacity: 1">
      <span> {{ $t("fullscreen_ble") }} </span>
    </div> -->
    <!-- <div class="fullScreen" v-if="$store.state.useBluetooth && !$store.state.bleDeviceID" @click.stop="bleConnect()"
      style="opacity: 1; " :class="bleLoader ? 'full-screen-bg' : ''"> -->
    <div class="fullScreen" v-if="!isNative && $store.state.useBluetooth && !$store.state.bleDeviceID" @click.stop="bleConnect()"
      style="opacity: 1; " :class="bleLoader ? 'full-screen-bg' : ''">
      <span v-if="bleLoader">
        <v-progress-circular :size="70" :width="7" color="blue" indeterminate>
        </v-progress-circular>
      </span>
      <Splash :key="splashKey" v-else />
    </div>
    <v-snackbar v-if="!$store.state.useBluetooth" app top timeout="-1" v-model="$store.state.errorSnackbar">
      <div style="text-align: center; width: 100%" @touchstart="snackBarTouchStart()" @touchend="snackBarTouchEnd()">
        {{ $t("errorMessage") }}
      </div>
    </v-snackbar>
    <v-snackbar v-if="$store.state.useBluetooth" app top timeout="-1" v-model="$store.state.errorSnackbarBLE">
      <div style="text-align: center; width: 100%" @touchstart="snackBarTouchStart()" @touchend="snackBarTouchEnd()">
        {{ $t("errorMessageBLE") }}
      </div>
    </v-snackbar>
    <!-- <v-snackbar app top timeout="-1" v-model="$store.state.errorSnackbar">
      <div style="text-align: center; width: 100%" @click="bleConnect()" @touchstart="snackBarTouchStart()"
        @touchend="snackBarTouchEnd()">
        {{ $t("errorMessage") }}
      </div>
    </v-snackbar> -->
    <!-- <v-dialog fullscreen v-model="$store.state.errorSnackbar" class="error-dialog" >
      <v-layout row justify-center align-center @click="bleConnect()">
        <video autoplay muted loop id="myVideo">
          <source src="1.mp4" type="video/mp4">
        </video>
      </v-layout>
    </v-dialog> -->
    <div class="top-btn-container" :style="[$store.state.lockInterface ? { 'visibility': 'hidden' } : null]"
      style="display: flex;justify-content: space-between;">
      <v-app-bar-nav-icon class="settings-icon" @click="settingsShow()">
        <div class="gear-bg" :class="settingsDrawerShown && 'active'">
          <icon name="gear" :color="topIconsColor()" />
        </div>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon class="sin-btn-container">
        <div v-if="!$store.state.lockInterface &&
          ['beginner', 'professional', 'guru'].includes(this.$store.state.themes.current)
        " class="gear-bg sin-btn" :class="carouselIndex == 1 && 'active'">
          <div class="sin" @click="sinClick">
            <template>
              <sinus ref="sin" :s1="s1" :s2="s2" :s3="s3" :s4="s4" :sinWidth="20" :showAxis="false"
                :color="$store.getters.currentThemeShade.color" />
            </template>
          </div>
        </div>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon class="presets-icon" @click="presetsDrawerShown = !presetsDrawerShown;"
        :style="settingsDrawerShown ? { 'z-index': 5 } : { 'z-index': 7 }">
        <div class="brain-bg" :class="presetsDrawerShown && 'active'">
          <icon name="brain" :color="topIconsColor()" />
        </div>
      </v-app-bar-nav-icon>
    </div>
    <v-navigation-drawer v-if="!$store.state.lockInterface" v-model="settingsDrawerShown" :width="drawerWidth(30)"
      absolute app :close-delay="1" :open-delay="1" :stateless="windowWidth > 500" class="drawer setting-drawer" disable-resize-watcher
      :key="settingsAccordionKey">
      <v-row v-if="windowWidth > 500" class="settings-content">
        <v-list nav dense style="width: 100%">
          <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
            <v-list-item style="text-align: center" v-for="item in $store.getters.settingsItems" :key="item.title"
              v-on:click="changeScreen(item.component)">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-row>
      <v-row v-else justify="center" style="margin: 10px; margin-top: 25px" :class="settingsDrawerShown && 'settings'">
        <!-- <keep-alive> -->
        <v-expansion-panels accordion>
          <v-expansion-panel v-for="item in $store.getters.settingsItems" :key="item.title">
            <v-expansion-panel-header>
              {{ $t(item.title) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <component :is="item.component" :name="item.component" :key="item.component == 'AboutSettings'
                ? Math.random().toString(36).substr(2, 5)
                : item.component
                "></component>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- </keep-alive> -->
      </v-row>
    </v-navigation-drawer>

    <v-navigation-drawer v-if="!$store.state.lockInterface && $store.state.themes.current != 'rotor'
    " v-model="presetsDrawerShown" :close-delay="1" :open-delay="1" :width="drawerWidth(100)" app right disable-resize-watcher class="drawer presets">
      <div class="container-main">
        <v-list nav dense class="list">
          <v-list-item-group v-model="group" :multiple="trashIsOpen" active-class="deep-purple--text text--accent-4">
            <v-list-item active-class="highlighted" style="text-align: center"
              v-for="preset in $store.getters.currentTheme.presets" :key="preset.key"
              :class="preset.selected == true ? 'activeItem' : ''">
              <div class="icon-bg" :style="{}" v-on:click="() => {
                if (preset.sinShow == undefined) preset.sinShow = false;
                preset.sinShow = !preset.sinShow;
              }
                ">
                <div style="
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                  <template v-if="preset.sinShow">
                    <sinus :color="preset.selected && getPresetIconColor()" :s1="preset.s1" :s2="preset.s2"
                      :s3="preset.s3" :s4="preset.s4" :sinWidth="20" :showAxis="false" />
                  </template>
                  <template v-else>
                    <icon :name="preset.iconName" :color="preset.selected && getPresetIconColor()" />
                  </template>
                </div>
              </div>
              <div class="preset" v-on:click="onPresetItemClick(preset)">
                <template v-if="preset.sinShow">
                  <div :style="{ color: $store.getters.currentThemeShade.color }">
                    {{ preset.s1 }}
                  </div>
                  <div :style="{ color: $store.getters.currentThemeShade.color }">
                    {{ preset.s2 }}
                  </div>
                  <div :style="{ color: $store.getters.currentThemeShade.color }">
                    {{ preset.s3 }}
                  </div>
                  <div :style="{ color: $store.getters.currentThemeShade.color }">
                    {{ preset.s4 }}
                  </div>
                </template>
                <template v-else>
                  <div class="preset-freq" :style="{ color: $store.getters.currentThemeShade.color }">
                    {{ preset.freq }}
                  </div>
                  <template v-if="$store.state.themes.current === 'guru'">
                    <div :style="{ color: $store.getters.currentThemeShade.color }">
                      {{ preset.delay || 0 }}
                    </div>
                    <div :style="{ color: $store.getters.currentThemeShade.color }">
                      {{ preset.points || 0 }}
                    </div>
                  </template>
                  <div class="preset-duty" :style="{ color: $store.getters.currentThemeShade.color }">
                    {{ preset.duty }}
                  </div>
                  <div class="preset-level" :style="{ color: $store.getters.currentThemeShade.color }">
                    {{ preset.level }}
                  </div>
                  <div class="preset-duty1" :style="{ color: $store.getters.currentThemeShade.color }">
                    {{ preset.duty1 }}
                  </div>
                  <div class="preset-duty2" :style="{ color: $store.getters.currentThemeShade.color }">
                    {{ preset.duty2 }}
                  </div>
                </template>
              </div>
            </v-list-item>
            <div class="no-items" v-if="$store.getters.currentTheme.presets.length == 0">
              {{ $t("noItems") }}
            </div>
          </v-list-item-group>
        </v-list>
      </div>
      <div class="footer">
        <div>
          <v-dialog v-model="presetIconDialogIsOpen" persistent max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <div class="save-btn" v-bind="attrs" v-on="on">
                <div>
                  <icon name="arrow" />
                </div>
              </div>
            </template>
            <div class="presets">
              <div class="container-main">
                <div class="icon-dialog">
                  <div class="container">
                    <div class="item-bg" v-on:click="presetIconDialogClick('eye1')">
                      <icon name="eye1" class="eye1" />
                    </div>
                    <div class="item-bg" v-on:click="presetIconDialogClick('eye2')">
                      <icon name="eye2" class="eye2" />
                    </div>
                    <div class="item-bg" v-on:click="presetIconDialogClick('eye3')">
                      <icon name="eye3" class="eye3" />
                    </div>
                    <div class="item-bg" v-on:click="presetIconDialogClick('face')">
                      <icon class="face" name="face" />
                    </div>
                    <div class="item-bg" v-on:click="presetIconDialogClick('disk')">
                      <icon class="disk" name="disk" />
                    </div>
                    <div class="item-bg" v-on:click="presetIconDialogClick('eyelashes')">
                      <icon class="eyelashes" name="eyelashes" />
                    </div>
                    <div class="item-bg" v-on:click="presetIconDialogClick('clover')">
                      <icon class="clover" name="clover" />
                    </div>
                    <div class="item-bg" v-on:click="presetIconDialogClick('lips')">
                      <icon class="lips" name="lips" />
                    </div>
                    <div class="item-bg" v-on:click="presetIconDialogClick('lips1')">
                      <icon class="lips1" name="lips1" />
                    </div>
                    <div class="item-bg" v-on:click="presetIconDialogClick('eyebrows')">
                      <icon name="eyebrows" class="eyebrows" />
                    </div>
                    <div class="item-bg" v-on:click="presetIconDialogClick('eyebrows1')">
                      <icon name="eyebrows1" class="eyebrows1" />
                    </div>
                    <div class="item-bg" v-on:click="presetIconDialogClick('eyebrows2')">
                      <icon name="eyebrows2" class="eyebrows2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-dialog>
        </div>
        <div>
          <div v-on:click="trashClick" class="trash-btn">
            <div v-if="trashIsOpen">
              <icon name="trash-open" />
            </div>
            <div v-else>
              <icon name="trash" />
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
    <v-main v-if="!settingsDrawerShown ||
      orientation === 'landscape' ||
      orientation === 'portrait'
    ">
      <v-container fluid fill-height :class="settingsDrawerShown && 'settings'">
        <v-layout justify-center>
          <!-- <keep-alive> -->
          <component v-if="$store.state.bleDeviceID || !$store.state.useBluetooth" ref="theme" :key="componentKey"
            @nextScreen="changeScreen" v-bind:is="selectedScreenComponent" :name="selectedScreenComponent"
            v-bind="selectedComponentProps" :bus="bus"></component>
          <!-- </keep-alive> -->
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import ProfessionalBlack from "./components/themes/ProfessionalBlack.vue";
import BeginnerBlack from "./components/themes/BeginnerBlack.vue";
import RotorWhite from "./components/themes/RotorWhite.vue";
import RotorBlack from "./components/themes/RotorBlack.vue";
import GuruWhite from "./components/themes/GuruWhite.vue";
import GuruBlack from "./components/themes/GuruBlack.vue";
import FallOutBlack from "./components/themes/FallOutBlack.vue";
import FallOutWhite from "./components/themes/FallOutWhite.vue";
import ProfessionalWhite from "./components/themes/ProfessionalWhite.vue";
import BeginnerWhite from "./components/themes/BeginnerWhite.vue";
import AboutSettings from "./components/settings/AboutSettings.vue";
import CalibrationSettings from "./components/settings/CalibrationSettings.vue";
import LanguageSettings from "./components/settings/LanguageSettings.vue";
// import PedalSettings from "./components/settings/PedalSettings.vue";
import ThemesSettings from "./components/settings/ThemesSettings.vue";
import WifiSettings from "./components/settings/WifiSettings.vue";
import SoundSettings from "./components/settings/SoundSettings.vue";
import HandPositionGesture from "./components/settings/HandPositionGesture.vue";
import Gear from "./components/icons/Gear.vue";
import Brain from "./components/icons/Brain.vue";
import Icon from "./components/icons/Icon.vue";
import { DeviceOrientationMixin } from "./components/mixins";
import sinus from "./components/sinus.vue";
import Splash from "./components/splash.vue";
import SpeechRecognition from "./components/SpeechRecognition.vue";
import { Preset } from "./store";
import i18n from "@/i18n";
import fetchData, { bleStartNotification } from "@/fetchData";
import { SERVICE_UUID } from "@/fetchData"
import { detectMob, isSafari as _isSafari, voiceCommandToAction, wordsToNumber, isWebSite } from "@/helpers";
import { Capacitor } from '@capacitor/core';
import { BleClient, BluetoothLe } from 'bluetooth-le';
import { Device } from '@capacitor/device';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { SpeechRecognition as SR } from "speechrecognition";
// import {HandGestureRecognition } from "handgesturerecognition"; 


declare let window: any;


export default Vue.extend({
  name: "App",

  components: {
    BeginnerBlack,
    BeginnerWhite,
    ProfessionalBlack,
    ProfessionalWhite,
    GuruBlack,
    GuruWhite,
    AboutSettings,
    CalibrationSettings,
    LanguageSettings,
    // PedalSettings,
    ThemesSettings,
    WifiSettings,
    SoundSettings,
    HandPositionGesture,
    Gear,
    Brain,
    Icon,
    RotorWhite,
    RotorBlack,
    FallOutBlack,
    FallOutWhite,
    sinus,
    Splash,
    SpeechRecognition,
  },
  mixins: [DeviceOrientationMixin],
  data() {
    return {
      nativeSRInitialized: false,
      deferredPrompt: null,
      isNative: false,
      platform: "",
      spr: 0,
      device: null,
      bus: new Vue(),
      timer: null,
      touchduration: 5000,
      s1: 0,
      s2: 0,
      s3: 0,
      s4: 0,
      carouselIndex: 0,
      componentKey: false,
      trashIsOpen: false,
      settingsDrawerShown: false,
      rSettingsDrawerShown: false,
      presetsDrawerShown: false,
      settingsAccordionKey: 0,
      stateless: true,
      group: null,
      model: null,
      // modelsLoaded: false,
      selectedScreenComponent: "beginner-black",
      selectedComponentProps: {},
      selectedSettingsScreenComponent: "",
      presetIconDialogIsOpen: false,
      bleLoader: false,
      isWebSite: false,
      splashKey: 0,
      insets: {}
    };
  },
  async created() {
    this.isNative = Capacitor.isNativePlatform();
    this.platform = Capacitor.getPlatform();
    this.bus.$on("sin-sync", (data) => {
      this.s1 = data.s1;
      this.s2 = data.s2;
      this.s3 = data.s3;
      this.s4 = data.s4;
    })
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },

  watch: {
    '$store.state.themes.current': function (newVal) {
      this.selectedScreenComponent =
        newVal + "-" + this.$store.state.themes.shade;
      this.settingsDrawerShown = false;
    },
    '$store.state.locale': async function (newVal) {
      if (Capacitor.isNativePlatform() && this.$store.state.micOn) {
        console.log("REINITIALIZE SPR_1");
        if (!this.nativeSRInitialized) {
          this.SRInitialize();
        } else {
          await SR.stopRecognition()
          await SR.startRecognition({ lang: this.$store.state.locale, mode: this.$store.state.voiceMode1 });
        }
      } else {
        console.log("REINITIALIZE SPR");
        this.spr++;
      }
    },
    '$store.state.voiceMode1': async function (newVal) {
      if (Capacitor.isNativePlatform() && this.$store.state.micOn) {
        if (!this.nativeSRInitialized) {
          this.SRInitialize();
        } else {
          console.log("REINITIALIZE SPR_2");
          await SR.stopRecognition()
          console.log("START SPR 2");
          await SR.startRecognition({ lang: this.$store.state.locale, mode: this.$store.state.voiceMode1 });
        }
      } else {
        console.log("REINITIALIZE SPR");
        this.spr++;
      }
    },
    '$store.state.micOn': async function (value) {
      if (value) {
        if (Capacitor.isNativePlatform()) {
          // await this.SRInitialize()
          console.log("REINITIALIZE SPR_3");
          if (!this.nativeSRInitialized) {
            console.log("REINITIALIZE SPR_3-----");
            this.SRInitialize();
          } else {
            await SR.stopRecognition()
            console.log("START SPR 3");
            await SR.startRecognition({ lang: this.$store.state.locale, mode: this.$store.state.voiceMode1 });
          }
        } else {
          this.spr++;
        }
      } else {
        if (Capacitor.isNativePlatform()) {
          SR.stopRecognition()
        } else {
          this.$refs.spr.stopRecording();
        }
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.switchToFullScreen);
  },
  destroyed() {
    this.bus.$off("sin-sync");
  },
  async mounted() {
    
    // HandGestureRecognition.start({});

    // HandGestureRecognition.addListener("onHandLandmarkerResult", (response) => {
    //     console.log("HandLandmarkerResult: ", response["result"])
    // })

    // document.addEventListener("aaaaaa", ((event: CustomEvent) => {
    //     alert("]]]]]]]]]]]]]]]]]]]]]]]]]]");
    //     alert(event.detail);
    //  }) as EventListener);

    

    
    console.log("MOUNTED", this.isNative)
    if (this.$store.state.useBluetooth) {
      await BleClient.setDisplayStrings({
        scanning: this.$t("scanning"),
        cancel: this.$t("cancel"),
        availableDevices: this.$t("availableDevices"),
        noDeviceFound: this.$t("noDeviceFound")
      });

    document.addEventListener('click', this.switchToFullScreen);
    if (navigator.wakeLock) {
      try {
        window.currentWakeLock = await (navigator.wakeLock as any).request();
        console.log('Wake Lock enabled');
      }
      catch (err) {
        console.log(err);
      }
    }

    // App.addListener('backButton', async data => {
    // await StatusBar.setOverlaysWebView({overlay: true})
    // await StatusBar.hide();
    // await NavigationBar.hide();
    // });

    //   SpeechRecognition.available();

    //   console.log("checkPermissions", SpeechRecognition.checkPermissions());
    //   console.log("**********************", SpeechRecognition.getSupportedLanguages())
    //   SpeechRecognition.requestPermissions();

    //   SpeechRecognition.start({
    //    language: 'ru-RU',
    //    maxResults: undefined,
    //    partialResults: true,
    //    popup: false,
    //  })


    //   SpeechRecognition.addListener("partialResults", (data: any) => {
    //     console.log("partialResults was fired", data.matches);
    //   });


    this.isWebSite = isWebSite();


    let userLocale = window.localStorage.getItem('spica-locale')
    let bleDeviceID = window.localStorage.getItem('bleDeviceID')
    // this.$store.commit('toogleMic', { on: !!+(window.localStorage.getItem('micOn') || 0) })
    this.$store.commit('toogleMic', { on: false })
    if (Capacitor.getPlatform() == "ios" && bleDeviceID) {
      this.$store.commit("bleDeviceID", bleDeviceID);
    }
    this.$store.commit('setVolume', { volume: window.localStorage.getItem('volume') || 100 })
    this.$store.dispatch("changeThemeShade", window.localStorage.getItem('spica-theme-shade') || "black");

    await this.bleInitialize();
    
    if (this.isNative) {
      await this.bleConnect()
    }

    if (Capacitor.isNativePlatform()) {
      this.bleLoader = false;
      await KeepAwake.keepAwake();
      if (!userLocale) {
        userLocale = (await Device.getLanguageCode()).value;
      }
      this.$store.dispatch("changeLocale", userLocale);
    } else {
      if (!userLocale) {
        userLocale = navigator.languages && navigator.languages.length
          ? navigator.languages[0]
          : navigator.language;
        userLocale = userLocale.slice(0, 2);
      }
      this.$store.dispatch("changeLocale", userLocale);
    }

    // if (location.protocol == "http:" && !this.$store.state.DEBUG) {
    //   this.$store.state.useBluetooth = false;
    // }

    window.resizeTo(700, 700);

    if (window.localStorage.getItem('100') == "1") {
      this.$store.commit('unlockInterface');
    }

    const that = this;

    // if (this.$cordova.platformId == "android") {
    //     const permissions = this.$cordova.plugins.permissions;
    //     console.log("check permission RECORD_AUDIO");
    //     permissions.checkPermission(
    //       permissions.RECORD_AUDIO,
    //       function (status) {
    //         if (status.hasPermission) {
    //           that.$store.dispatch("setRecordAudioPermission", true);
    //           that.$store.dispatch("setSRInitialization", true);
    //           console.log("App has RECORD_AUDIO permission")
    //           SR.initVoskRecognition(
    //             (success) => {
    //               console.log("initRecognition", success);
    //               that.$store.dispatch("setSRInitialization", false);
    //               that.$store.dispatch("setSRInitialized", true);
    //               if (
    //                 that.$store.state.voiceMode1 ||
    //                 that.$store.state.voiceMode2
    //               ) {
    //                 SR.startVoskRecognition(
    //                   (success) => {
    //                     console.log("startRecognition", success);
    //                     that.$store.dispatch("setSRStarted", true);
    //                   },
    //                   (error) => {
    //                     console.log("Recognition start error", error);
    //                   }
    //                 );
    //               }
    //             },
    //             (error) => {
    //               console.log("Recognition init error", error);
    //             }
    //           );
    //         } else {
    //           console.log("App has no RECORD_AUDIO permission")
    //         }
    //       }
    //     );
    //   }
    if (Capacitor.isNativePlatform()) {
      that.$store.dispatch("setRecordAudioPermission", true);
      that.$store.dispatch("setSRInitialization", true);

      // SR.initVoskModel().then((r) => {
      //   console.log("///////////////////////////////////////////////////////////", r)
      // }).catch((e) => {
      //   console.log("///////////////////////////////////////////////////////////", e)
      // })
      if (this.$store.state.micOn) {
        await that.SRInitialize();
      }
    }
    i18n.locale = this.$store.state.locale;
    this.$vuetify.theme.dark =
      window.localStorage.getItem("spica-theme-shade") == "black";
    const self = this;
    self.selectedScreenComponent =
      self.$store.state.themes.current + "-" + self.$store.state.themes.shade;

    
      let devices = [];
      
      if (Capacitor.getPlatform() == "ios" && bleDeviceID) {
        devices = await BleClient.getDevices([bleDeviceID]);
        if (!devices || devices.length == 0) {
          this.$store.commit("bleDeviceID", null);
          window.localStorage.removeItem('bleDeviceID');
          this.$store.commit("useBluetooth", false);
        } else {
          this.$store.commit("useBluetooth", true);
        }
      }
      if (Capacitor.getPlatform() == "android") {
        await this.enableBluetoothAndroid();
        devices = await BleClient.getBondedDevices();
        this.$store.commit("useBluetooth", false);
      }
      this.bleLoader = false;
     
      for (const device of devices) {
        if (device.name == "SPICA") {
          this.$store.commit("bleDeviceID", device.deviceId);
          this.$store.commit("useBluetooth", true);
          await this._bleConnect(device.deviceId);
          break;
        }
      }
    }
    this.sync();
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    switchToFullScreen() {
      if (detectMob() && window.innerHeight != screen.height) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
          this._lockScreenOrientation();
        }
      }
    },
    async bleInitialize() {
      console.log("INITIALIZE BLUETOOTH");
      try {
        if (Capacitor.getPlatform() == "android") {
          await BleClient.initialize({ androidNeverForLocation: true });
        } else {
          await BleClient.initialize();
        }
        console.log("BLUETOOTH INITIALIZED");
      } catch (e) {
        console.log("************", e);
        if (!this.isWebSite) {
          this.$store.commit("useBluetooth", false);
          
        }
      }
      const that = this;
      setTimeout(() => {
        that.splashKey++;
      }, 50);
    },
    async SRInitialize() {
      // const srInitialized = await SR.pluginIsReady();
      // while (!srInitialized.value) {
      //   const that = this;
      //   setTimeout(() => {
      //     that.SRInitialize();
      //   }, 50);
      //   return
      // }
      try {
        if (!this.nativeSRInitialized && this.$store.state.micOn) {
          try {
            console.log("---------------------init SR: ")
            const resp = (await SR.initModel({ lang: this.$store.state.locale, mode: this.$store.state.voiceMode1 })).response;
            console.log("initModel response: ", resp)
            this.nativeSRInitialized = true;
            SR.addListener("onResult", (response) => {
              this.onSpeechRecognitionResult(response);
            })
            SR.addListener("onPartialResult", (response) => {
              this.onSpeechRecognitionResult(response);
            })
            SR.addListener("onFinalResult", (response) => {
              this.onSpeechRecognitionResult(response);
            })
            return true
          } catch (e) {
            console.log("initModel response: ", e)
            // alert("startRecognition")
            //const r = await SR.startRecognition({ lang: this.$store.state.locale, mode:this.$store.state.voiceMode1 });
            // alert("startRecognition resp: " + r)
            //console.log("startRecognition", r)

          }
        }
      } catch (e) {
        console.log("SPEECH RECOGNITION ERROR", e)
        return false
      }
    },
    async enableBluetoothAndroid() {
      if (Capacitor.getPlatform() == "android") {
        let isEnabled = await BleClient.isEnabled();
        if (!isEnabled) {
          try {
            await BleClient.requestEnable();
          } catch {
            if (!this.isWebSite) {
              this.$store.dispatch("requestFullScreen", false);
              this.$store.commit("useBluetooth", false);
            }
            this.bleLoader = false;
          }
        }
      }
    },
    onDisconnect: async function (deviceId: string) {
      console.log(`Device ${deviceId} disconnected`);
      this.$store.commit("errorSnackbarBLE", true)
      setTimeout(this._bleConnect, 1000, deviceId);
    },
    _bleConnect: async function (deviceId) {
      try {
        await BleClient.connect(deviceId, (deviceId) => this.onDisconnect(deviceId), { timeout: Capacitor.getPlatform() == 'ios' ? 60000 * 60 : 10000 });
      } catch (e) {
        //alert("BLE Connection Error: " + deviceId + " " + e)
      }
      if (Capacitor.getPlatform() == "android") {
        console.log("Android Request Connection Priority")
        try {
          await BleClient.requestConnectionPriority(deviceId, 1);
        } catch (e) {
          await this.onDisconnect(deviceId);
        }
      }
      this.$store.commit("errorSnackbarBLE", false);
      this.$store.commit("bleDeviceID", deviceId);
      window.localStorage.setItem('bleDeviceID', deviceId);
      // alert(`Device ${deviceId} connected!!!`);
      // alert("v3")
      
      await bleStartNotification((notification) => {
        // alert(`receive remote notification ` + notification["key"]);
        switch (notification["key"]) {
          case "p":
            this.$store.dispatch("switchPedal", +notification["val"])
            break;
          case "f":
            this.$store.commit("freqNotification", +notification["val"])
            break;
          case "l":
            this.$store.commit("levelNotification", +notification["val"])
            break;
          case "d2":
            this.$store.commit("duty2Notification", +notification["val"])
            break;
          default:
            break;
        }
      })
      
      if (!this.$store.state.bleInitialized) {
        this.$store.dispatch("setBleInitialized", true);
      }
    },
    bleConnect: async function () {
      console.log("CHECK BLE ENABLED")
      const isEnabled = await BleClient.isEnabled();
      console.log("BLE ENABLED", isEnabled)
      if (!isEnabled || Capacitor.getPlatform() == "android") {
        this.$store.commit("useBluetooth", false);
        return
      }
      console.log("BLE ENABLED", this.$store.state.useBluetooth)
      if (this.$store.state.useBluetooth) {
        let deviceId = this.$store.state.bleDeviceID;
        try {
          this.bleLoader = true;
          if (!deviceId) {
            this.device = await BleClient.requestDevice({
              services: [SERVICE_UUID],
              scanMode: 2,
              allowDuplicates: true
            });
            console.log("REQUESTED DEVICE: " + this.device.deviceId);
            this.$store.commit("useBluetooth", true);
            deviceId = this.device.deviceId;
          }
        } catch (e) {
          console.log("REQUEST DEVICE ERROR: " + e);
          if (!this.isWebSite) {
            this.$store.commit("useBluetooth", false);
          }
          this.bleLoader = false;
          return
        }
        await this._bleConnect(deviceId);
        //if (this.$store.state.wifiOn) {
        //  this.$store.dispatch("setWifiMode", { wifiOn: false });
        //}
        console.log('Connected to device: ', deviceId);
        this.switchToFullScreen()
        this.$store.dispatch("requestFullScreen", false);
      }
    },
    snackBarTouchStart: function () {
      this.timer = setTimeout(this.snackBarOnLongTouch, this.touchduration);
    },
    snackBarTouchEnd: function () {
      if (this.timer)
        clearTimeout(this.timer);
    },
    snackBarOnLongTouch: function () {
      let code = prompt("Enter the code");
      if (code.toLowerCase() == "tr") {
        window.localStorage.setItem('100', "1");
        this.$store.commit('unlockInterface');
        this.$store.commit('enableServiceMode');
        this.settingsAccordionKey += 1;
        this.$forceUpdate();
      } else {
        alert("Invalid code!");
      }
    },
    sinClick: function () {
      this.carouselIndex = +!this.carouselIndex;
      this.bus.$emit("sin-click", { carouselIndex: this.carouselIndex });
    },
    getVoiceCommand(text) {
      function getCommandIndex(prefix) {
        let fromIndex = text.indexOf(prefix);
        if (fromIndex >= 0) {
          return fromIndex + prefix.length;
        }
        return fromIndex;
      }
      let fromIndex = -1;
      fromIndex = getCommandIndex("с пика ");
      if (fromIndex < 0) {
        fromIndex = getCommandIndex("шпика ");
      }
      if (fromIndex < 0) {
        fromIndex = getCommandIndex("с пиков ");
      }
      if (fromIndex < 0) {
        fromIndex = getCommandIndex("с бега ");
      }
      if (fromIndex < 0) {
        fromIndex = getCommandIndex("спи как ");
      }
      if (fromIndex < 0) {
        fromIndex = getCommandIndex("пика ");
      }
      if (fromIndex < 0) {
        fromIndex = getCommandIndex("спида ");
      }
      if (fromIndex < 0) {
        fromIndex = getCommandIndex("стыка ");
      }
      if (fromIndex < 0) {
        fromIndex = getCommandIndex("спикер ");
      }
      if (fromIndex < 0) {
        fromIndex = getCommandIndex("сбегаю ");
      }
      if (fromIndex < 0) {
        fromIndex = getCommandIndex("сбегать ");
      }
      if (fromIndex < 0) {
        fromIndex = getCommandIndex("сбегая ");
      }
      if (fromIndex < 0) {
        fromIndex = getCommandIndex("спирька ");
      }
      let startStopText = text;
      text = text.substring(fromIndex);
      if (this.$store.state.voiceMode1) {
        if (fromIndex < 0) {
          fromIndex = 0;
        }
        startStopText = startStopText.substring(fromIndex);
      }
      if (text.indexOf("частота ") >= 0 || text.indexOf("чистота ") >= 0) {
        text = text.substring("частота ".length);
        console.log("FREQ", wordsToNumber(text));
        return { cmd: "FREQ", value: wordsToNumber(text) };
      }
      if (text.indexOf("мощность ") >= 0) {
        text = text.substring("мощность ".length);
        console.log("LEVEL", wordsToNumber(text));
        return { cmd: "LEVEL", value: wordsToNumber(text) };
      }
      if (startStopText == "сто" || startStopText == "стоп") {
        return { cmd: "STOP" };
      }
      if (startStopText.indexOf("старт") >= 0) {
        return { cmd: "START" };
      }

      if (text.indexOf("гуру") >= 0 || text.indexOf("гору") >= 0) {
        return { cmd: "GURU" };
      }

      if (text.indexOf("профессионал") >= 0) {
        return { cmd: "PROFESSIONAL" };
      }

      if (text.indexOf("новичок") >= 0) {
        return { cmd: "BEGINNER" };
      }

      if (text.indexOf("ротор") >= 0) {
        return { cmd: "ROTOR" };
      }

      return "";
    },
    sendDefaultParams() {
      setTimeout(() => {
        fetchData.post("/api", { n: 1, f: 60 })
      }, 100);
      setTimeout(() => {
        fetchData.post("/api", { n: 1, d: 30 })
      }, 100);
      setTimeout(() => {
        fetchData.post("/api", { n: 1, l: 90 })
      }, 100);
      setTimeout(() => {
        fetchData.post("/api", { n: 1, d1: 100 })
      }, 100);
      setTimeout(() => {
        fetchData.post("/api", { n: 1, d2: 0 })
      }, 100);
      setTimeout(() => {
        fetchData.post("/SAVE_DELAY_SETTINGS", { d: 0, p: 50 })
      }, 100);
    },
    onSpeechRecognitionResult(result) {
      result = JSON.parse(result.result);
      if (Object.prototype.hasOwnProperty.call(result, "cmd")) {
        voiceCommandToAction(this, result.cmd, wordsToNumber(result.val));
        return;
      }
      if (Object.prototype.hasOwnProperty.call(result, "partial")) {
        console.log("__onSpeechRecognitionResult:", result);
        if (result.partial.length > 0) {
          console.log("__onSpeechRecognitionResult1:", result);
          const { cmd, value } = this.getVoiceCommand(result.partial);
          console.log("__onSpeechRecognitionResult2:", result, cmd, value);
          switch (cmd) {
            case "FREQ": {
              const freq = value > 150 ? 150 : value;
              console.log("FREQ", freq);
              fetchData.post("/api", {
                n: 1,
                f: freq,
              });
              this.$store.dispatch("freqFromVoice", freq);
              break;
            }
            case "LEVEL": {
              const level = value > 240 ? 240 : value;
              console.log("LEVEL", level);
              fetchData.post("/api", {
                n: 1,
                l: level,
              });
              this.$store.dispatch("levelFromVoice", level);
              break;
            }
            case "START": {
              if (!this.$store.state.pedalOn)
                this.$store.dispatch("switchPedal");
              break;
            }
            case "STOP": {
              if (this.$store.state.pedalOn)
                this.$store.dispatch("switchPedal");
              break;
            }
            case "PROFESSIONAL": {
              this.selectedScreenComponent =
                "professional-" + this.$store.state.themes.shade;
              break;
            }
            case "BEGINNER": {
              this.selectedScreenComponent =
                "beginner-" + this.$store.state.themes.shade;
              break;
            }
            case "GURU": {
              this.selectedScreenComponent =
                "guru-" + this.$store.state.themes.shade;
              break;
            }
            case "ROTOR": {
              this.selectedScreenComponent =
                "rotor-" + this.$store.state.themes.shade;
              break;
            }
            // case "MIC_OFF": {
            //   this.settingsDrawerShown = false;
            //   this.presetsDrawerShown = false;
            //   break;
            // }
            default:
              break;
          }
          // let arr = result.partial.split(" ");
          // console.log(arr);
        }
      }
    },
    isSafari() {
      return _isSafari();
    },
    getPresetIconColor() {
      if (this.$store.state.themes.current == "guru") {
        if (this.$vuetify.theme.dark) {
          return "#fff";
        } else {
          return "#000";
        }
      }
      return undefined;
    },
    topIconsColor() {
      if (this.settingsDrawerShown) {
        if (this.$vuetify.theme.dark) {
          return "white";
        } else {
          return "rgba(0, 0, 0, 0.6)";
        }
      }
    },
    async startFullScreen() {
      if (this.$store.state.useBluetooth) {
        await this.bleConnect()
      } else {
        this.$store.state.requestFullScreen = false;
        this.$store.dispatch("requestFullScreen", false);
      }
    },
    onOrientationChange() {
      try {
        const splashHeader = document.getElementsByClassName("splash-header")[0].clientHeight;
        const splashContent = document.getElementsByClassName("splash-content")[0];
        const splashContentHeight = "calc(100% - " + (splashHeader - 1).toString() + "px)";
        (splashContent as any).style["height"] = splashContentHeight;
      } catch (e) {
        console.log("onOrientationChange", e)
      }

      if (
        window.innerHeight !== screen.height &&
        !this.$store.state.lockScreenOrientationNotSupported
      ) {
        this.$store.dispatch("requestFullScreen", true);
      }
      if (this.orientation == "landscape" && this.settingsDrawerShown) {
        this.changeScreen("ThemesSettings");
      }
      this.rSettingsDrawerShown = this.settingsDrawerShown;
      this.settingsDrawerShown = false;
      setTimeout(() => {
        this.settingsDrawerShown = this.rSettingsDrawerShown;
      }, 50);
    },
    sync() {
      const self = this;
      if (!this.settingsDrawerShown) {
        fetchData.get("/PEDAL_STATUS").then((response) => {
          console.log("RESPONSE", response);
          self.$store.commit("switchPedal", {
            on: Boolean(+String(response.data).charAt(0)),
            mode: +String(response.data).charAt(1),
            wifiOn: +String(response.data).charAt(2),
          });
        }).catch(e => {
          console.log("/PEDAL_STATUS", e.message)
        });
      }
      if (!self.$store.state.useBluetooth) {
        setTimeout(self.sync, 1000);
      }
    },
    trashClick() {
      if (this.trashIsOpen) {
        this.$refs.theme.removeSelectedPresets();
      } else {
        // this.$store.dispatch("selectPreset", { key: "", allowMultiple: false });
      }
      this.trashIsOpen = !this.trashIsOpen;
    },
    presetIconDialogClick(iconName: string) {
      this.$refs.theme.addPreset(iconName);
      this.presetIconDialogIsOpen = false;
    },
    onPresetItemClick(preset: Preset) {
      this.$refs.theme.selectPreset(preset, this.trashIsOpen);
    },
    drawerWidth(percents: number) {
      // if (percents == 100) return this.windowWidth;
      return this.windowWidth > 500
        ? (this.windowWidth * percents) / 100
        : this.windowWidth;
    },
    showPresetIconDialog() {
      this.presetIconDialogIsOpen = true;
    },
    changeScreen(screen: string) {
      const self = this;
      this.selectedScreenComponent = screen;
      if (this.settingsDrawerShown) {
        this.selectedSettingsScreenComponent = screen;
      } else {
        this.selectedScreenComponent =
          screen + "-" + this.$store.state.themes.shade;
        if (this.lockScreenOrientation != this.orientation) {
          this.$store.dispatch("requestFullScreen", true);
        }
      }
    },
    settingsShow() {
      this.carouselIndex = 0;
      if (!this.settingsDrawerShown) {
        this.settingsDrawerShown = true;
        setTimeout(() => {
          if (this.selectedSettingsScreenComponent)
            this.selectedScreenComponent = this.selectedSettingsScreenComponent;
          if (!this.selectedScreenComponent) {
            this.selectedScreenComponent =
              this.$store.state.themes.current +
              "-" +
              this.$store.state.themes.shade;
          }
        }, 500);
      } else {
        this.selectedScreenComponent =
          this.$store.state.themes.current +
          "-" +
          this.$store.state.themes.shade;
        // setTimeout(() => {
        this.settingsDrawerShown = false;
        // }, 500);
        console.log("Component", this.selectedScreenComponent);
      }
    },
  },
});
</script>

<style lang="scss">
$preset-footer-height: 100px;


.v-navigation-drawer {
  transition-duration: 0s!important;
}
// .settings-open .presets-icon {
//   display: none;
// }

// .presets-open .settings-icon {
//   display: none;
// }

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  // @supports (-webkit-touch-callout: none) {
  //   height: var(--app-height);
  // }
}

.sin-btn {

  // height: 50px;
  .sin {

    // width: 100%;
    canvas {
      width: 37px;
    }
  }
}

.v-snack__wrapper {
  min-width: 225px !important;
}

.cordova-app .v-application {

  // padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  // padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  .v-snack {
    top: env(safe-area-inset-top);
  }

  height: 100vh;
}

.sin-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;

  .sin-btn {
    .sin-btn {
      align-self: center;
      display: flex;
    }
  }
}

.fullScreen,
.lockScreen {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 20000;
  display: flex;
  justify-content: center;
  opacity: 0.7;

  img {
    height: 20vh;
  }

  table {
    height: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  span {
    align-self: center;
    color: white;
  }
}

.fullScreen {
  margin-top: calc(var(--insets-top) * -1);
  height: calc(100vh + (var(--insets-top)));
}

.lockScreen {
  opacity: 1;
  z-index: 10000;
  display: none;
  margin-top: calc(var(--insets-top) * -1);
  height: calc(100vh + (var(--insets-top)))
}

.gear-bg {
  background-image: url(/i/p/gear-bg.svg);

  &.active {
    background-image: url(/i/p/gear-bg-active.svg);
  }

  background-size: 100% 100%;
  padding: 5px;
  padding: 7px 6px 5px 5px;
  transform: rotate(180deg);

  svg {
    height: 36px;
    width: 35px;
    transform: rotate(180deg);
  }
}

.brain-bg {
  background-image: url(/i/p/gear-bg.svg);
  transform: rotate(180deg);

  &.active {
    background-image: url(/i/p/gear-bg-active.svg);
  }

  background-size: 100% 100%;
  padding: 6px 7px 6px 4px;

  svg {
    height: 36px;
    width: 35px;
    transform: rotate(180deg);
  }
}

.drawer {
  padding-top: 65px;
  height: 100% !important;
}

.settings-icon,
.presets-icon {
  z-index: 6;
  margin: 16px 16px 0px 16px;
}

.presets {

  // z-index: 1000;
  // z-index: 99999;
  .no-items {
    height: 100%;
    width: 100%;
    text-align: center;
    color: white;
  }

  .icon-dialog {
    // position: absolute;
    // text-align: center;
    // margin: 0 auto;
    // max-width: 650px;
    // width: 100%;
    background-color: black;
    // z-index: 10;
    // top: 50%;
    // transform: translateY(-50%);
    background: linear-gradient(218.19deg, #5b5b5b -21.73%, #343434 88.77%),
      #c4c4c4;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);

    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0px;

      .item-bg {
        background-image: url(/i/p/preset-icon-dialog-bg.svg);
        width: 80px;
        height: 85px;
        justify-content: center;
        display: flex;
        align-items: center;
        margin: 10px;
        background-size: cover;

        svg {
          width: 72px;
        }

        .eye1 {
          width: 70px;
          margin-left: -3px;
        }

        .face {
          width: 63px;
          margin-right: 7px;
        }

        .disk {
          width: 63px;
          margin-top: 4px;
        }

        .eyelashes {
          margin-right: 5px;
        }

        .clover,
        .lips,
        .lips1,
        .eyebrows,
        .eyebrows1,
        .eyebrows2 {
          margin-top: 3px;
          margin-right: 2px;
        }
      }
    }
  }

  .container-main {
    display: flex;
    justify-content: center;
    height: calc(100% - #{$preset-footer-height});
    margin-top: 40px;

    .list {
      margin-top: 3vh;
      padding-top: constant(safe-area-inset-top);
      margin-top: env(safe-area-inset-top);
      width: 95%;

      .highlighted {
        background-color: transparent;

        &::before {
          opacity: 0 !important;
        }

        margin: 0px;
        border-radius: 15px;
      }

      .activeItem {
        .preset {
          background: radial-gradient(123.74% 794.75% at 94.64% -163.47%,
              #2c2c2c 0%,
              rgba(14, 14, 14, 0.55) 100%),
            linear-gradient(0deg, #898989, #898989);
          box-shadow: inset -2px 2px 4px rgba(0, 0, 0, 0.2);
        }

        .icon-bg {
          background-image: url(/i/p/preset-icon-bg-selected.svg);
        }
      }
    }

    .icon-bg {
      background-image: url(/i/p/preset-icon-bg.svg);
      background-size: cover;
      height: 50px;
      width: 54px;
      z-index: 1;
      justify-content: center;
      display: flex;
      align-items: center;

      svg {
        width: 32px;
        height: 32px;
      }

      canvas {
        width: 45px;
        height: 45px;
        padding: 5px;
      }
    }

    .preset {
      justify-content: space-between;
      align-items: center;
      display: flex;
      width: 35vw;
      height: 32px;
      margin-top: -2px;
      font-size: 25px;
      margin-left: -3px;
      background: radial-gradient(164.53% 1064.1% at 138.1% -251.83%,
          rgb(119, 119, 119) 0%,
          rgba(14, 14, 14, 0.48) 100%),
        linear-gradient(0deg, rgb(137, 137, 137), rgb(137, 137, 137)) black;
      box-shadow: rgb(0 0 0 / 20%) -2px 2px 4px inset;
      font-family: Rajdhani;
      font-weight: 500;
      border-radius: 0px 15px 15px 0px;

      div {
        width: 100%;
      }
    }
  }

  .footer {
    height: $preset-footer-height;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .save-btn {
      margin-left: 2vw;

      .arrow {
        height: 30px;
        bottom: 28px;
        position: absolute;
      }
    }

    .trash {
      position: absolute;
      z-index: 100;
      bottom: 28px;
      right: 1vw;
      height: 62px;
    }

    .trash-open {
      position: absolute;
      z-index: 100;
      bottom: 28px;
      right: 1vw;
      height: 62px;
    }
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin-back {
    100% {
      -moz-transform: rotate(-360deg);
    }
  }

  @-webkit-keyframes spin-back {
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }

  @keyframes spin-back {
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
}

.v-ripple__container {
  display: none !important;
}

.presets {
  @media screen and (min-width: 700px) {
    .container-main {
      .preset {
        height: 5vh;
        font-size: 4vh !important;
      }

      .icon-bg {
        height: 7vh;
        width: 7vh;
      }
    }

    .footer {
      .save-btn .arrow {
        height: 5vh;
        width: 8vh;
      }

      .trash-btn svg {
        height: 10vh;
        width: 8vh;
      }

      .trash-btn .trash-open {
        height: 10vh;
        width: 8vh;
      }
    }
  }

  .v-list-item-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .v-list-item {
      display: flex;
      flex: none;
    }
  }

  .theme--light.v-list-item:hover::before,
  .theme--dark.v-list-item:hover::before {
    opacity: 0 !important;
  }
}

svg {
  image-rendering: pixelated;
}

.select-wrap .minus {
  font-size: 4vh;
  margin-bottom: 2vh;
  vertical-align: top;
}

.v-input--radio-group--row .v-input--radio-group__input {
  justify-content: center;
}

.settings-open .gear-bg {
  background: none !important;
}

.settings-open .brain-bg {
  background: none !important;
}

.theme--light.v-btn:hover::before,
.theme--dark.v-btn:hover::before {
  opacity: 0 !important;
}

input[role="radio"] {
  opacity: 1 !important;
}

.v-app-bar__nav-icon .v-ripple__container {
  display: none !important;
}

.theme--dark .v-application .deep-purple--text.text--accent-4 {
  color: white !important;
}

.v-application .deep-purple--text {
  color: inherit !important;
}

@media (orientation: landscape) {
  .settings-open .v-navigation-drawer__content {
    margin-top: 2vh;
  }
}

.settings-item {
  width: 90%;
  padding: 2% 5% 0% 5%;
}

.settings-open {

  .sin-btn-container,
  .presets-icon {
    display: none;
  }
}

.theme--dark .settings-item {
  background: #1e1e1e;
}

.theme--dark.v-application .indicator-button-group {
  color: initial;
}

.v-application.landscape .deep-purple--text.text--accent-4 {
  color: inherit !important;
}

.v-snack {
  padding: initial !important;
}

.v-application .green--text.text--darken-1 {
  color: inherit !important;
}

.v-application .green--text {
  color: inherit !important;
}

* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  // touch-action: manipulation;
}

.row.sliderContainer {
  flex-wrap: inherit;
}

.v-btn__content {
  flex: auto;
}

.v-navigation-drawer--absolute {
  z-index: 5 !important;
}

.v-application--wrap {

  @supports (-webkit-touch-callout: none) {
    min-height: var(--app-height) !important;
  }

  @media screen and (min-width: 700px) and (min-height: 400px) {
    .v-snack__content {
      font-size: 3vh;
      line-height: 3vh;
    }

    .v-list--dense .v-list-item .v-list-item__title {
      font-size: 1.3rem;
      line-height: 1.5rem;
    }
  }
}

@media (orientation: landscape) {
  .presets {
    .v-list-item-group {
      display: flex !important;
    }

    .container-main .preset {
      width: 40vw !important;
    }
  }
}

.theme--light.v-application.settings-open {
  background: #ffff!important;
}

.theme--dark.v-application.settings-open {
  background: #363636!important;
}

</style>
