import Icon from "../icons/Icon.vue";
import { DeviceOrientationMixin } from "../mixins";
import slider from "../slider.vue";
import Timer from "../timer.vue";
import fetchData from '@/fetchData'

const theme = "fallOut"

export const FalloutMixin = {
  components: { slider, Icon, Timer },
  name: "FallOut",
  mixins: [DeviceOrientationMixin],
  data() {
    return {
      fanActive: 0,
      startButton1Active: false,
      startButton2Active: false,
      freq: 80,
      duty: 100,
      level: 90,
      duty1: 100,
      duty2: 0,
      carouselIndex: 0,
      rollerRange: [100, -100],
      s1: 0,
      s2: 0,
      s3: 0,
      s4: 0,
      r1: 0,
      r2: 0,
      maxValue1: 100,
      maxValue2: 100,
      sinRequest: ""
    };
  },
  created: function () {
    this.$store.dispatch("loadState", { theme: "fallOut", num: 1 }).then(response => {
      this.freq = response.data.f;
      this.duty = response.data.d;
      this.level = response.data.l;
      this.duty1 = response.data.d1;
      this.duty2 = response.data.d2;
      this.s1 = response.sin.s1;
      this.s2 = response.sin.s2;
      this.r1 = this.s1;
      this.r2 = this.s2;
      this.s3 = response.sin.s3;
      this.s4 = response.sin.s4;
      if (response.pedal.on) {
        this.$store.dispatch("switchPedal");
      }
    }).catch(reason => {
      console.log("Load state error: ", reason)
    });
  },
  mounted: function () {
    console.log("lockScreenOrientation('landscape') start")
    this.lockScreenOrientation('landscape')
    console.log("lockScreenOrientation('landscape') end")
    fetchData.post("/SAVE_DELAY_SETTINGS", {
      d: 0,
      p: 50,
    })
  },
  methods: {
    onStart() {
      this.startButtonClick(0)
    },
    InductionRotorToogle(num) {
      if (this.fanActive !== num && this.$store.state.pedalOn) return;
      this.fanActive = num;
      if (this.fanActive === 1) {
        this.$store.dispatch("loadState", { theme: "fallOut", num: 2, loadPresets: false }).then((response) => {
          this.level = response.data.l4;
        })
      } else {
        this.$store.dispatch("loadState", { theme: "fallOut", num: 1, loadPresets: false }).then(response => {
          this.level = response.data.l;
        })
      }
    },
    changeLevel(val) {
      this.levelValue = val;
    },
    startButtonClick(num) {
      if (this.startButton1Active && num === 1) return;
      if (this.startButton2Active && num === 0) return;
      if (num == 0) {
        this.startButton1Active = !this.startButton1Active;
        if (this.fanActive == 1) {
          fetchData.post("/api", {
            n: 2,
            l4: this.rotorLevel,
            "dr": true
          })
        } else {
          fetchData.post("/api", {
            n: 1,
          })
        }
        this.$store.dispatch("switchPedal");
      } else if (num == 1) {
        this.startButton2Active = !this.startButton2Active;
        fetchData.post("/api", {
            n: 3,
        })
        this.$store.dispatch("switchPedal");
      }
    },
    rollerOnChange(key, value, final) {
      this["s" + key] = value;
      const max = Math.abs(this.rollerRange[0]);

      switch (key) {
        case 1:
          if (Math.abs(value) >= max - Math.abs(this.s2)) {
            this.$refs.roller1.stop();

            const s1 = max - Math.abs(this.s2);
            if (this.s1 < 0) {
              this.s1 = -s1;
            } else {
              this.s1 = s1;
            }

            this.r1 = this.s1;
            this.maxValue1 = this.r1;
            this.$refs.roller1.select(this.r1);
          } else {
            if (this.s1 < 0) {
              this.maxValue1 = -max;
            } else {
              this.maxValue1 = max;
            }
          }
          break;
        case 2:
          if (Math.abs(value) >= max - Math.abs(this.s1)) {
            this.$refs.roller2.stop();
            const s2 = max - Math.abs(this.s1);
            if (this.s2 < 0) {
              this.s2 = -s2;
            } else {
              this.s2 = s2;
            }
            this.r2 = this.s2;
            this.maxValue2 = this.r2;
            this.$refs.roller2.select(this.r2);
          } else {
            if (this.s2 < 0) {
              this.maxValue2 = -max;
            } else {
              this.maxValue2 = max;
            }
          }
          break;
        default:
          break;
      }
      if (final) {
        fetchData.post("/SAVE_SIN_SETTINGS", this.$refs.sin.req)
      }
    },
    addPreset(iconName) {
      const preset = {
        iconName: iconName,
        freq: this.freq,
        duty: this.duty,
        level: this.level,
        duty1: this.duty1,
        duty2: this.duty2,
        s1: this.s1,
        s2: this.s2,
        s3: this.s3,
        s4: this.s4,
      }

      this.$store.dispatch("addPreset", { theme, preset });
    },
    selectPreset(preset, trashIsOpen) {
      const key = preset.key;
      this.$store.dispatch("selectPreset", { theme, key, trashIsOpen });
      this.freqValue = preset.freq;
      this.dutyValue = preset.duty;
      this.levelValue = preset.level;
      this.duty1Value = preset.duty1;
      this.duty2Value = preset.duty2;
      this.s1 = preset.s1;
      this.s2 = preset.s2;
      this.r1 = this.s1;
      this.r2 = this.s2;
      this.s3 = preset.s3;
      this.s4 = preset.s4;
      setTimeout(() => {
        fetchData.post("/SAVE_SIN_SETTINGS", this.$refs.sin.req)
      }, 100);
      if (trashIsOpen) {
        return
      }
      if (preset.selected && !this.$store.state.pedalOn) {
        this.onStart()
      }
      if (!preset.selected && this.$store.state.pedalOn) {
        this.onStart()
      }
    },
    removeSelectedPresets() {
      this.$store.dispatch("removeSelectedPresets", theme);
    },
    getValues() {
      const values = [];
      for (let i = this.rollerRange[0]; i >= this.rollerRange[1]; i--) {
        values.push({
          value: i,
          text:
            i < 0 ? '<span class="minus">-</span>' + String(Math.abs(i)) : i,
        });
      }
      return values;
    },
  },
  computed: {
    freqValue: {
      get() {
        return this.freq;
      },
      set(val) {
        if (this.fanActive == 1) return;
        if (this.startButton2Active) return;
        this.freq = val
        fetchData.post("/api", {
          n: 1,
          f: val,
        })
      },
    },
    dutyValue: {
      get() {
        return this.duty;
      },
      set(val) {
        if (this.fanActive == 1) return;
        if (this.startButton2Active) return;
        this.duty = val
        fetchData.post("/api", {
          n: 1,
          d: val,
        })
      },
    },
    levelValue: {
      get() {
        return this.level;
      },
      set(val) {
        if (this.startButton2Active) return;
        this.level = val
        if (this.fanActive == 0) {
          fetchData.post("/api", {
            n: 1,
            l: val,
          })
        } else {
          fetchData.post("/api", {
            n: 2,
            l4: val,
          })
        }
      },
    },
    duty1Value: {
      get() {
        return this.duty1;
      },
      set(val) {
        if (this.startButton2Active) return;
        if (this.fanActive == 1) return;
        this.duty1 = val
        fetchData.post("/api", {
          n: 1,
          d1: val,
        })
      },
    },
    duty2Value: {
      get() {
        return this.duty2;
      },
      set(val) {
        if (this.fanActive == 1) return;
        if (this.startButton2Active) return;
        this.duty2 = val
        fetchData.post("/api", {
          n: 1,
          d2: val,
        })
      },
    },
  },

}
