import fetchData from "@/fetchData";


export function isSafari() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf("safari") != -1) {
    if (ua.indexOf("chrome") > -1) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}

export function isWebSite() {
  if (window.window.location.hostname.indexOf("spica.") >= 0) {
    return true;
  } 
  return false

  // return true;
}


export function isBluefy() {
  return navigator.userAgent.indexOf("Bluefy") > -1;
}

export function wordsToNumber(words) {
  if (!words) return words;
  const wordsNumberMap = {
    'один': 1,
    'два': 2,
    'три': 3,
    'четыре': 4,
    'пять': 5,
    'шесть': 6,
    'жить': 6,
    'семь': 7,
    'восемь': 8,
    'девять': 9,
    'одиннадцать': 11,
    'двенадцать': 12,
    'тринадцать': 13,
    'четырнадцать': 14,
    'пятнадцать': 15,
    'шестнадцать': 16,
    'семнадцать': 17,
    'восемнадцать': 18,
    'девятнадцать': 19,
    'десять': 10,
    'двадцать': 20,
    'тридцать': 30,
    'сорок': 40,
    'пятьдесят': 50,
    'шестьдесят': 60,
    'семьдесят': 70,
    'восемьдесят': 80,
    'девяносто': 90,
    'сто': 100,
    'что': 100,
    'стоп': 100,
    'стол': 100,
    'то': 100,
    'двести': 200,
    'one': 1,
    'two': 2,
    'three': 3,
    'four': 4,
    'five': 5,
    'six': 6,
    'live': 6,
    'seven': 7,
    'eight': 8,
    'nine': 9,
    'eleven': 11,
    'twelve': 12,
    'thirteen': 13,
    'fourteen': 14,
    'fifteen': 15,
    'sixteen': 16,
    'seventeen': 17,
    'eighteen': 18,
    'nineteen': 19,
    'ten': 10,
    'twenty': 20,
    'thirty': 30,
    'forty': 40,
    'fifty': 50,
    'sixty': 60,
    'seventy': 70,
    'eighty': 80,
    'ninety': 90,
    'one hundred': 100,
    'what': 100,
    'stop': 100,
    'table': 100,
    'then': 100,
    'two hundred': 200,
    'uno': 1,
    'dos': 2,
    'tres': 3,
    'cuatro': 4,
    'cinco': 5,
    'seis': 6,
    'en vivo': 6,
    'siete': 7,
    'ocho': 8,
    'nueve': 9,
    'once': 11,
    'doce': 12,
    'trece': 13,
    'catorce': 14,
    'quince': 15,
    'dieciséis': 16,
    'diecisiete': 17,
    'dieciocho': 18,
    'diecinueve': 19,
    'diez': 10,
    'veinte': 20,
    'treinta': 30,
    'cuarenta': 40,
    'cincuenta': 50,
    'sesenta': 60,
    'setenta': 70,
    'ochenta': 80,
    'noventa': 90,
    'cien': 100,
    'qué': 100,
    'parar': 100,
    'tabla': 100,
    'entonces': 100,
    'doscientos': 200,
  };
  const stringArray = words.split(/(\s+)/);
  let result = 0;
  console.log("wordsToNumber", stringArray);
  for (let word of stringArray) {
    result += wordsNumberMap[word] !== undefined ? wordsNumberMap[word] : 0;
  }
  console.log("wordsToNumber=", result);
  return result;
}


export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getLocale(that) {
  let locale = that.$store.state.locale;
  if (locale == "ru") {
    locale = "ru-RU"
  } else if (locale == "es") {
    locale = "es-ES"
  } else if (locale == "jp") {
    locale = "ja-JP"
  } else {
    locale = "en-US"
  }
  return locale
}

export function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}



export function voiceCommandToAction(that, cmd, value){
  switch (cmd) {
    case "FREQ": {
        const freq = value > 150 ? 150 : value;
        console.log("FREQ", freq);
        fetchData.post("/api", {
            n: 1,
            f: freq,
        });
        that.$store.dispatch("freqFromVoice", freq);
        break;
    }
    case "LEVEL": {
        const level = value > 240 ? 240 : value;
        console.log("LEVEL", level);
        fetchData.post("/api", {
            n: 1,
            l: level,
        });
        that.$store.dispatch("levelFromVoice", level);
        break;
    }
    case "START": {
        if (!that.$store.state.pedalOn)
            that.$store.dispatch("switchPedal");
        break;
    }
    case "STOP": {
        if (that.$store.state.pedalOn)
            that.$store.dispatch("switchPedal");
        break;
    }
    case "PROFESSIONAL": {
        that.$store.dispatch("changeTheme", "professional")
        break;
    }
    case "BEGINNER": {
        that.$store.dispatch("changeTheme", "beginner")
        break;
    }
    case "GURU": {
        that.$store.dispatch("changeTheme", "guru")
        break;
    }
    case "ROTOR": {
        that.$store.dispatch("changeTheme", "rotor")
        break;
    }
    case "MIC_OFF": {
        that.stopRecording()
        break;
    }
    default:
        break;
}
}