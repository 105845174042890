<template>
  <div>
    <svg
      v-if="!active"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i16)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.36887 24.2446C1.71139 21.4896 1.07248 18.2486 1.56371 15.0754C2.05489 11.9025 3.64446 8.9999 6.05484 6.85966C6.8808 6.12626 8.1449 6.2013 8.8783 7.02726C9.61169 7.85322 9.53665 9.11732 8.71069 9.85072C6.99069 11.378 5.86427 13.4416 5.51662 15.6873C5.16902 17.9328 5.62034 20.2277 6.7964 22.1826C7.97279 24.138 9.80375 25.6351 11.9822 26.4127C14.1611 27.1903 16.5483 27.1981 18.7352 26.4339C20.9217 25.6698 22.7674 24.1833 23.9621 22.2338C25.1564 20.285 25.6279 17.9922 25.3004 15.7451C24.9729 13.4979 23.8654 11.4286 22.1605 9.89186C21.3401 9.15231 21.2745 7.88768 22.014 7.06723C22.7536 6.24678 24.0182 6.1812 24.8387 6.92075C27.2314 9.07755 28.7956 11.9916 29.2586 15.1682C29.7216 18.345 29.0536 21.581 27.3726 24.324C25.6919 27.0663 23.1042 29.1443 20.0548 30.2099C17.0057 31.2755 13.6783 31.2651 10.6376 30.1799C7.59656 29.0945 5.02601 26.9991 3.36887 24.2446Z"
          fill="#A9A9A9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.4633 -8.74226e-08C16.5678 -1.35705e-07 17.4633 0.89543 17.4633 2L17.4633 13C17.4633 14.1046 16.5678 15 15.4633 15C14.3587 15 13.4633 14.1046 13.4633 13L13.4633 2C13.4633 0.89543 14.3587 -3.91404e-08 15.4633 -8.74226e-08Z"
          fill="#A9A9A9"
        />
      </g>
      <defs>
        <filter
          id="filter0_i16"
          x="0.402344"
          y="0"
          width="28.9994"
          height="33.0015"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
      </defs>
    </svg>
    <svg
      v-else
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i16)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.36887 24.2446C1.71139 21.4896 1.07248 18.2486 1.56371 15.0754C2.05489 11.9025 3.64446 8.9999 6.05484 6.85966C6.8808 6.12626 8.1449 6.2013 8.8783 7.02726C9.61169 7.85322 9.53665 9.11732 8.71069 9.85072C6.99069 11.378 5.86427 13.4416 5.51662 15.6873C5.16902 17.9328 5.62034 20.2277 6.7964 22.1826C7.97279 24.138 9.80375 25.6351 11.9822 26.4127C14.1611 27.1903 16.5483 27.1981 18.7352 26.4339C20.9217 25.6698 22.7674 24.1833 23.9621 22.2338C25.1564 20.285 25.6279 17.9922 25.3004 15.7451C24.9729 13.4979 23.8654 11.4286 22.1605 9.89186C21.3401 9.15231 21.2745 7.88768 22.014 7.06723C22.7536 6.24678 24.0182 6.1812 24.8387 6.92075C27.2314 9.07755 28.7956 11.9916 29.2586 15.1682C29.7216 18.345 29.0536 21.581 27.3726 24.324C25.6919 27.0663 23.1042 29.1443 20.0548 30.2099C17.0057 31.2755 13.6783 31.2651 10.6376 30.1799C7.59656 29.0945 5.02601 26.9991 3.36887 24.2446Z"
          :fill="color"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.4633 -8.74226e-08C16.5678 -1.35705e-07 17.4633 0.89543 17.4633 2L17.4633 13C17.4633 14.1046 16.5678 15 15.4633 15C14.3587 15 13.4633 14.1046 13.4633 13L13.4633 2C13.4633 0.89543 14.3587 -3.91404e-08 15.4633 -8.74226e-08Z"
          :fill="color"
        />
      </g>
      <defs>
        <filter
          id="filter0_i16"
          x="0.402344"
          y="0"
          width="28.9994"
          height="33.0015"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "start",
  mixins: [IconMixin],
  props: ["active"],
});
</script>
