<template>
  <svg
    width="72"
    height="78"
    viewBox="0 0 72 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.0208 76.2267C30.3459 75.5656 29.6711 69.6151 24.9468 68.2927C20.2225 66.9704 14.1483 68.9539 12.7985 68.2927C11.4487 67.6316 10.7737 66.3091 10.7737 63.6644C10.7737 61.0197 8.07413 61.6809 8.74903 59.6974C9.22626 58.2948 8.74903 56.3915 8.07413 55.0691C7.83985 54.6101 9.42375 53.7468 9.42375 51.1021C9.42375 48.4574 8.74903 49.1186 6.04942 48.4574C3.34981 47.7962 2 47.7962 2 46.4738C2 45.1515 8.74903 37.2174 8.74903 32.5892C8.74903 27.961 6.72432 8.78688 29.671 3.49748C52.6177 -1.79193 66.7907 10.1092 69.4903 26.6386C72.1899 43.168 63.4162 48.4574 60.7165 57.0527C58.5569 63.9289 58.9168 70.0558 59.3667 72.2597"
      :stroke="color"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "face",
  mixins: [IconMixin],
});
</script>
