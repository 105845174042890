import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default new VueI18n({
  locale: window.localStorage.getItem('locale') || 'en',
  messages: {
    en: {
      on: "On",
      off: "Off",
      themes: 'Themes',
      language: 'Language',
      pedal: 'Pedal',
      control: 'Control',
      wifi: 'Wifi Settings',
      about: 'About',
      professional: 'Professional',
      beginner: 'Beginner',
      white: 'Light',
      black: 'Dark',
      rotor: 'Rotor',
      errorMessage: 'Waiting for connection',
      errorMessageBLE: 'Waiting for Bluethooth connection',
      fullscreen: 'Tap on the screen to continue... ',
      fullscreen_ble: 'Please connect your device',
      lockscreen: 'Please rotate your device',
      noItems: '',
      fallOut: 'Old School',
      pedalMode1: '1',
      pedalMode2: '2',
      newVersion: 'New version is available',
      installToDevice: 'Install',
      installErrors: "Install Errors",
      installationToDevice: "Installation to device",
      lastVersion: "You have a last version of software",
      installingFirmware: "Installing firmware",
      finishing: "Finalization",
      flashError: "Flash error",
      guru: "Guru",
      deviceReboot: "Rebooting",
      add: "Add",
      cancel: "Cancel",
      apply: "Apply",
      selectColor: "Select color",
      voice: 'Voice',
      calibrationSettings: "Device Calibration",
      calibration: "Calibration",
      calibrationOn: "On",
      calibrationSend: "Send settings to device?",
      yes: "Yes",
      no: "No",
      calibrationResetYesNo: "Reset calibration settings?",
      reset: "Reset",
      scanning: "Scanning...",
      availableDevices: "Available devices",
      noDeviceFound: "No device found",
      performance: "Performance",
      sound: "Voice control",
      bleReconnect: "Reconnect via bluetooth",
      wifiDisconnected: "Wifi connection lost",
      wifiConnected: "Wifi connection established",
      bleConnected: "bluetooth connection established",
      languageSelected: "English language selected",
      setVoice: "New voice installed",
      setVolume: "Volume changed",
      micOn: "Microphone on",
      micOff: "Voice control disabled",
      setVoiceMode: "Voice control enabled",
      switchToWifi: "To check for updates, go to Wifi mode",
      finished: "Finished. Reboot your device and app"
    },
    ru: {
      on: "Вкл",
      off: "Выкл",
      themes: 'Темы',
      language: 'Язык',
      pedal: 'Педаль',
      voice: 'Голос',
      control: 'Управление',
      wifi: 'Настройки Wifi',
      about: 'О программе',
      beginner: 'Новичок',
      professional: 'Профессионал',
      white: 'Светлая',
      black: 'Темная',
      rotor: 'Ротор',
      errorMessage: 'Ожидание подключения',
      errorMessageBLE: 'Ожидание Bluethooth соединения',
      fullscreen: 'Для продолжения нажмите на экран... ',
      fullscreen_ble: 'Подключите ваше устройство',
      lockscreen: 'Пожалуйста, поверните устройство',
      noItems: '',
      fallOut: 'Old School',
      pedalMode1: '1',
      pedalMode2: '2',
      newVersion: 'Доступно новое обновление',
      installToDevice: 'Обновить',
      installErrors: "Ошибка установки",
      installationToDevice: "Установка на устройство",
      lastVersion: "У Вас последняя версия ПО",
      installingFirmware: "Установка прошивки",
      finishing: "Завершение",
      flashError: "Ошибка прошивки",
      guru: "Гуру",
      deviceReboot: "Перезагрузка уcтройства",
      add: "Добавить",
      cancel: "Отмена",
      apply: "Применить",
      selectColor: "Выберите цвет",
      calibrationSettings: "Калибровка устройства",
      calibration: "Калибровка",
      calibrationOn: "Вкл.",
      calibrationSend: "Отправить настройки на устройство?",
      yes: "Да",
      no: "Нет",
      calibrationResetYesNo: "Сбросить калибровочные настройки?",
      reset: "Сброс",
      scanning: "Поиск устройства...",
      availableDevices: "Найденные устройства",
      noDeviceFound: "Устройства не найдены",
      performance: "Производительность",
      sound: "Голосовое управление",
      bleReconnect: "Переподключение через bluetooth",
      wifiDisconnected: "Wifi соединение разорвано",
      wifiConnected: "Wifi соединение установлено",
      bleConnected: "bluetooth соединение установлено",
      languageSelected: "Выбран русский язык",
      setVoice: "Установлен новый голос",
      setVolume: "Изменена громкость",
      micOn: "Включен микрофон",
      micOff: "Голосовое управление отключено",
      setVoiceMode: "Голосовое управление включено",
      switchToWifi: "Для проверки обновлений перейдите в режим Wifi",
      finished: "Завершена. Перезагрузите устройство и приложение"
    },
    es: {
      on: "en",
      off: "Apagada",
      themes: 'Temas',
      language: 'Idioma',
      pedal: 'Pedal',
      voice: 'voz',
      control: 'Control',
      wifi: 'Configuración de Wi-Fi',
      about: 'Sobre el programa',
      beginner: 'Novato',
      professional: 'Profesional',
      white: 'Luz',
      black: 'Oscuro',
      rotor: 'Rotor',
      errorMessage: 'Esperando la conexión',
      errorMessageBLE: 'Esperando conexión Bluetooth',
      fullscreen: 'Para continuar, haga clic en la pantalla...',
      fullscreen_ble: 'Conecta tu dispositivo',
      lockscreen: 'Por favor gire su dispositivo',
      noItems: '',
      fallOut: 'Old School',
      pedalMode1: '1',
      pedalMode2: '2',
      newVersion: 'Nueva actualización disponible',
      installToDevice: 'Actualizar',
      installErrors: "Error de instalación",
      installationToDevice: "Instalación en el dispositivo",
      lastVersion: "Tienes la última versión del software.",
      installingFirmware: "Instalación de firmware",
      finishing: "Terminación",
      flashError: "error de firmware",
      guru: "Gurú",
      deviceReboot: "Reiniciar el dispositivo",
      add: "Agregar",
      cancel: "Cancelar",
      apply: "Aplicar",
      selectColor: "Seleccionar el color",
      calibrationSettings: "Calibración del dispositivo",
      calibration: "Calibración",
      calibrationOn: "En",
      calibrationSend: "¿Enviar configuración al dispositivo?",
      yes: "Sí",
      no: "No",
      calibrationResetYesNo: "¿Restablecer la configuración de calibración?",
      reset: "Reiniciar",
      scanning: "Buscando dispositivo...",
      availableDevices: "Dispositivos encontrados",
      noDeviceFound: "No se encontraron dispositivos",
      performance: "Actuación",
      sound: "Control de voz",
      bleReconnect: "Reconectar vía bluetooth",
      wifiDisconnected: "Se perdió la conexión wifi",
      wifiConnected: "Conexión wifi establecida",
      bleConnected: "conexión bluetooth establecida",
      languageSelected: "idioma español seleccionado",
      setVoice: "Nueva voz instalada",
      setVolume: "Volumen cambiado",
      micOn: "Micrófono encendido",
      micOff: "Control por voz desactivado",
      setVoiceMode: "Control por voz habilitado",
    },
    jp: {
      on: "On",
      off: "Off",
      themes: 'テーマ',
      language: '言語',
      pedal: 'ペダル',
      voice: 'ボイス',
      control: 'コントロール',
      wifi: 'Wifi設定',
      about: 'プログラムについて',
      professional: 'プロ',
      beginner: '初心者',
      white: '光',
      black: '暗い',
      rotor: 'ローター',
      errorMessage: '接続を待っています',
      errorMessageBLE: 'Bluetooth 接続を待機しています',
      fullscreen: '画面をタップして続行します...',
      fullscreen_ble: 'Please connect your device.',
      lockscreen: 'デバイスを回転させてください',
      noItems: '',
      fallOut: 'Old School',
      pedalMode1: '1',
      pedalMode2: '2',
      newVersion: '新しいアップデートが利用可能',
      installToDevice: '最新バージョンをインストールする',
      installErrors: "インストールエラー",
      installationToDevice: "デバイスへのインストール",
      lastVersion: "あなたは最新のソフトウェアバージョンを持っています",
      installingFirmware: "ファームウェアのインストール",
      finishing: "完了",
      flashError: "ファームウェアエラー",
      guru: "達人",
      deviceReboot: "デバイスを再起動します",
      add: "追加",
      cancel: "キャンセル",
      apply: "申し込み",
      selectColor: "色を選ぶ",
      calibrationSettings: "Device Calibration",
      calibration: "Calibration",
      calibrationOn: "On",
      calibrationSend: "Send data to device?",
      yes: "はい",
      reset: "リセット",
      scanning: "走査...",
      availableDevices: "Available devices",
      noDeviceFound: "No device found",
      performance: "パフォーマンス",
      sound: "音声制御",
      bleReconnect: "Bluetooth経由で再接続する",      
      wifiDisconnected: "Wi-Fi接続が失われました",
      wifiConnected: "Wi-Fi接続が確立されました",
      bleConnected: "Bluetooth接続が確立されました",
      languageSelected: "日本語が選択されました",
      setVoice: "新ボイス搭載",
      setVolume: "音量が変更されました",
      micOn: "マイクオン",
      micOff: "マイクがミュートされました",
      setVoiceMode: "音声制御が有効になりました",
    }
  },

})