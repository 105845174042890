<template>
  <div class="rotor-main">
    <div class="roller-container">
      <div class="rotor-bg">
        <div 
          ref="rotor1-bg"
          class="rotor1-bg"
          :style="
            activeRotor == 1
              ? 'visibility: initial;'
              : 'visibility: hidden'
          "
        />
        <div
          class="rotor2-bg"
          ref="rotor2-bg"
          :style="
            activeRotor == 2
              ? 'visibility: initial;'
              : 'visibility: hidden'
          "
        />
        <div 
        ref="rotor3-bg"
          class="rotor3-bg"
          :style="
            activeRotor == 3
              ? 'visibility: initial;'
              : 'visibility: hidden'
          "
        />
      </div>
      <rollerPickerBox :key="rotorKey" class="rotor1-box" :class="activeRotor == 1 && 'active'">
        <rollerPicker ref="rotor1" class="rotor1" @touchstart="activeRotor = 1"  :data="values" :index="r1Index" @change="roller1OnChange" :layer="false" :options="options"></rollerPicker>
      </rollerPickerBox>
      <rollerPickerBox :key="rotorKey" class="rotor2-box" :class="activeRotor == 2 && 'active'">
        <rollerPicker ref="rotor2" class="rotor2" @touchstart="activeRotor = 2" :data="values" :index="r2Index" @change="roller2OnChange" :layer="false" :options="options"></rollerPicker>
      </rollerPickerBox>
      <rollerPickerBox :key="rotorKey" class="rotor3-box" :class="activeRotor == 3 && 'active'">
        <rollerPicker :key="rotorKey" ref="rotor3" class="rotor3" @touchstart="activeRotor = 3" :data="values" :index="r3Index" @change="roller3OnChange" :layer="false" :options="options"></rollerPicker>
      </rollerPickerBox>
    </div>
    <div class="fanContainer">
      <div
        ref="fan1"
        class="fan1Container"
        v-on:click="rotorStart(1)"
        :class="getFanClass(1)"
      >
        <fan color="#91F2F2" />
      </div>
      <div
        ref="fan2"
        class="fan2Container"
        v-on:click="rotorStart(2)"
        :class="getFanClass(2)"
      >
        <fan color="#F1EFBA" />
      </div>
      <div
        ref="fan2"
        class="fan3Container"
        v-on:click="rotorStart(3)"
        :class="getFanClass(3)"
      >
        <fan color="#F1B2AF" />
      </div>
    </div>
    <transition name="fade">
      <img
        v-if="direction"
        class="toogle-direction"
        v-on:click="toogleDirection(false)"
        src="/i/r/rotate_right_btn.png"
      />
      <img
        v-else
        class="toogle-direction"
        v-on:click="toogleDirection(true)"
        src="/i/r/rotate_left_btn.png"
      />
    </transition>
    <div class="timer"><timer :active="$store.state.pedalOn" /></div>
    <div class="clock"><clock /></div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { RotorMixin } from "./RotorMixin";


export default Vue.extend({
  name: "RotorBlack",
  mixins: [RotorMixin],
});
</script>

<style lang="scss">
$rotor-height: 470px;

.theme-rotor-black {


  .rotor-main {
    max-width: 430px;
  }


  .roller-container {
    @media (orientation: landscape) {
      width: 40vw!important;
    }
    @media (orientation: landscape) and (min-height: 700px){
      padding-top: 110px;
    }
  }
  

  .fanContainer .active {
    margin: 2px;
  }

  .roller-picker-item {
    font-size: 35px;
    padding-right: 3px;
  }

  .rotor1-box {
    .roller-picker-box-select {
      border-top: 2px solid #91F2F2;
      border-bottom: 2px solid #91F2F2;
    }
  }
  .rotor2-box {
    .roller-picker-box-select {
      border-top: 2px solid #F1EFBA;
      border-bottom: 2px solid #F1EFBA;
    }
  }

  .rotor3-box {
    .roller-picker-box-select {
      border-top: 2px solid #F1B2AF;
      border-bottom: 2px solid #F1B2AF;
    }
  }

  .roller-picker-box {
    margin-top: 0vh;
    @media (orientation: portrait) and (min-height: 670px){
      margin-top: 10vh;
    }
  }

  .presets-icon {
    display: none;
  }
  .gear-bg {
    background-image: none;
  }
  &.v-application.portrait {
    background-image: url(/i/r/bg-dark.svg);
    background-size: cover;
  }
  &.v-application.landscape {
    background-image: url(/i/r/bg-h-dark.svg);
    background-size: cover;
  }

  .toogle-direction {
    height: 11vw;
    top: 72vh;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    @media (orientation: landscape) {
      height: 12vh;
      top: 36%;
      left: 13%;
    }
  }
  .roller-container {
    width: 70vw;
    max-width: 400px;
    justify-content: center;
    display: flex;

    @media (orientation: landscape) {
      top: 0px;
      position: fixed;
      left: 50%;
      transform: translate(-50%, 0%);
    }
    
    .rotor1 .roller-picker-item {
      color: #91f2f2 !important;
    }
    .rotor2 .roller-picker-item {
      color: #f1efba !important;
    }
    .rotor3 .roller-picker-item {
      color: #f1b2af !important;
    }
    .rotor-bg {
      position: fixed;
      top: 0px;
      justify-content: space-between;
      width: 70vw;
      display: flex;
      height: 250px;
      max-width: 400px;
      margin-left: 2px;
      .rotor1-bg,
      .rotor2-bg,
      .rotor3-bg {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
        @media  (orientation: landscape) and (min-height: 760px){ 
          height: 400px !important;
        }
        @media  (orientation: landscape) and (max-height: 800px){ 
          height: 260px;
        }
      }
      .rotor1-bg {
        background-image: url(/i/r/picker1-bg.svg);
      }
      .rotor2-bg {
        background-image: url(/i/r/picker2-bg.svg);
      }
      .rotor3-bg {
        background-image: url(/i/r/picker3-bg.svg);
      }
      @media (orientation: landscape) {
        
        width: 40vw;
        .rotor1-bg {
          background-image: url(/i/r/picker1-bg-l.svg);
        }
        .rotor2-bg {
          background-image: url(/i/r/picker2-bg-l.svg);
        }
        .rotor3-bg {
          background-image: url(/i/r/picker3-bg-l.svg);
        }
      }
      @media (orientation: portrait) and (min-height: 1000px){
        height: 55vh;
      }
    }
  }
  .fanContainer {
    width: 100%;
    display: flex;
    margin-top: 6vh;
    text-align: center;
    justify-content: space-around;
    div {
      width: 100%;
      display: flex;
    }
    .fan1Container.active {
      background: linear-gradient(
        180deg,
        rgba(99, 99, 99, 0) -47.14%,
        rgba(145, 242, 242, 0.5) 16.71%,
        rgba(96, 238, 238, 0.5) 84.53%,
        rgba(162, 255, 255, 0) 130%
      );
    }
    .fan2Container.active {
      background: linear-gradient(
        180deg,
        rgba(255, 243, 181, 0) -35.71%,
        rgba(228, 216, 100, 0.5) 11.93%,
        rgba(242, 218, 88, 0.5) 86.48%,
        rgba(255, 210, 128, 0) 131.43%
      );
    }
    .fan3Container.active {
      background: linear-gradient(
        360deg,
        rgba(255, 52, 114, 0) -44.29%,
        rgba(255, 121, 162, 0.5) 22.67%,
        rgba(255, 133, 176, 0.5) 86.02%,
        rgba(255, 47, 120, 0) 131.43%
      );
    }
    .active {
      filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.4));
      background-blend-mode: lighten;
      border-radius: 50%;
      svg {
        -webkit-animation: spin 1s linear infinite;
        -moz-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
      }
      &.spin-back {
        svg {
          -webkit-animation: spin-back 1s linear infinite;
          -moz-animation: spin-back 1s linear infinite;
          animation: spin-back 1s linear infinite;
        }
      }
      // width: 12vh;
      // height: 12vh;
    }
    @media (orientation: landscape) {
      width: 40vw;
      justify-content: space-between;
      margin: auto;
      bottom: 4vh;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
    }
    svg {
      height: calc(4vh + 5vw);
      width: calc(4vh + 5vw);
      margin: auto;
    }
  }
  .timer,
  .clock {
    left: 0px;
    bottom: 10vh;
    color: #fff;
    width: 35vw;
    position: absolute;
    font-size: 6vw;
    text-align: center;
    font-family: Jura-Bold;
    border-bottom: 1px solid;
    @media (orientation: landscape) {
      font-size: 4vw;
      width: 20vw;
    }
  }
  .clock {
    right: 0px;
    left: auto;
  }
  @media screen and (orientation: portrait) and (min-aspect-ratio: 1024 / 1366)
   {
     .roller-container {
        .rotor-bg {
          width: 60vw;
          height: 90vh;
        }
        .roller-picker {
          width: 60vw;
          height: 31vh;
        }
     }
      .fanContainer {
        width: 60vw;
        margin: 0 auto;
        max-width: 400px;
      }
      .toogle-direction {
        bottom: 10vh;
        top: auto;
      }
   }
}
.cordova-app {
    body {
      width: var(--app-width)!important;
      height: 100vh;
      .v-application {
        height: 100vh;
      }
    }
  }
</style>