<template>
  <svg
    width="29"
    height="7"
    viewBox="0 0 29 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i11)">
      <path
        d="M6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3Z"
        :fill="color"
      />
      <path
        d="M12.508 3.05963C12.508 4.71649 11.1649 6.05963 9.508 6.05963C7.85114 6.05963 6.508 4.71649 6.508 3.05963C6.508 1.40278 7.85114 0.0596313 9.508 0.0596313C11.1649 0.0596313 12.508 1.40278 12.508 3.05963Z"
        :fill="color"
      />
      <path
        d="M20.0188 3.05963C20.0188 4.71649 18.6757 6.05963 17.0188 6.05963C15.3619 6.05963 14.0188 4.71649 14.0188 3.05963C14.0188 1.40278 15.3619 0.0596313 17.0188 0.0596313C18.6757 0.0596313 20.0188 1.40278 20.0188 3.05963Z"
        :fill="color"
      />
      <path
        d="M29 3C29 4.65685 27.6569 6 26 6C24.3431 6 23 4.65685 23 3C23 1.34315 24.3431 0 26 0C27.6569 0 29 1.34315 29 3Z"
        :fill="color"
      />
    </g>
    <defs>
      <filter
        id="filter0_i11"
        x="-1"
        y="0"
        width="30"
        height="8.05963"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
    </defs>
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "rocket",
  mixins: [IconMixin],
});
</script>
