import Vue from 'vue'
import Vuex from 'vuex'
import fetchData from '@/fetchData'
import i18n from '@/i18n'
import { isWebSite } from "@/helpers";

Vue.use(Vuex)

declare let window: any;

export interface Preset {
  iconName: string;
  freq: number;
  duty: number;
  level: number;
  duty1: number;
  duty2: number;
  s1: number;
  s2: number;
  s3: number;
  s4: number;
  key: string;
  selected: boolean;
  delay: number;
  points: number;

}

function getPresetKey(preset: Preset): string {
  return preset.freq + '_' + preset.duty + '_' + preset.level + '_' + preset.duty1 + '_' + preset.duty2 + '_' +
    preset.s1 + '_' + preset.s2 + '_' + preset.s3 + '_' + preset.s4
}

function updatePresetRequest(preset) {
          let _preset = {...preset}
          delete _preset["selected"]
          _preset = JSON.stringify(_preset)
          _preset = _preset.replace("iconName", "i")
          _preset = _preset.replace("freq", "f")
          _preset = _preset.replace("duty", "d")
          _preset = _preset.replace("level", "l")
          _preset = _preset.replace("duty1", "d1")
          _preset = _preset.replace("duty2", "d2")
          _preset = _preset.replace("points", "p")
          _preset = _preset.replace("delay", "dl")
          _preset = JSON.parse(_preset)
          _preset["key"] = preset.key
          _preset["s1"] = preset.s1
          _preset["s2"] = preset.s2
          _preset["s3"] = preset.s3
          _preset["s4"] = preset.s4
          return _preset
}

const DEBUG = false;

export default new Vuex.Store({
  state: {
    voice: "",
    voiceChanged: false,
    volume: 90,
    wifiOn: true,
    micOn: false,
    withCredentials: true,
    useBluetooth: true,
    bleDeviceID: null,
    bleInitialized: false,
    DEBUG: DEBUG,
    cpuFrequency: 240,
    firmwareVersion: "*",
    srInitialization: false,
    srInitialized: false,
    srStarted: false,
    hasRecordAudioPermission: false,
    lockInterface: true,
    serviceMode: window.localStorage.getItem('100') == "1" ? true : false,
    calibrationToogle: false,
    errorSnackbar: false,
    errorSnackbarBLE: false,
    requestFullScreen: true,
    lockScreenOrientation: null,
    lockScreenOrientationNotSupported: true,
    locale: window.localStorage.getItem('spica-locale') || 'ru',
    pedalOn: false,
    pedalMode: 0,
    voiceMode1: 0,
    sin1PresetS1: 0,
    sin1PresetS2: 0,
    sin1PresetS3: 0,
    sin1PresetS4: 0,
    sin2PresetS1: 0,
    sin2PresetS2: 0,
    sin2PresetS3: 0,
    sin2PresetS4: 0,
    sin3PresetS1: 0,
    sin3PresetS2: 0,
    sin3PresetS3: 0,
    sin3PresetS4: 0,
    sin4PresetS1: 0,
    sin4PresetS2: 0,
    sin4PresetS3: 0,
    sin4PresetS4: 0,
    levelFromVoice: NaN,
    freqFromVoice: NaN,
    freqNotification: NaN,
    levelNotification: NaN,
    duty2Notification: NaN,
    themes: {
      current: window.localStorage.getItem('spica-theme') || "beginner",
      shade: window.localStorage.getItem('spica-theme-shade') || "black",
      beginner: {
        white: {
          color: window.localStorage.getItem('spica-beginner-white-color') || "#8bf3ea",
          appStyle: {
            background:
              "linear-gradient(236.16deg, #FFFFFF -24.98%, rgba(189, 189, 189, 0) 104%), #DADADA",
            "background-size": "cover",
          },
        },
        black: {
          color: window.localStorage.getItem('spica-beginner-black-color') || "#f08eb7",
          appStyle: {
            background:
              "linear-gradient(220.86deg, #727272 -5.86%, rgba(112, 111, 111, 0) 89.41%), #272727",
            "background-size": "cover",
          },
        },
        presets: []
      },
      professional: {
        white: {
          color: window.localStorage.getItem('spica-professional-white-color') || "#8bf3ea",
          appStyle: {
            background:
              "linear-gradient(236.16deg, #FFFFFF -24.98%, rgba(189, 189, 189, 0) 104%), #DADADA",
            "background-size": "cover",
          },
        },
        black: {
          color: window.localStorage.getItem('spica-professional-black-color') || "#f08eb7",
          appStyle: {
            background:
              "linear-gradient(220.86deg, #727272 -5.86%, rgba(112, 111, 111, 0) 89.41%), #272727",
            "background-size": "cover",
          },
        },
        presets: []
      },
      rotor: {
        current: window.localStorage.getItem('spica-theme-shade') || "white",
        white: {
          color: "#1F1F1F",
          appStyle: {
          },
        },
        black: {
          color: "white",
          appStyle: {
          },
        },
        presets: []
      },
      fallOut: {
        current: window.localStorage.getItem('spica-theme-shade') || "white",
        white: {
          color: "#ffffff",
          appStyle: {
          },
        },
        black: {
          color: "#FBDD73",
          appStyle: {
          },
        },
        presets: []
      },
      guru: {
        current: window.localStorage.getItem('spica-theme-shade') || "#4634eb",
        white: {
          color: window.localStorage.getItem('spica-guru-white-color') || "#7b6dcf",
          appStyle: {
          },
        },
        black: {
          color: window.localStorage.getItem('spica-guru-black-color') || "#fce595",
          appStyle: {
          },
        },
        presets: []
      }
    }
  },
  getters: {
    currentTheme: state => {
      return (state.themes as any)[state.themes.current]
    },
    currentThemeShade: (state, getters) => {
      return getters.currentTheme[state.themes.shade]
    },
    settingsItems: state => {
      return [
        { title: "themes", component: "ThemesSettings" },
        { title: "language", component: "LanguageSettings" },
        // { title: "control", component: "PedalSettings" },
        // { title: "performance", component: "PerformanceSettings" },
        // { title: "wifi", component: "WifiSettings" },
        { title: "sound", component: "SoundSettings" },
        // { title: "hand_gesture", component: "HandPositionGesture" },
        // ... state.serviceMode ? [{ title: "calibration", component: "CalibrationSettings" }] : [],
        { title: "about", component: "AboutSettings" },
      ]
    },
  },
  mutations: {
    setRecordAudioPermission(state, value) {
      state.hasRecordAudioPermission = value;
    },
    setSRInitialized(state, value) {
      state.srInitialized = value;
    },
    setBleInitialized(state, value) {
      state.bleInitialized = value;
    },
    setSRInitialization(state, value) {
      state.srInitialization = value;
    },
    setSRStarted(state, value) {
      state.srStarted = value;
    },
    saveSin(state, { btnNum, sinNum, value }) {
      (state as any)['sin' + btnNum + 'PresetS' + sinNum] = parseInt(value);
    },
    lockScreenOrientation(state, value) {
      state.lockScreenOrientation = value
    },
    lockScreenOrientationNotSupported(state, value) {
      state.lockScreenOrientationNotSupported = value
    },
    errorSnackbar(state, value) {
      state.errorSnackbar = value
    },
    errorSnackbarBLE(state, value) {
      state.errorSnackbarBLE = value
    },
    bleDeviceID(state, value) {
      state.bleDeviceID = value
    },
    useBluetooth(state, value) {
      state.useBluetooth = value
    },
    withCredentials(state, value) {
      state.withCredentials = value
    },
    addPreset(state, { theme, preset }) {
      preset.key = (getPresetKey(preset) as any);
      (state.themes as any)[theme].presets = (state.themes as any)[theme].presets.filter((_preset: Preset) => _preset.key != preset.key);
      (state.themes as any)[theme].presets.forEach((value: any, index: number) => {
        (state.themes as any)[theme].presets[index].selected = false
      });
      (state.themes as any)[theme].presets.unshift(preset)
    },
    selectPreset(state, { theme, key, trashIsOpen }) {
      (state.themes as any)[theme].presets.forEach((value: any, index: any) => {
        if (key == value.key) {
          (state.themes as any)[theme].presets[index].selected = !(state.themes as any)[theme].presets[index].selected;
        } else {
          if (!trashIsOpen) {
            (state.themes as any)[theme].presets[index].selected = false
          }
        }
      });
    },
    updateLocale(state, newLocale) {
      state.locale = newLocale
    },
    updatePedal(state, { on, mode }) {
      state.pedalOn = !!on;
      state.pedalMode = mode;
    },
    setFirmwareVersion(state, version) {
      state.firmwareVersion = version
    },
    removeSelectedPresets(state, theme) {
      console.log("!!!!!!!!!!!!!!!!1", (state.themes as any)[theme].presets);
      let presets = [];
      for (const preset of (state.themes as any)[theme].presets) {
        if ((preset.hasOwnProperty("selected") && preset.selected == false) || !preset.hasOwnProperty("selected")) {
          presets.push(preset)
        }
      }
      // (state.themes as any)[theme].presets = [...(state.themes as any)[theme].presets.filter((preset: any) => 
      //   {(preset.hasOwnProperty("selected") && preset.selected == false) || !preset.hasOwnProperty("selected")})]
      (state.themes as any)[theme].presets = presets
      console.log("!!!!!!!!!!!!!!!!3", (state.themes as any)[theme].presets)


    },
    updateTheme(state, theme) {
      state.themes.current = theme;
    },
    updateThemeShade(state, shade) {
      state.themes.shade = shade;
    },
    updateCalibrationToogle(state, value) {
      state.calibrationToogle = value;
    },
    updateThemeColor(state, [theme, shade, color]) {
      (state.themes as any)[theme][shade].color = color
    },
    switchPedal(state, { on, mode, wifiOn }) {
      state.lockInterface = false;
      state.pedalOn = !!+on;
      state.pedalMode = mode;
      state.wifiOn = wifiOn;
    },
    toogleWifi(state, { on }) {
      state.wifiOn = on;
    },
    toogleMic(state, { on }) {
      state.micOn = on;
    },
    setVoice(state, { value }) {
      state.voice = value;
      state.voiceChanged = true
    },
    setVolume(state, { volume }) {
      state.volume = volume;
    },
    setCPUFreq(state, { value }) {
      state.cpuFrequency = value;
    },
    freqNotification(state, value) {
      state.freqNotification = value
    },
    levelNotification(state, value) {
      state.levelNotification = value
    },
    duty2Notification(state, value) {
      state.duty2Notification = value
    },
    freqFromVoice(state, value) {
      state.freqFromVoice = value;
    },
    levelFromVoice(state, value) {
      state.levelFromVoice = value;
    },
    unlockInterface(state) {
      state.lockInterface = false;
    },

    enableServiceMode(state) {
      state.serviceMode = true;
    },
    toogleVoiceMode(state, { value }) {
      state.voiceMode1 = value;
      window.localStorage.setItem('spica-voice-mode-1', state.voiceMode1);
    },
    setPresets(state, payload) {
      (state.themes as any)[payload.theme].presets = payload.data;
    },
    updateRequestFullScreen(state, value) {
      state.requestFullScreen = value;
    }
  },
  actions: {
    changeTheme({ commit }, theme) {
      commit('updateTheme', theme)
      window.localStorage.setItem('spica-theme', theme)
    },
    requestFullScreen({ commit }, value) {
      commit('updateRequestFullScreen', value)
    },
    changeThemeShade({ commit }, shade) {
      commit('updateThemeShade', shade)
      window.localStorage.setItem('spica-theme-shade', shade)
    },
    changeThemeColor({ commit }, [theme, shade, color]) {
      commit('updateThemeColor', [theme, shade, color])
      window.localStorage.setItem('spica-' + theme + '-' + shade + '-color', color)
    },
    changeLocale({ commit }, newLocale: string) {
      i18n.locale = newLocale
      commit('updateLocale', newLocale)
      window.localStorage.setItem('spica-locale', newLocale)
    },

    addPreset({ commit, state }, { theme, preset }) {

      if (state.useBluetooth) {
        preset.key = (getPresetKey(preset) as any);
        const presetExists = ((state.themes as any)[theme].presets.filter((_preset: Preset) => _preset.key == preset.key)).length > 0;
        if (!presetExists) {
          fetchData.post("/SavePresets/" + theme + ".json", updatePresetRequest(preset))
            .then(response => {
              if (response.data.status != 'success') {
                alert('error while saving presets')
                console.log(response.data);
              }
            })
        }
      } else {
        fetchData.post("/SavePresets/" + theme + ".json", (state.themes as any)[theme].presets)
          .then(response => {
            if (response.data.status != 'success') {
              alert('error while saving presets')
              console.log(response.data);
            }
          });
      }
      commit('addPreset', { theme, preset })
    },
    loadState({ commit }, payload) {
      let loadPresets = true;
      // debugger
      if (payload.loadPresets !== undefined && payload.loadPresets === false) {
        loadPresets = false
      }
      return new Promise((resolve, reject) => {
        fetchData.post("/api", {
          n: payload.num,
          a: 1
        })
          .then(response => {
            commit('unlockInterface');
            commit('updatePedal', { on: response.data.pedal.on, mode: response.data.pedal.pm })
            resolve(response.data);
            commit('setFirmwareVersion', response.data.v)
            if (!loadPresets) return;
            fetchData.post("/LoadPresets/" + payload.theme + ".json")
              .then(response => {
                if (response && response.data && response.data.length > 0 && response.data[0].iconName != undefined) {
                  commit('setPresets', { theme: payload.theme, data: response.data });
                }
              })
          })
          .catch(reason => {
            console.log(reason);
            reject(reason);
          });
      });
    },
    rotorChangeValue({ commit }, payload) {
      fetchData.post("/api", {
        n: payload.number,
        dr: payload.direction,
        l: payload.level
      })
        .then(data => (commit('rotorUpdateValue', data.data)));
    },
    selectPreset({ commit, state }, preset) {
      commit('selectPreset', preset)
      // const p: any = state.themes.professional.presets.filter(_preset => _preset.key == preset.key)
      // fetch(ESP_URL + "/SavePreset/api_preset.json", {
      //   method: "POST", body: JSON.stringify(
      //     state.themes.professional.presets
      //   )
      // })
    },
    async removeSelectedPresets({ commit, state }, theme) {
      if (state.useBluetooth) {
        let presets = state.themes[theme].presets.filter((preset) => preset.selected == true)
        for (const preset of presets) {
          await fetchData.post("/SavePresets/" + theme + ".json?r", updatePresetRequest(preset))
        }
      } else {
        fetchData.post("/SavePresets/" + theme + ".json", (state.themes as any)[theme].presets)
          .then((response: any) => {
            if (response.data.status != 'success') {
              alert('error while saving presets')
            }
          });
      }
      commit('removeSelectedPresets', theme);
    },
    switchPedal({ commit, state }, value) {
      if (value == undefined) {
        fetchData.get("/PEDAL?on=" + +!state.pedalOn)
          .then(response => {
            commit('switchPedal', {
              on: Boolean(+String(response.data).charAt(0)),
              mode: +String(response.data).charAt(1),
              wifiOn: +String(response.data).charAt(2),
            })
          });
      } else {
        commit('switchPedal', {
          on: value
        })
      }
    },
    saveSin({ commit }, { btnNum, sinNum, value }) {
      window.localStorage.setItem('sin' + btnNum + 'PresetS' + sinNum, value)
      commit('saveSin', { btnNum, sinNum, value: value })
    },
    setWifiMode({ commit }, { wifiOn }) {
      commit('toogleWifi', {
        on: wifiOn
      });

      fetchData.get("/SET_WIFI?on=" + +wifiOn)
        .then(response => {
          console.log(response);
        })
        .catch(e => {
          console.log(e);
        })

    },
    setCPUFreq({ commit }, { value }) {
      commit('setCPUFreq', {
        value: value
      });

      fetchData.get("/SET_CPU_FREQ?v=" + value)
        .then(response => {
          console.log(response);
        })
        .catch(e => {
          console.log(e);
        })

    },
    setPedalMode({ commit, state }) {
      fetchData.get("/set_pedal_mode?mode=" + +!state.pedalMode)
        .then(response => {
          commit('switchPedal', {
            on: Boolean(+String(response.data).charAt(0)),
            mode: +String(response.data).charAt(1),
            wifiOn: +String(response.data).charAt(2)
          })
        });
    },
    freqFromVoice({ commit }, value) {
      commit('freqFromVoice', value)
    },
    updateCalibrationToogle({ commit }, value) {
      commit('updateCalibrationToogle', value)
    },
    levelFromVoice({ commit }, value) {
      commit('levelFromVoice', value)
    },
    setRecordAudioPermission({ commit }, value) {
      commit('setRecordAudioPermission', value)
    },
    setSRInitialization({ commit }, value) {
      commit('setSRInitialization', value)
    },
    setSRInitialized({ commit }, value) {
      commit('setSRInitialized', value)
    },
    setBleInitialized({ commit }, value) {
      commit('setBleInitialized', value)
    },
    setSRStarted({ commit }, value) {
      commit('setSRStarted', value)
    },
  },
  modules: {
  }
})
