<template>
  <div>
    <h3 v-if="orientation == 'landscape'" style="text-align: center;font-weight">
      {{ $t("wifi") }}
    </h3>
    <v-row justify="space-around" style="margin-top: 30px; width: 100%; margin-left: -35px;min-width: 275px;margin-bottom: 30px">
      <!-- <v-checkbox v-model="wifiOn"></v-checkbox> -->
      <!-- <v-btn-toggle :rounded="true">
        <v-btn :value="$store.state.wifiOn" @click="()=>{setWifiMode(true)}">{{ $t("on") }}</v-btn>
        <v-btn :value="!$store.state.wifiOn" @click="()=>{setWifiMode(false)}">{{ $t("off") }}</v-btn>
      </v-btn-toggle> -->
      <div class="v-input--selection-controls__input">
        <input type="radio" id="on" role="radio" :value="$store.state.wifiOn" :checked="$store.state.wifiOn"
          @change="(e) => (setWifiMode(true))" />
          <label for="on" style="margin-left: 30px;">{{ $t("on") }}</label>
      </div>
      <div class="v-input--selection-controls__input">
        <input type="radio" id="off" role="radio" :value="!$store.state.wifiOn" :checked="!$store.state.wifiOn"
          @change="(e) => (setWifiMode(false))" />
          <label for="off" style="margin-left: 30px;">{{ $t("off") }}</label>
      </div>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { DeviceOrientationMixin } from "../mixins";

export default Vue.extend({
  name: "WifiSettings",
  mixins: [DeviceOrientationMixin],
  data() {
    return {
      wifiOn: false
    }
  },
  methods: {
    setWifiMode: function (value) {
      this.$store.commit('toogleWifi', {
             on: value
            })
      this.$store.dispatch("setWifiMode", { wifiOn: value });
    },
  }
});
</script>

<style lang="scss">
.settings {
  .control-value {
    margin-top: 0px;
  }
}
</style>