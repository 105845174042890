<template>
  <div>
    <h3 v-if="orientation == 'landscape'" style="text-align: center">
      {{ $t("language") }}
    </h3>
    <div class="language">
      <div v-on:click="lang = 'en'">
        <img class="svg-icon" :class="lang === 'en' && 'active'" src="/i/uk_flag.svg"/>
      </div>
      <div v-on:click="lang = 'ru'">
        <img class="svg-icon" :class="lang === 'ru' && 'active'" src="/i/ru_flag.svg"/>
      </div>
    </div>
    <div class="language">
      <div v-on:click="lang = 'jp'">
        <img class="svg-icon" :class="lang === 'jp' && 'active'" src="/i/jp_flag.svg"/>
      </div>
      <div v-on:click="lang = 'es'">
        <img class="svg-icon" :class="lang === 'es' && 'active'" src="/i/es_flag.svg"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { DeviceOrientationMixin } from "@/components/mixins";
import store from "@/store";
// import RuFlag from "../icons/RuFlag.vue";
// import EnFlag from "../icons/EnFlag.vue";



export default Vue.extend({
  // components: { RuFlag, EnFlag },

  name: "LanguageSettings",
  computed: {
    lang: {
      get: function () {
        return this.$store.state.locale;
      },
      set: async function (newVal: string) {
        store.dispatch("changeLocale", newVal);
      },
    },
  },
  mixins: [DeviceOrientationMixin],
});
</script>

<style lang="scss" scoped>
.settings {
  .language {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .svg-icon {
      width: 70px;
      margin: 10px;
      height: 46px;
      cursor: pointer;
      border: 1px solid #e6e6e6;
      &.active {
        padding: 2px;
        border: 2px solid #2196f3;
      }
    }
  }
}
</style>