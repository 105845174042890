<template>
  <svg
    width="19"
    height="26"
    viewBox="0 0 19 26"
    fill="none"
  >
    <g filter="url(#filter0_di_u)">
      <rect x="3" y="3.33264" width="4" height="19" :fill="color" />
      <rect
        x="3"
        y="3.33264"
        width="4"
        height="19"
        fill="url(#paint0_linear_u)"
      />
    </g>
    <g filter="url(#filter1_di_u)">
      <rect x="12" y="3.33264" width="4" height="19" :fill="color" />
      <rect
        x="12"
        y="3.33264"
        width="4"
        height="19"
        fill="url(#paint1_linear_u)"
      />
      <rect
        x="12"
        y="3.33264"
        width="4"
        height="19"
        fill="url(#paint2_linear_u)"
      />
    </g>
    <defs>
      <filter
        id="filter0_di_u"
        x="0"
        y="0.332642"
        width="10"
        height="25"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.890196 0 0 0 0 0.537255 0 0 0 0 0.596078 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
      <filter
        id="filter1_di_u"
        x="9"
        y="0.332642"
        width="10"
        height="25"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.890196 0 0 0 0 0.537255 0 0 0 0 0.596078 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
      </filter>
      <linearGradient
        id="paint0_linear_u"
        x1="5"
        y1="3.33264"
        x2="5"
        y2="22.3326"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop offset="1" :stop-color="color" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_u"
        x1="14"
        y1="3.33264"
        x2="14"
        y2="22.3326"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EF7474" />
        <stop offset="1" :stop-color="color" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_u"
        x1="14"
        y1="3.33264"
        x2="14"
        y2="22.3326"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop offset="1" :stop-color="color" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "pause",
  mixins: [IconMixin],
});
</script>
