<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.797804 32.3876C0.696827 32.4319 0.510939 32.5792 0.384718 32.715L0.155226 32.9619L0.129798 37.4465C0.108685 41.1666 0.127962 42.0455 0.243259 42.6027C0.675531 44.6928 2.39002 46.4174 4.46968 46.8541C5.43685 47.0572 41.7818 47.0572 42.749 46.8541C44.8286 46.4174 46.5431 44.6928 46.9754 42.6027C47.0907 42.0454 47.11 41.1659 47.0889 37.4408L47.0634 32.9506L46.7633 32.6507C46.505 32.3924 46.3997 32.3506 46.0078 32.3506C45.6158 32.3506 45.5105 32.3924 45.2522 32.6506L44.9521 32.9506L44.9062 37.6076L44.8603 42.2646L44.6011 42.8255C44.2767 43.5275 43.6495 44.1516 42.9326 44.4861L42.3818 44.7431H23.6093H4.83687L4.28609 44.4861C3.56915 44.1516 2.942 43.5275 2.61758 42.8255L2.35835 42.2646L2.31245 37.6076L2.26655 32.9506L1.98263 32.6667C1.67951 32.3637 1.14094 32.2368 0.797804 32.3876Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.083 0.0750846C22.982 0.119422 22.7961 0.266848 22.6699 0.402616L22.4404 0.649549L22.4167 15.7176L22.3929 30.7856L18.2834 26.6799L14.1739 22.5742H13.7311C13.3499 22.5742 13.2449 22.6176 12.9762 22.8863C12.7074 23.1551 12.6641 23.2599 12.6641 23.6417V24.0851L17.8264 29.2504C23.2161 34.6433 23.206 34.6344 23.7864 34.4613C24.0828 34.3729 34.3151 24.1622 34.4741 23.7962C34.8317 22.9732 33.9288 22.0703 33.1061 22.4285C32.9432 22.4995 31.0031 24.3706 28.7285 26.6504L24.6436 30.745L24.5977 15.6916L24.5518 0.638258L24.2678 0.354331C23.9647 0.0512175 23.4262 -0.0756458 23.083 0.0750846Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "arrow",
  mixins: [IconMixin]
});
</script>
