import store from '@/store';
import axios from 'axios'
import { BleClient, textToDataView, dataViewToText } from 'bluetooth-le';
import { isBluefy, sleep } from '@/helpers';
import { Capacitor } from '@capacitor/core';

export const SERVICE_UUID = "55bf6fbf-e5a3-42f6-8bb0-e49857963b8d";
export const CHARACTERISTIC_UUID = "6962aa15-93ed-4626-b899-3f1bdfe97e58";

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

const BASE_URL = 'http://192.168.4.1';

const BLE_REQUEST_TIMEOUT = 5000;

let request_id = 0; 
let last_api_id = 0;
let last_api_value = null;
let last_request_uri = null;
let last_request_data = null;
let last_response = null;

function generateString(length) {
  // let result = '';
  // const charactersLength = characters.length;
  // for (let i = 0; i < length; i++) {
  //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
  // }
  request_id++;
  return request_id.toString();
  // return result;
}


let requests = {};


// if (location.protocol == "http:" && !store.state.DEBUG) {
//   store.state.useBluetooth = false;
// }


export const axiosFetch = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
})

const headers = {
  "content-type": "application/x-www-form-urlencoded",
};

const headers_withCredentials  = {
  "content-type": "application/x-www-form-urlencoded",
  'Access-Control-Allow-Credentials':true,
  // 'Cache-Control': 'no-cache',
  // 'Pragma': 'no-cache',
  // 'Expires': '0',
};

axiosFetch.interceptors.response.use((response) => {
  if (response == undefined || response.config == undefined) return;
  if (response.config.url == 'version.json') return response;
  if (response.config.url == '/version.json') return response;
  store.commit("errorSnackbar", false)
  return response
}, (error) => {
  
  if (error.code == "ERR_NETWORK") {
    store.state.withCredentials = false
    console.log("-----------ERR_NETWORK", error.message)
  }
  if (error == undefined || error.config == undefined) return;
  if (error.config.url.endsWith('/flash_firmware')) return;
  if (error.config.url.endsWith('/esp_reboot')) return;
  if (error.config.url.endsWith('/TATTOO')) return;

  if (error.config.url.indexOf('LoadPresets') >= 0) return;
  if (error.code == "ECONNABORTED" || !error.status) {
    // console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++====", error.config.url, "====", store.state.useBluetooth)
    if (error.config.url.endsWith('/PEDAL_STATUS')) {
      store.commit("errorSnackbar", true)
    }
  
  }
  throw error;
});

const b = new class {
  async post(uri, data = null, key = null, bleWaitingCount = 0) {
    if (!store.state.bleDeviceID) {
      store.commit("errorSnackbar", true)
      // store.commit("bleDeviceID", device.deviceId);
      throw new Error('Ble connection error', uri, data);
    } else {
      if (!store.state.bleInitialized && bleWaitingCount < 25) {
        console.log("Waiting BLE Device. Attemp:", bleWaitingCount, uri)
        await sleep(200);
        bleWaitingCount++
        await this.post(uri=uri, data=data, key=key, bleWaitingCount=bleWaitingCount)
        return
      } 
      if (data == null) data = "";
      const _data = data;
      data = JSON.stringify(data);
      if (uri == last_request_uri && data == last_request_data && last_response!=null) {
         console.log("CACHED RESPONSE: ", last_response);
         return {"data": last_response};
      }
      last_response = null;
      if (!key)
        key = generateString(5);
      
      let _requests = [];
      for (let i = 0; i<=data.length; i+=200) {
        if (i > 0 && !uri.endsWith("?a")) {
          uri+="?a";
        }
        _requests.push(JSON.stringify({ k: key, u: uri, d: data.slice(i, i+200) }));
      }
      let resp = null;
      for (const i in _requests) {
        let request = _requests[i];
        console.log("REQUEST: ", store.state.bleDeviceID, request);
        try {
        if (Capacitor.getPlatform() == "android") {
           await BleClient.writeWithoutResponse(store.state.bleDeviceID, SERVICE_UUID, CHARACTERISTIC_UUID, textToDataView(request));
        } else {
            await BleClient.write(store.state.bleDeviceID, SERVICE_UUID, CHARACTERISTIC_UUID, textToDataView(request));
        }
       } catch(e) {
          store.commit("errorSnackbarBLE", true)
       }
        if (uri == "/api" && !Object.prototype.hasOwnProperty.call(_data, "a")) {
          return { "data": null };
        }
        for (let i = 0; i < BLE_REQUEST_TIMEOUT; i++) {
          if (Object.prototype.hasOwnProperty.call(requests, key)) {
            resp = requests[key];
            if (!key.endsWith(":")) {
              delete requests[key];
              // if (Object.prototype.hasOwnProperty.call(requests, key + ":")) {
              //   setTimeout(() => {
              //     delete requests[key + ":"];
              //   }, 100);
              // }
            }
            break;
          }
          await sleep(1);
          if (i == BLE_REQUEST_TIMEOUT - 1) {
            throw new Error("BLE Request Timeout. ID: " + key + " ,URL: " + uri);
          }
        }
      }
      if (uri == "/api") {
        if (parseInt(key) < last_api_id) {
          resp = last_api_value;
        } else {
          last_api_id = key;
          last_api_value = resp;
        }
      }
      console.log("RESPONSE: ", resp);
      last_request_uri = uri;
      last_request_data = data;
      last_response = resp;
      return { "data": resp };
    }
  }
  async get(uri, data = null) {
    return this.post(uri, data)
  }
};

const r = new class {
  async post(uri, data = null) {
    if (!store.state.useBluetooth) {
      if (store.state.withCredentials) {
        return axiosFetch.post(uri, JSON.stringify(data), {headers: headers_withCredentials})
      } 
      return axiosFetch.post(uri, JSON.stringify(data), {headers: headers})
    } else {
      return b.post(uri, data)
    }
  }
  async get(uri, data = null) {
    if (!store.state.useBluetooth) {
      if (store.state.withCredentials) {
        return axiosFetch.get(uri, data, {headers: headers_withCredentials})
      }
      return axiosFetch.get(uri, data, {headers: headers})
    } else {
      return b.get(uri, data)
    }
  }
}

export async function bleStartNotification(callback) {
    setTimeout(() => {
      BleClient.startNotifications(
        store.state.bleDeviceID,
        SERVICE_UUID,
        CHARACTERISTIC_UUID,
        (value) => {
          // value = new TextDecoder().decode(value);
          let notification = dataViewToText(value);
          console.log("dataViewToText(value)", notification)
          try {
            notification = JSON.parse(notification);
          } catch (e) {
            console.log(e, notification)
          }
  
          if (Object.prototype.hasOwnProperty.call(notification, "n") && Object.prototype.hasOwnProperty.call(notification, "t")) {
            const n = notification["n"];
            const t = notification["t"];
            let key = notification.k;
            if (!key.endsWith(":")) {
               key = key + ":"
            }
            if (requests[key] == undefined) {
              requests[key] = [];
            }
            if (n == t) {
              requests[key].push({ num: notification.n - 1, data: notification.d });
              requests[key].sort((a, b) => axiosFetch.num - b.num);
              let response = "";
              for (const item of requests[key]) {
                response += item.data;
              }
              requests[key.slice(0, -1)] = JSON.parse(response);
              // delete requests[key];         
              return;    
            } else {
              requests[key].push({ num: notification.n - 1, data: notification.d });
              b.post(notification.u + "?c=" + notification.n, null, key);
            }
          } else {
            requests[notification.k] = notification.d;
          }
          callback(notification);
        }
      );
    }, 500);
}


export default r;


