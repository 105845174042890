<template>
  <svg fill="none" viewBox="0 0 30 10">
    <g :fill="color" filter="url(#filter0_i14)">
      <circle cx="3.429" cy="4.714" r="3.429"></circle>
      <circle cx="13.714" cy="4.714" r="3.857"></circle>
      <circle cx="25.286" cy="4.714" r="4.714"></circle>
    </g>
    <defs>
      <filter
        id="filter0_i14"
        width="31"
        height="11.429"
        x="-1"
        y="0"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-1" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
        ></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow"></feBlend>
      </filter>
    </defs>
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "freq",
  mixins: [IconMixin],
});
</script>
