import { Capacitor } from '@capacitor/core';
import { SafeArea } from '@aashu-dubey/capacitor-statusbar-safe-area';

export const IconMixin = {
    props: {
        color: {
            type: String,
            required: true
        }
    }
}

export const enum DeviceOrientation {
    portrait = 'portrait',
    landscape = 'landscape'
}

export const DeviceOrientationMixin = {
    data() {
        return {
            orientation:
                window.screen.height > window.screen.width ? DeviceOrientation.portrait : DeviceOrientation.landscape,
            windowWidth: window.screen.width,
            windowHeight: window.screen.height,
            deviceHeight: window.screen.height > window.screen.width ? window.screen.height: window.screen.width,
            deviceWidth: window.screen.height > window.screen.width ? window.screen.width: window.screen.height,
            insets: {}
        }
    },
    created() {
        this.$store.commit('lockScreenOrientation', null);
        window.addEventListener("resize", this.onResize)
    },
    async mounted() {
        if (Capacitor.isNativePlatform()) {
            this.insets = await SafeArea.getSafeAreaInsets();
        } else {
            this.insets = {top: 40}
        }
        this._lockScreenOrientation();
        this.appHeight();
    },
    methods: {
        appHeight() {
            const doc = document.documentElement;
            const appWidth = document.documentElement.clientWidth;
            const appHeight = document.documentElement.clientHeight;
            doc.style.setProperty('--app-width', `${appWidth}px`);
            if (Capacitor.isNativePlatform()) {
                doc.classList.add("cordova-app");
            } else {
                doc.style.setProperty('--app-height', `${appHeight}px`);
            }

            const e = document.getElementsByClassName("v-application--wrap") as any;
            let containerWidth = e[0].clientWidth;
            if (containerWidth>appWidth) {
                containerWidth = appWidth;
            }
            e[0].style.setProperty('--contain-width', `${containerWidth}px`);
            const containerHeight = e[0].clientHeight;
            e[0].style.setProperty('--contain-height', `${containerHeight - this.insets.top}px`);
            e[0].style.setProperty('--insets-top', `${this.insets.top}px`);
            if (containerWidth < containerHeight) {
                e[0].style.setProperty('margin-top', `${this.insets.top}px`);
                e[0].style.setProperty('min-height', `calc(100vh - ${this.insets.top}px`);
            } else {
                e[0].style.setProperty('margin-top', `0px`);
                e[0].style.setProperty('min-height', `100vh`);
            }
            const self = this;
            setTimeout(function () {
                (window as any).scrollTo(0, 0);
                }, 500)
        },
        onOrientationChange() {
            return
        },
        lockScreenOrientation(orientation: string) {
            this.$store.commit('lockScreenOrientation', orientation);
            this._lockScreenOrientation();
        },
        _lockScreenOrientation() {
            if ("orientation" in screen) {
                if (this.$store.state.lockScreenOrientation === "portrait") {
                    console.log("Lock Screen portrait");
                    screen.orientation.lock("portrait").then(
                        success => {
                            console.log("lock portrait");
                            this.$store.commit('lockScreenOrientationNotSupported', false);
                        },
                        (error) => {
                            console.log("lockScreenOrientationNotSupported", error);
                        }
                    );
                } else if (this.$store.state.lockScreenOrientation === "landscape") {
                    console.log("Lock Screen Landscape");
                    try {
                    screen.orientation.lock("landscape-primary").then(
                        success => {
                            console.log("lock landscape");
                            setTimeout(function () {
                                screen.orientation.lock("landscape-primary")
                                }, 500)
                            this.$store.commit('lockScreenOrientationNotSupported', false);
                        },
                        (error) => {
                            console.log("lockScreenOrientationNotSupported", error);
                        }
                    )
                    } catch(e) {
                        console.log(e);
                    }
                } else {
                    console.log("++++++++++++++++++++++Unlock");
                    this.$store.commit('lockScreenOrientation', null);
                    setTimeout(function () {
                        screen.orientation.unlock();
                        }, 500)
                } 
            }
            if (Capacitor.isNativePlatform()) return;
            if (this.$store.state.lockScreenOrientationNotSupported) {
                if ((document.getElementsByClassName('lockScreen') as any)[0] == undefined) return;
                (document.getElementsByClassName('lockScreen') as any)[0].style.display = "none"
                if (this.$store.state.lockScreenOrientation !== null && this.$store.state.lockScreenOrientation != this.orientation) {
                    (document.getElementsByClassName('lockScreen') as any)[0].style.display = "flex";
                }
            }
        },
        async onResize() {
            const _orientation = this.orientation;
            this.orientation =
                document.documentElement.clientHeight > document.documentElement.clientWidth ? DeviceOrientation.portrait : DeviceOrientation.landscape
            this.windowWidth = document.documentElement.clientWidth
            this.windowHeight = document.documentElement.clientHeight;
            if (_orientation != this.orientation) {
                console.log(_orientation, this.orientation);
                this.insets = await SafeArea.getSafeAreaInsets();
                this.onOrientationChange();
                //this._lockScreenOrientation();               
                this.appHeight();
            }
            
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
}
