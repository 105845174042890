<template>
  <div v-on:click="clear" :style="color ? 'color: ' + color : ''">{{ time }}</div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "timer",
  data() {
    return {
      timer: null,
      ms: 0,
      time: "00:00:00",
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
      required: true,
    },
    color: {
      type: String,
    },
  },
  watch: {
    active: function (newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal) {
          this.timer = setInterval(() => {
            this.ms = this.ms + 1;
            this.update(this.ms);
          }, 1000);
        } else {
          clearInterval(this.timer)
        }
      }
    },
  },
  methods: {
    update(time: number) {
      const hour = Math.floor(time / 60 / 60) % 24;
      const min = Math.floor(time / 60) % 60;
      const sec = Math.floor(time) % 60;
      const hourStr = hour < 10 ? "0" + hour : hour;
      const minStr = min < 10 ? "0" + min : min;
      const secStr = sec < 10 ? "0" + sec : sec;
      this.time = hourStr + ":" + minStr + ":" + secStr;
    },
    clear() {
      if (!this.active) {
        this.ms = 0;
        this.time = '00:00:00'
      }
    },
  },
  mounted() {
    if (!this.active) return;
    this.timer = setInterval(() => {
      this.ms = this.ms + 1;
      this.update(this.ms);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
});
</script>

<style lang="scss">
</style>