import Icon from "../icons/Icon.vue";
import { DeviceOrientationMixin } from "../mixins";
import slider from "../slider.vue";
import Timer from "../timer.vue";
import fetchData from '@/fetchData'
import { sleep } from '@/helpers';


const theme = "beginner"

export const BeginnerMixin = {
  props: ['bus'],
  components: { slider, Icon, Timer },
  name: "Beginner",
  mixins: [DeviceOrientationMixin],
  data() {
    return {
      synced: false,
      bleWaitingCount: 0,
      selectedMode: 0,
      currentSinBtn: -1,
      // audio: new Audio("sounds/tick.ogg"),
      freq: 60,
      duty: 30,
      level: 90,
      duty1: 100,
      duty2: 0,
      carouselIndex: 0,
      rollerRange: [100, -100],
      s1: 0,
      s2: 0,
      s3: 0,
      s4: 0,
      r1: 0,
      r2: 0,
      maxValue1: 100,
      maxValue2: 100,
      sinRequest: "",
      counter: null,
      sinPickerKey: false,
      minValue: 1,
      maxValue: 120,
      knobPositionX: 0,
      knobPositionY: 0,
      mouseX: 0,
      mouseY: 0,
      knobCenterX: 0,
      knobCenterY: 0,
      adjacentSide: 0,
      oppositeSide: 0,
      currentRadiansAngle: 0,
      getRadiansInDegrees: 0,
      finalAngleInDegrees: 0,
      currentValue: 80,
      tickHighlightPosition: 0,
      startingTickAngle: -135,
      boundingRectangle: null,
      radius: 200,
      angle: 0,
      buttons: null,
      count: null,
      increase: null,
      endAngle: 0,
      
    };
  },
  created: function () {
    this.bus.$off('sin-click');
    this.bus.$on("sin-click", (data)=>{
      this.carouselIndex = data.carouselIndex;
      if (this.carouselIndex == 0) {
        this.bus.$emit("sin-sync", {
          s1: this.s1,
          s2: this.s2,
          s3: this.s3,
          s4: this.s4,
        })
      }
    })
    if (this.deviceHeight / this.deviceWidth > 1.4) {
      this.lockScreenOrientation('portrait');
    }
    // setTimeout(() => {
      this.sync();
  // }, 1000);
  },
  destroyed() {
    // this.bus.$off('sin-click')
  },
  mounted: function () { 
    this.initKnob()
    if (this.deviceHeight / this.deviceWidth > 1.4) {
      this.lockScreenOrientation('portrait');
    } 
  },
  watch: {
    '$store.state.lockInterface': function () {
      if (!this.synced) {
        this.sync();
      }
    },
    '$store.state.freqFromVoice': function (value) {
      if (value == 0) value = 1;
      this.freq = value;
    },
    '$store.state.levelFromVoice': function (value) {
      this.level = value;
    },
    '$store.state.freqNotification': function (value) {
      this.freq = value;
      this.selectedMode = 0;
      this.setMode(0);
      // this.initKnob(value);
    },
    '$store.state.levelNotification': function (value) {
      this.level = value;
      this.selectedMode = 1;
      this.setMode(1);
      // this.initKnob(value);
    },
    '$store.state.duty2Notification': function (value) {
      this.duty2 = value;
      this.selectedMode = 2;
      this.setMode(2);
      // this.initKnob(value);
    }
  },
  methods: {
    initKnob(num) {
      this.boundingRectangle = this.$refs['knob'].getBoundingClientRect()
      this.tickContainer = this.$refs["tickContainer"]
      this.$refs['knob'].addEventListener(this.getMouseDown(), this.onMouseDown); //listen for mouse button click
      document.addEventListener(this.getMouseUp(), this.onMouseUp); //listen for mouse button release
      this.createTicks(27, 0);
      this.buttons = Array.from(document.querySelectorAll('.result-button'))
      this.count = this.buttons.length
      this.increase = Math.PI * 2 / this.buttons.length
      this.buttons.forEach((button, i) => {
        button.style.top = Math.sin(-Math.PI / 2 + i * this.increase) * this.radius + 'px'
        button.style.left = Math.cos(-Math.PI / 2 + i * this.increase) * this.radius + 'px'
        button.addEventListener('click', this.move)
      })
      this.createTicks(27, this.currentValue * 27/ this.maxValue + 1)
      this.$refs['knob'].style.transform = "rotate(" + this.currentValue * 270 / this.maxValue + "deg)"
    },
    setMode(num) {
      this.selectedMode = num;
      switch (num) {
        case 0:
          this.maxValue = 150;
          this.currentValue = this.freqValue
          if (this.currentValue + 1 > this.maxValue) {
            this.currentValue = this.maxValue
          }
          break
        case 1:
          this.maxValue = 240
          this.currentValue = this.levelValue
          break
        default:
          this.maxValue = 100
          this.currentValue = this.duty2Value
      }
      this.createTicks(27, this.currentValue * 27/ this.maxValue + 1)
      this.$refs['knob'].style.transform = "rotate(" + this.currentValue * 270 / this.maxValue + "deg)"
    },
    move: function (e) {
      const n = this.buttons.indexOf(e.target)
      this.endAngle = (n % this.count) * this.increase
      // console.log(this.endAngle)
      this.turn()
    },

    turn: function () {
      if (Math.abs(this.endAngle - this.angle) > 1 / 8) {
        const sign = this.endAngle > this.angle ? 1 : -1
        this.angle = this.angle + sign / 8
        setTimeout(this.turn, 20)
      } else {
        this.angle = this.endAngle
      }
      this.buttons.forEach((button, i) => {
        button.style.top = Math.sin(-Math.PI / 2 + i * this.increase - this.angle) * this.radius + 'px'
        button.style.left = Math.cos(-Math.PI / 2 + i * this.increase - this.angle) * this.radius + 'px'
        // console.log(i, button.style.top, button.style.left)
      })
    },
    onMouseDown: function () {
      document.addEventListener(this.getMouseMove(), this.onMouseMove); //start drag
    },
    onMouseUp: function () {
      document.removeEventListener(this.getMouseMove(), this.onMouseMove); //stop drag
    },
    
    onOrientationChange() {
      if (this.deviceHeight / this.deviceWidth > 1.4) {
        this.lockScreenOrientation('portrait');
        this.initKnob();
      } 
    },

    createTicks: function (numTicks, highlightNumTicks) {
      //reset first by deleting all existing ticks
      while (this.tickContainer.firstChild) {
        this.tickContainer.removeChild(this.tickContainer.firstChild);
      }

      //create ticks
      for (var i = 0; i < numTicks; i++) {
        var tick = document.createElement("div");

        //highlight only the appropriate ticks using dynamic CSS
        if (i < highlightNumTicks) {
          tick.className = "tick activetick";
        } else {
          tick.className = "tick";
        }

        this.tickContainer.appendChild(tick);
        tick.style.transform = "rotate(" + this.startingTickAngle + "deg)";
        this.startingTickAngle += 10;
      }

      this.startingTickAngle = -130; //reset
    },

    onMouseMove: function (event) {
      this.knobPositionX = this.boundingRectangle.left; //get knob's global x position
      this.knobPositionY = this.boundingRectangle.top; //get knob's global y position

      if (this.detectMobile() == "desktop") {
        this.mouseX = event.pageX; //get mouse's x global position
        this.mouseY = event.pageY; //get mouse's y global position
      } else {
        this.mouseX = event.touches[0].pageX; //get finger's x global position
        this.mouseY = event.touches[0].pageY; //get finger's y global position
      }

      this.knobCenterX = this.boundingRectangle.width / 2 + this.knobPositionX; //get global horizontal center position of knob relative to mouse position
      this.knobCenterY = this.boundingRectangle.height / 2 + this.knobPositionY; //get global vertical center position of knob relative to mouse position

      this.adjacentSide = this.knobCenterX - this.mouseX; //compute adjacent value of imaginary right angle triangle
      this.oppositeSide = this.knobCenterY - this.mouseY; //compute opposite value of imaginary right angle triangle

      //arc-tangent function returns circular angle in radians
      //use atan2() instead of atan() because atan() returns only 180 degree max (PI radians) but atan2() returns four quadrant's 360 degree max (2PI radians)
      this.currentRadiansAngle = Math.atan2(this.adjacentSide, this.oppositeSide);

      this.getRadiansInDegrees = (this.currentRadiansAngle * 180) / Math.PI; //convert radians into degrees

      this.finalAngleInDegrees = -(this.getRadiansInDegrees - 135); //knob is already starting at -135 degrees due to visual design so 135 degrees needs to be subtracted to compensate for the angle offset, negative value represents clockwise direction

      //only allow rotate if greater than zero degrees or lesser than 270 degrees
      if (this.finalAngleInDegrees >= 0 && this.finalAngleInDegrees <= 270) {
        this.$refs['knob'].style.transform = "rotate(" + this.finalAngleInDegrees + "deg)"; //use dynamic CSS transform to rotate volume knob

        //270 degrees maximum freedom of rotation / 100% volume = 1% of volume difference per 2.7 degrees of rotation
        this.currentValue = Math.floor(this.finalAngleInDegrees / (270 / this.maxValue));


        this.tickHighlightPosition = Math.round((this.currentValue * 2.7) / (this.maxValue / 10)); //interpolate how many ticks need to be highlighted

        this.createTicks(27, this.tickHighlightPosition); //highlight ticks

        this.currentValue = Math.round(this.currentValue)
        if (this.currentValue>10)
          this.currentValue = Math.round(this.currentValue / 5) * 5

        switch (this.selectedMode) {
          case 0:
            this.maxValue = 150;
            if (this.freq != this.currentValue)
            {
              this.freqValue = this.currentValue == 0 ? 1 : this.currentValue;
            }
            break
          case 1:
            this.maxValue = 240
            this.levelValue = this.currentValue;
            break
          default:
            this.maxValue = 100
            this.duty2Value = this.currentValue;
        }

        // audio.volume = this.currentValue / 100; //set audio volume

        // document.getElementById("volumeValue").innerHTML = this.currentValue + "%"; //update volume text
      }
    },

    detectMobile: function () {
      var result = (navigator.userAgent.match(/(iphone)|(ipod)|(ipad)|(android)|(blackberry)|(windows phone)|(symbian)/i));

      if (result !== null) {
        return "mobile";
      } else {
        return "desktop";
      }
    },
    getMouseDown: function () {
      if (this.detectMobile() == "desktop") {
        return "mousedown";
      } else {
        return "touchstart";
      }
    },
    getMouseMove: function () {
      if (this.detectMobile() == "desktop") {
        return "mousemove";
      } else {
        return "touchmove";
      }
    },
    getMouseUp: function () {
      if (this.detectMobile() == "desktop") {
        return "mouseup";
      } else {
        return "touchend";
      }
    },

    async sync() {
      if (this.$store.state.useBluetooth && !this.$store.state.bleInitialized && this.bleWaitingCount < 25) {
        console.log("Waiting BLE Device. Beginner, Attemp:", this.bleWaitingCount)
        await sleep(200);
        this.bleWaitingCount++
        await this.sync()
        return
      } else {
        console.log("Waiting BLE Device. Beginner READY", this.$store.state.bleInitialized, this.$store.state.useBluetooth, this.bleWaitingCount)
      }
      this.$store.dispatch("loadState", { theme: "beginner", num: 1 }).then(response => {
        if (!response.data.f) return;
        this.freq = response.data.f;
        this.duty = response.data.d;
        this.level = response.data.l;
        this.duty1 = response.data.d1;
        this.duty2 = response.data.d2;
        this.s1 = response.sin.s1;
        this.s2 = response.sin.s2;
        this.r1 = this.s1;
        this.r2 = this.s2;
        this.s3 = response.sin.s3;
        this.s4 = response.sin.s4;

        this.bus.$emit("sin-sync", {
          s1: this.s1,
          s2: this.s2,
          s3: this.s3,
          s4: this.s4,
        })

        if (response.pedal.on) {
          this.$store.dispatch("switchPedal");
        }
        this.synced = true;
      }).catch(reason => {
        console.log("Load state error: beginner", reason)
      });

      this.$store.dispatch("saveSin", { btnNum: 1, sinNum: 1, value: window.localStorage.getItem('sin1PresetS1') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 1, sinNum: 2, value: window.localStorage.getItem('sin1PresetS2') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 1, sinNum: 3, value: window.localStorage.getItem('sin1PresetS3') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 1, sinNum: 4, value: window.localStorage.getItem('sin1PresetS4') || 0 });


      this.$store.dispatch("saveSin", { btnNum: 2, sinNum: 1, value: window.localStorage.getItem('sin2PresetS1') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 2, sinNum: 2, value: window.localStorage.getItem('sin2PresetS2') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 2, sinNum: 3, value: window.localStorage.getItem('sin2PresetS3') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 2, sinNum: 4, value: window.localStorage.getItem('sin2PresetS4') || 0 });

      this.$store.dispatch("saveSin", { btnNum: 3, sinNum: 1, value: window.localStorage.getItem('sin3PresetS1') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 3, sinNum: 2, value: window.localStorage.getItem('sin3PresetS2') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 3, sinNum: 3, value: window.localStorage.getItem('sin3PresetS3') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 3, sinNum: 4, value: window.localStorage.getItem('sin3PresetS4') || 0 });

      this.$store.dispatch("saveSin", { btnNum: 4, sinNum: 1, value: window.localStorage.getItem('sin4PresetS1') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 4, sinNum: 2, value: window.localStorage.getItem('sin4PresetS2') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 4, sinNum: 3, value: window.localStorage.getItem('sin4PresetS3') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 4, sinNum: 4, value: window.localStorage.getItem('sin4PresetS4') || 0 });
    },
    sinReset() {
      this.s1 = 0;
      this.s2 = 0;
      this.s3 = 0;
      this.s4 = 0;
      this.r1 = 0;
      this.r2 = 0;
      this.$refs.roller1.select(this.r1);
      this.$refs.roller2.select(this.r2);
      setTimeout(() => {
        fetchData.post("/SAVE_SIN_SETTINGS", this.$refs.sin.req);
      }, 100);
    },
    onStart() {
      console.log("switchPedal", this.$store.state.pedalOn)
      this.$store.dispatch("switchPedal");
    },
    sinStop(btnNum) {
      btnNum = this.currentSinBtn;
      if (this.currentSinBtn == -1) {
        return false;
      }
      clearInterval(this.timer);
      if (this.counter > 4) {
        this.$store.dispatch("saveSin", { btnNum, sinNum: 1, value: this.s1 });
        this.$store.dispatch("saveSin", { btnNum, sinNum: 2, value: this.s2 });
        this.$store.dispatch("saveSin", { btnNum, sinNum: 3, value: this.s3 });
        this.$store.dispatch("saveSin", { btnNum, sinNum: 4, value: this.s4 });
        this.currentSinBtn = -1;
      } else {
        this['s1'] = this.$store.state['sin' + btnNum + 'PresetS1'];
        this['s2'] = this.$store.state['sin' + btnNum + 'PresetS2'];
        this['s3'] = this.$store.state['sin' + btnNum + 'PresetS3'];
        this['s4'] = this.$store.state['sin' + btnNum + 'PresetS4'];
        this.r1 = this.s1;
        this.r2 = this.s2;
        this.$refs.roller1.select(this.r1);
        this.$refs.roller2.select(this.r2);

        // this.audio.play();
        this.currentSinBtn = -1;
        setTimeout(() => {
          fetchData.post("/SAVE_SIN_SETTINGS", this.$refs.sin.req)
        }, 100);
        return false;
      }
      this.counter = 0;
    },
    sinStart(btnNum) {
      this.currentSinBtn = btnNum;
      this.timer = setInterval(() => {
        this.counter++;
        if (this.counter == 4) {
          // this.audio.play();
          this.$navigator.vibrate(100);
        }
      }, 100);
    },
    rollerOnChange(key, value, final) {
      this["s" + key] = value;
      const max = Math.abs(this.rollerRange[0]);
      switch (key) {
        case 1:
          if (Math.abs(value) >= max - Math.abs(this.s2)) {
            this.$refs.roller1.stop();

            const s1 = max - Math.abs(this.s2);
            if (this.s1 < 0) {
              this.s1 = -s1;
            } else {
              this.s1 = s1;
            }

            this.r1 = this.s1;
            this.maxValue1 = this.r1;
            this.$refs.roller1.select(this.r1);
          } else {
            if (this.s1 < 0) {
              this.maxValue1 = -max;
            } else {
              this.maxValue1 = max;
            }
          }
          break;
        case 2:
          if (Math.abs(value) >= max - Math.abs(this.s1)) {
            this.$refs.roller2.stop();
            const s2 = max - Math.abs(this.s1);
            if (this.s2 < 0) {
              this.s2 = -s2;
            } else {
              this.s2 = s2;
            }
            this.r2 = this.s2;
            this.maxValue2 = this.r2;
            this.$refs.roller2.select(this.r2);
          } else {
            if (this.s2 < 0) {
              this.maxValue2 = -max;
            } else {
              this.maxValue2 = max;
            }
          }
          break;
        default:
          break;
      }
      if (final) {
        fetchData.post("/SAVE_SIN_SETTINGS", this.$refs.sin.req)
      }
    },
    addPreset(iconName) {
      const preset = {
        iconName: iconName,
        freq: this.freq,
        // duty: this.duty,
        level: this.level,
        //duty1: this.duty1,
        duty2: this.duty2,
        // s1: this.s1,
        // s2: this.s2,
        // s3: this.s3,
        // s4: this.s4,
      }

      this.$store.dispatch("addPreset", { theme, preset });
    },
    selectPreset(preset, trashIsOpen) {
      const key = preset.key;
      this.$store.dispatch("selectPreset", { theme, key, trashIsOpen });
      this.freqValue = preset.freq;
      this.dutyValue = preset.duty;
      this.levelValue = preset.level;
      this.duty1Value = preset.duty1;
      this.duty2Value = preset.duty2;
      this.s1 = preset.s1;
      this.s2 = preset.s2;
      this.r1 = this.s1;
      this.r2 = this.s2;
      this.s3 = preset.s3;
      this.s4 = preset.s4;
      setTimeout(() => {
        fetchData.post("/SAVE_SIN_SETTINGS", this.$refs.sin.req)
      }, 100);
      if (trashIsOpen) {
        return
      }
      if (preset.selected && !this.$store.state.pedalOn) {
        this.onStart()
      }
      if (!preset.selected && this.$store.state.pedalOn) {
        this.onStart()
      }
    },
    removeSelectedPresets() {
      this.$store.dispatch("removeSelectedPresets", theme);
    },
    getValues() {
      const values = [];
      for (let i = this.rollerRange[0]; i >= this.rollerRange[1]; i--) {
        values.push({
          value: i,
          text:
            i < 0 ? '<span class="minus">-</span>' + String(Math.abs(i)) : i,
        });
      }
      return values;
    },
  },
  computed: {
    freqValue: {
      get() {
        return this.freq;
      },
      set(val) {
        this.freq = val
        fetchData.post("/api", {
          n: 1,
          f: val,
        })
      },
    },
    dutyValue: {
      get() {
        return this.duty;
      },
      set(val) {
        this.duty = val
        fetchData.post("/api", {
          n: 1,
          d: val,
        })
      },
    },
    levelValue: {
      get() {
        return this.level;
      },
      set(val) {
        this.level = val
        fetchData.post("/api", {
          n: 1,
          l: val,
        })
      },
    },
    duty1Value: {
      get() {
        return this.duty1;
      },
      set(val) {
        this.duty1 = val
        fetchData.post("/api", {
          n: 1,
          d1: val,
        })
      },
    },
    duty2Value: {
      get() {
        return this.duty2;
      },
      set(val) {
        this.duty2 = val
        fetchData.post("/api", {
          n: 1,
          d2: val,
        })
      },
    },
  },

}
