<template>
  <div>{{ time }}</div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "clock",
  data() {
    return {
      timer: null,
      time: "",
    };
  },
  methods: {
    update() {
      const hours = new Date().getHours()
      const minutes = new Date().getMinutes()
      const _hours = hours < 10 ? '0' + hours : hours
      const _minutes = minutes < 10 ? '0' + minutes : minutes
      this.time = _hours + ':' + _minutes
    }
  },
  created() {
    this.update()
    this.timer = setInterval(
    () => this.update(),
    1000
    )
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
});
</script>

<style lang="scss">
</style>