<template>
  <svg
    viewBox="0 0 16 225"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
    name="scale"
    class="scale"
    version="1.1"
    id="svg77"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_1)" id="g28">
      <path
        d="M5 224H11"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="path2"
      />
      <g filter="url(#filter1_d_1)" id="g6">
        <path
          d="M5 114.5H9"
          :stroke="color"
          stroke-linecap="round"
          stroke-linejoin="round"
          id="path4"
        />
      </g>
      <g filter="url(#filter2_d_1)" id="g10">
        <path
          d="M5 5H11"
          :stroke="color"
          stroke-linecap="round"
          stroke-linejoin="round"
          id="path8"
        />
      </g>
      <path
        d="M5 70.7H6"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="path12"
      />
      <path
        d="M5 92.6H6"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="path14"
      />
      <path
        d="M5 48.8H6"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="path16"
      />
      <path
        d="M5 26.9H6"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="path18"
      />
      <path
        d="M5 202.1H6"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="path20"
      />
      <path
        d="M5 180.2H6"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="path22"
      />
      <path
        d="M5 136.4H6"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="path24"
      />
      <path
        d="M5 158.3H6"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
        id="path26"
      />
    </g>
    <defs id="defs75">
      <filter
        id="filter0_i_1"
        x="4.5"
        y="4.5"
        width="7"
        height="221"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" id="feFlood30" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
          id="feBlend32"
        />
        <feOffset dy="1" id="feOffset34" />
        <feGaussianBlur
          stdDeviation="0.5"
          id="feGaussianBlur36"
          result="result1"
        />
        <feComposite
          in2="result1"
          operator="arithmetic"
          k2="-1"
          k3="1"
          id="feComposite38"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.645833 0 0 0 0 0.521178 0 0 0 0 0.0807292 0 0 0 0.7 0"
          id="feColorMatrix40"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow"
          id="feBlend42"
        />
      </filter>
      <filter
        id="filter1_d_1"
        x="0.5"
        y="110"
        width="13"
        height="9"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" id="feFlood45" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          id="feColorMatrix47"
        />
        <feOffset id="feOffset49" />
        <feGaussianBlur stdDeviation="2" id="feGaussianBlur51" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          id="feColorMatrix53"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
          id="feBlend55"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
          id="feBlend57"
        />
      </filter>
      <filter
        id="filter2_d_1"
        x="0.5"
        y="0.5"
        width="15"
        height="9"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" id="feFlood60" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          id="feColorMatrix62"
        />
        <feOffset id="feOffset64" />
        <feGaussianBlur stdDeviation="2" id="feGaussianBlur66" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          id="feColorMatrix68"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
          id="feBlend70"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
          id="feBlend72"
        />
      </filter>
    </defs>
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "scale",
  mixins: [IconMixin],
});
</script>
