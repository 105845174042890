<template>
  <svg
    width="77"
    height="35"
    viewBox="0 0 77 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.88232 17.4462C7.55649 16.3012 16.986 9.89964 19.0407 7.8006C21.0955 5.70157 25.4979 1.50351 29.7046 1.50351C33.9114 1.50351 36.391 3.00334 37.5311 4.07973C38.4521 4.94926 39.8418 4.91825 40.7016 4.07973C41.4793 3.32127 43.8054 1.50351 48.3889 1.50351C51.6948 1.50351 55.2173 5.24463 58.6598 7.8006C62.4758 11.0446 70.6206 17.3242 74.9846 17.4462"
      :stroke="color"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M1.88281 17.4456C26.3337 31.2313 30.208 32.9818 37.7877 32.9818C45.3675 32.9818 50.6883 32.8952 74.9851 17.4456"
      :stroke="color"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M24.3236 3.71868C20.9272 5.92826 13.669 12.0046 2.91382 18.0809C6.87627 20.8429 15.3672 26.5878 17.6315 27.4716C20.4618 28.5764 31.8213 32.9955 40.3123 32.9955C48.8033 32.9955 65.3845 22.8634 70.4791 20.1014C75.5737 17.3394 75.9361 18.0809 73.6719 16.9761C71.4076 15.8714 57.8221 7.03306 54.4257 3.71868C51.0293 0.404306 48.1789 1.22672 46.4998 1.22672C44.714 1.22672 39.8685 4.50496 39.3025 5.05735C38.7364 5.60974 36.9462 3.71866 34.0474 2.0615C31.3008 0.491391 27.7199 1.50911 24.3236 3.71868Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "lips1",
  mixins: [IconMixin],
});
</script>
