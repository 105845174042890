<template>
  <canvas ref="canvas" :data="req" style="height: 30%, display: flex, margin: 0 auto" />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Sinus extends Vue {
  private toX1 = 0;
  private fromX2 = 0;
  private toX2 = 0;
  private fromX3 = 0;
  private toX3 = 0;
  private fromX4 = 0;
  private toX4 = 0;
  private level = 100;
  private left = 0;
  private req = "";
  @Prop({ default: 0 }) readonly s1: number;
  @Prop({ default: 0 }) readonly s2: number;
  @Prop({ default: 0 }) readonly s3: number;
  @Prop({ default: 0 }) readonly s4: number;
  @Prop({ default: 3 }) readonly sinWidth: number;
  @Prop({ default: true }) readonly showAxis: boolean;
  @Prop({ default: null }) readonly color: string;
  mounted() {
    this.renderCanvas(
      this.s1 >= 0,
      this.s2 >= 0,
      this.s3 < 0,
      this.s4 < 0,
      this.mapX(this.s1),
      this.mapX(this.s2),
      this.mapX(this.s3),
      this.mapX(this.s4)
    );
  }

  @Watch("s1")
  @Watch("s2")
  @Watch("s3")
  @Watch("s4")
  propWatcher() {
    this.renderCanvas(
      this.s1 >= 0,
      this.s2 >= 0,
      this.s3 < 0,
      this.s4 < 0,
      this.mapX(this.s1),
      this.mapX(this.s2),
      this.mapX(this.s3),
      this.mapX(this.s4)
    );
  }

  @Watch("color")
  colorWatcher() {
    this.renderCanvas(
      this.s1 >= 0,
      this.s2 >= 0,
      this.s3 < 0,
      this.s4 < 0,
      this.mapX(this.s1),
      this.mapX(this.s2),
      this.mapX(this.s3),
      this.mapX(this.s4)
    );
  }

  map(x: any, inMin: any, inMax: any, outMin: any, outMax: any) {
    return ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  mapX(x: any) {
    return Math.round(
      (((this.$refs.canvas as any) as any).width -
        ((((this.$refs.canvas as any) as any).width * Math.abs(x)) / 100) * 2) /
        4
    );
  }

  mapY(y: any) {
    return ((this.$refs.canvas as any).height * y) / 360;
  }

  mapPI1(x: any, maxX: any) {
    return this.map(x, 0, maxX, 0, Math.PI / 2);
  }

  mapPI2(x: any, maxX: any) {
    return this.map(x, 0, maxX, Math.PI / 2, Math.PI);
  }

  mapPI3(x: any, maxX: any) {
    return this.map(x, 0, maxX, Math.PI, 1.5 * Math.PI);
  }

  mapPI4(x: any, maxX: any) {
    return this.map(x, 0, maxX, 1.5 * Math.PI, 2 * Math.PI);
  }
  renderCanvas(
    convex1: any,
    convex2: any,
    convex3: any,
    convex4: any,
    s1: any,
    s2: any,
    s3: any,
    s4: any
  ) {
    const ctx = (this.$refs.canvas as any).getContext("2d");
    let totalX = (this.$refs.canvas as any).width / 4;
    ctx.clearRect(
      0,
      0,
      (this.$refs.canvas as any).width,
      (this.$refs.canvas as any).height
    );
    ctx.beginPath();
    ctx.lineWidth = 1;

    ctx.strokeStyle = this.color || this.$store.getters.currentThemeShade.color;
    ctx.shadowColor = this.color || this.$store.getters.currentThemeShade.color;
    ctx.shadowBlur = 2;

    if (this.showAxis) {
      let i;
      for (i = 0; i < totalX * 4; i += 10) {
        ctx.moveTo(i + 5, this.mapY(180));
        ctx.lineTo(i, this.mapY(180));
      }

      for (i = 0; i < totalX * 4; i += 10) {
        ctx.moveTo(totalX, i + 5);
        ctx.lineTo(totalX, i);
      }

      for (i = 0; i < totalX * 4; i += 10) {
        ctx.moveTo(totalX * 2, i + 5);
        ctx.lineTo(totalX * 2, i);
      }

      for (i = 0; i < totalX * 4; i += 10) {
        ctx.moveTo(totalX * 3, i + 5);
        ctx.lineTo(totalX * 3, i);
      }
      ctx.stroke();
    }
    //1
    let x = 0,
      y = 180;
    ctx.beginPath();
    ctx.lineWidth = this.sinWidth;

    ctx.moveTo(x, y);

    let totalX1 = totalX - s1;
    if (totalX1 == 0) {
      totalX1 = 1;
    }
    for (x = 0; x < totalX1; x += 1) {
      ctx.moveTo(x, this.mapY(y));
      if (convex1 === true) {
        y = 180 - 180 * Math.sin(this.mapPI1(x + 1, totalX1));
      } else {
        y = -180 * Math.sin(this.mapPI4(x + 1, totalX1));
      }
      ctx.lineTo(x, this.mapY(y));
    }
    //2
    this.toX1 = x;
    let totalX2 = totalX - s2;
    let fromX = 2 * totalX - totalX2;

    for (x = 0; x < totalX2; x += 1) {
      ctx.moveTo(fromX + x, this.mapY(y));
      if (convex2 === true) {
        y = 180 - 180 * Math.sin(this.mapPI2(x + 1, totalX2));
      } else {
        y = -180 * Math.sin(this.mapPI3(x + 1, totalX2));
      }
      ctx.lineTo(fromX + x, this.mapY(y));
    }
    //3

    let sin1 = [];
    let sin2 = [];

    let totalX3 = totalX - s3;
    if (totalX3 == 0) {
      totalX3 = 1;
    }
    fromX = this.fromX3 = 2 * totalX;
    for (x = 0; x < totalX3; x += 1) {
      let from = { x: fromX + x, y: Math.round(this.mapY(y)) };
      if (convex3 === false) {
        y = 180 - 180 * Math.sin(this.mapPI3(x + 1, totalX3));
      } else {
        y = 360 - 180 * Math.sin(this.mapPI2(x + 1, totalX3));
      }
      let to = { x: fromX + x, y: Math.round(this.mapY(y)) };
      sin1.push({ from: from, to: to });
      for (let i = from.y; i < to.y; i += 1) {
        let _to = { x: to.x, y: i };
        sin1.push({ from: _to, to: _to });
      }
      for (let i = from.x; i < to.x; i += 1) {
        let _to = { x: i, y: to.y };
        sin1.push({ from: _to, to: _to });
      }
    }
    this.toX3 = fromX + x;

    //4
    let totalX4 = totalX - s4;
    fromX = this.fromX4 = 4 * totalX - totalX4;
    if (totalX4 == 0) {
      totalX4 = 1;
    }
    for (x = 0; x < totalX4; x += 1) {
      let from = { x: fromX + x, y: Math.round(this.mapY(y)) };
      if (convex4 === false) {
        y = 180 - 180 * Math.sin(this.mapPI4(x + 1, totalX4));
      } else {
        y = 360 - 180 * Math.sin(this.mapPI1(x + 1, totalX4));
      }
      let to = { x: fromX + x, y: Math.round(this.mapY(y)) };
      sin2.push({ from: from, to: to });
      for (let i = from.y; i > to.y; i--) {
        let _to = { x: to.x, y: i };
        sin2.push({ from: _to, to: _to });
      }
      for (let i = from.x; i < to.x; i += 1) {
        let _to = { x: i, y: to.y };
        sin2.push({ from: _to, to: _to });
      }
    }
    this.toX4 = fromX + x;

    let crossing = null;

    for (let xy1 of sin1) {
      for (let xy2 of sin2) {
        if (xy1.to.x === xy2.to.x && xy1.to.y === xy2.to.y) {
          crossing = xy1;
          break;
        }
      }
    }

    //Draw
    for (let i = 0; i < sin1.length; i++) {
      if (
        crossing &&
        sin1[i].to.x === crossing.to.x &&
        sin1[i].to.y === crossing.to.y
      ) {
        break;
      }
      ctx.moveTo(sin1[i].from.x, sin1[i].from.y);
      ctx.lineTo(sin1[i].to.x, sin1[i].to.y);
    }

    for (let i = sin2.length - 1; i >= 0; i--) {
      if (
        crossing &&
        sin2[i].to.x === crossing.to.x &&
        sin2[i].to.y === crossing.to.y
      ) {
        break;
      }
      ctx.moveTo(sin2[i].from.x, sin2[i].from.y);
      ctx.lineTo(sin2[i].to.x, sin2[i].to.y);
    }

    ctx.stroke();
    if (crossing) {
      this.level = Math.round(
        ((crossing.to.y - (this.$refs.canvas as any).height / 2) * 100) /
          ((this.$refs.canvas as any).height / 2)
      );
      this.left = Math.round(
        ((crossing.to.x - (this.$refs.canvas as any).width / 2) * 100) /
          ((this.$refs.canvas as any).width / 2)
      );
    } else {
      this.level = 100;
    }
    this.req = JSON.stringify({
      l: this.level,
      lt: this.left,
      c1: {
        u1: +(this.s1 > 0),
        p1: Math.abs(this.s1),
        u2: +(this.s2 > 0),
        p2: Math.abs(this.s2),
      },
      c2: {
        u1: +(this.s3 > 0),
        p1: Math.abs(this.s3),
        u2: +(this.s4 > 0),
        p2: Math.abs(this.s4),
      },
    })
  }
}
</script>

<style lang="scss">
</style>