<template>
  <div :style="'--theme-gradient: ' + getGradient()">
    <div v-show="carouselIndex == 0" style="height: 100%">
      <!-- <div v-if="!$store.state.lockInterface" class="gear-bg sin-btn">
        <div class="sin" v-on:click="carouselIndex = 1">
          <sinus
            ref="sin"
            :s1="s1"
            :s2="s2"
            :s3="s3"
            :s4="s4"
            :sinWidth="20"
            :showAxis="false"
            :color="$store.getters.currentThemeShade.color"
          />
        </div>
      </div> -->
      <v-container style="height: 100%">
        <v-row class="sliderContainer">
          <v-col align="center" justify="center">
            <slider :max="150" :min="1" v-model="freqValue" :color="$store.getters.currentThemeShade.color"
              class="slider1">
              <slot>
                <div class="slider-thumb freq">
                  <icon name="freq" />
                </div>
              </slot>
            </slider>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="100" :min="1" v-model="dutyValue" :color="$store.getters.currentThemeShade.color"
              class="slider2">
              <div class="slider-thumb duty">
                <icon name="duty" />
              </div>
            </slider>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="240" :min="1" v-model="levelValue" :color="$store.getters.currentThemeShade.color"
              class="slider3">
              <div class="slider-thumb level">
                <icon name="level" />
              </div>
            </slider>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="100" :min="0" v-model="duty1Value" :color="$store.getters.currentThemeShade.color"
              class="slider4">
              <div class="slider-thumb duty1">
                <icon name="duty1" />
              </div>
            </slider>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="100" :min="0" v-model="duty2Value" :color="$store.getters.currentThemeShade.color"
              class="slider5">
              <div class="slider-thumb duty2">
                <icon name="duty2" />
              </div>
            </slider>
          </v-col>
        </v-row>
        <v-row class="bottomContainer">
          <v-col class="v-col">
            <div v-on:click="onStart">
              <icon name="start-btn-light" :active="$store.state.pedalOn" :class="$store.state.pedalOn && 'active'" />
            </div>
          </v-col>
          <v-col class="timer" :style="{ color: $store.getters.currentThemeShade.color }">
            <timer :active="$store.state.pedalOn" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- <keep-alive> -->
    <div v-show="carouselIndex == 1" class="sin-roller-container">
      <div class="sin-wave sin-col" v-on:click="sinReset()">
        <sinus ref="sin" :s1="s1" :s2="s2" :s3="s3" :s4="s4" />
      </div>
      <div class="sin-buttons" style="display: flex; justify-content: space-between; width: 100%;">
        <v-btn class="gear-bg sin--btn" @touchstart="sinStart(1)" @mouseleave="sinStop(1)"
          @mouseup="sinStop(1)" @touchend="sinStop(1)" @touchcancel="sinStop(1)" @contextmenu.prevent>
          <sinus ref="sin_1" key="sin_1" :s1="$store.state.sin1PresetS1"
            :s2="$store.state.sin1PresetS2" :s3="$store.state.sin1PresetS3" :s4="$store.state.sin1PresetS4" :sinWidth="20"
            :showAxis="false" />
        </v-btn>
        <v-btn class="gear-bg sin--btn" @touchstart="sinStart(2)" @mouseleave="sinStop(2)"
          @mouseup="sinStop(2)" @touchend="sinStop(2)" @touchcancel="sinStop(2)" @contextmenu.prevent>
          <sinus ref="sin_2" key="sin_2" :s1="$store.state.sin2PresetS1"
            :s2="$store.state.sin2PresetS2" :s3="$store.state.sin2PresetS3" :s4="$store.state.sin2PresetS4" :sinWidth="20"
            :showAxis="false" />
        </v-btn>
        <v-btn class="gear-bg sin--btn" @touchstart="sinStart(3)" @mouseleave="sinStop(3)"
          @mouseup="sinStop(3)" @touchend="sinStop(3)" @touchcancel="sinStop(3)" @contextmenu.prevent>
          <sinus ref="sin_3" key="sin_3" :s1="$store.state.sin3PresetS1"
            :s2="$store.state.sin3PresetS2" :s3="$store.state.sin3PresetS3" :s4="$store.state.sin3PresetS4" :sinWidth="20"
            :showAxis="false" />
        </v-btn>
        <v-btn class="gear-bg sin--btn" @touchstart="sinStart(4)" @mouseleave="sinStop(4)"
          @mouseup="sinStop(4)" @touchend="sinStop(4)" @touchcancel="sinStop(4)" @contextmenu.prevent>
          <sinus ref="sin_4" key="sin_4" :s1="$store.state.sin4PresetS1"
            :s2="$store.state.sin4PresetS2" :s3="$store.state.sin4PresetS3" :s4="$store.state.sin4PresetS4" :sinWidth="20"
            :showAxis="false" />
        </v-btn>
      </div>
      <div class="sin-col">
        <div class="rollers" :style="cssVars">
          <rollerPickerBox class="rotor1-box">
            <rollerPicker ref="rotor1" :key="r1Key" class="rotor1" :data="values" :index="r1Index" @change="(index, final) => {rollerOnChange(1, index, final)}" :layer="false" :options="options"></rollerPicker>
          </rollerPickerBox>
          <rollerPickerBox class="rotor2-box">
            <rollerPicker ref="rotor2" :key="r2Key" class="rotor2" :data="values" :index="r2Index" @change="(index, final) => {rollerOnChange(2, index, final)}" :layer="false" :options="options"></rollerPicker>
          </rollerPickerBox>
          <rollerPickerBox class="rotor3-box">
            <rollerPicker ref="rotor3" :key="r3Key" class="rotor3" :data="values" :index="r3Index" @change="(index, final) => {rollerOnChange(3, index, final)}" :layer="false" :options="options"></rollerPicker>
          </rollerPickerBox>
          <rollerPickerBox class="rotor4-box">
            <rollerPicker ref="rotor4" :key="r4Key" class="rotor4" :data="values" :index="r4Index" @change="(index, final) => {rollerOnChange(4, index, final)}" :layer="false" :options="options"></rollerPicker>
          </rollerPickerBox>
        </div>
      </div>
      </div>
    </div>
    <!-- </keep-alive> -->
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import sinus from "../sinus.vue";
import { ProfessionalMixin } from "./ProfessionalMixin";
import { GradientMixin } from "./GradientMixin";
import Icon from "../icons/Icon.vue";
import SphereScroll from "../SphereScroll.vue";

export default Vue.extend({
  components: { sinus, SphereScroll, Icon },
  name: "ProfessionalWhite",
  mixins: [ProfessionalMixin, GradientMixin],
  computed: {
    cssVars() {
      return {
      "--color": this.$store.getters.currentThemeShade.color,
      }
    }
  }
});
</script>

<style lang="scss">
.theme-professional-white {
  .v-btn--icon.v-size--default {
    @media screen and (max-width: 700px) {
      height: 10px;
      width: 10px;
      margin: 29px 29px 9px 29px;
    }
  }
  .gear-bg {
    .sin {
      canvas {
        @media screen and (min-width: 700px) {
          transform: scale(1.2) rotate(180deg);
        }
        @media screen and (min-width: 1000px) {
          transform: scale(1.6) rotate(180deg);
        }
      }
    }
  }

  .roller-picker-item {
      font-size: 25px;
      color: var(--color)
  }

  .sin-buttons {
    position: relative;
    z-index: 4;
    @media screen and (min-width: 700px) {
      transform: scale(1.7);
      margin-top: 7vh;
      width: 45vw;
      margin: 12vw auto;
      .sin--btn {
        margin: 1.4vw;
      }
    }
  }
  .rollers {
    @media screen and (min-width: 700px) {
      transform: scale(1.7);
      padding-top: 45px;
    }
    margin-top: 30px;
    @media screen and (max-width: 700px) {
      margin-top: -15px!important;
    }
    
  }
  @media (orientation: portrait) {
    .rollers {
      display: flex;
      justify-content: space-around;
    }
  }
  .sin-wave {
    display: flex;
    justify-content: center;
    canvas {
      @media screen and (min-width: 700px) {
        transform: scale(1.7);
      }
      @media screen and (min-width: 1000px) {
        transform: scale(2);
      }
    }
  }
  @media (orientation: portrait) {
    .rollers {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      margin-top: 25px;
    }
  }
  &.v-application {
    background: linear-gradient(
        236.16deg,
        #ffffff -24.98%,
        rgba(189, 189, 189, 0) 104%
      ),
      #dadada !important;
  }
  .sliderContainer {
    height: calc(100% - 10vh);
  }
  .gear-bg {
    background-image: url(/i/p/gear-bg-white.svg);
    transform: rotate(180deg);
    &.sin--btn {
      background-color: initial !important;
      box-shadow: none;
      height: 50px !important;
      width: 50px !important;
      min-width: 50px;
    }
    canvas {
      transform: rotate(180deg);
      width: 37px;
    }
    svg {
      transform: rotate(180deg);
      height: 36px;
    }
    
    &.active {
      background-image: url(/i/p/top-btn-white-bg-active.svg);
      transform: rotate(180deg);
    }
  }
  .brain-bg {
    background-image: url(/i/p/gear-bg-white.svg);
    transform: rotate(180deg);
    svg {
      transform: rotate(180deg);
      height: 35px;
      width: 35px;
      padding-right: 1px;
    }
    &.active {
      background-image: url(/i/p/top-btn-white-bg-active.svg);
      transform: rotate(360deg);
      svg {
        transform: rotate(360deg);
        margin-top: 6px;
        padding-bottom: 0px;
        margin-right: -2px;
      }
    }
  }
  .slider-icon {
    margin-right: -1px;
    transform: rotate(270deg);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (orientation: landscape) {
      margin: -3px 0px 2px 2px;
    }
    svg {
      height: 30px;
      @media screen and (min-width: 700px) {
        height: 5vw;
      }
    }
  }
  .sin-btn {
    display: flex;
    justify-content: center;
    height: 49px;
    width: 100%;
    position: fixed;
    .sin {
      align-self: center;
    }
  }
  .slider-container {
    .value {
      height: 8vh;
    }
    .slider {
      height: calc(100% - 10vh);
      .track {
        background: var(--theme-gradient) !important;
      }
    }
    &.slider1 .slider {
      .rail {
        background: linear-gradient(
            90deg,
            #ffffff -23.33%,
            #ffffff -23.32%,
            rgba(236, 236, 236, 0.500402) 53.51%,
            rgba(208, 208, 208, 0) 123.33%
          ),
          linear-gradient(
            90deg,
            #575757 -23.33%,
            #d0d0d0 -23.32%,
            rgba(153, 153, 153, 0.500402) 56.57%,
            rgba(92, 92, 92, 0) 123.33%
          ),
          #d9d9d9;
      }
    }
    &.slider2 .slider {
      .rail {
        background: linear-gradient(
            90deg,
            #ffffff -23.33%,
            #ffffff -23.32%,
            rgba(236, 236, 236, 0.500402) 53.51%,
            rgba(208, 208, 208, 0) 123.33%
          ),
          linear-gradient(
            90deg,
            #575757 -23.33%,
            #d0d0d0 -23.32%,
            rgba(153, 153, 153, 0.500402) 56.57%,
            rgba(92, 92, 92, 0) 123.33%
          ),
          #d9d9d9;
      }
    }
    &.slider3 .slider {
      .rail {
        background: linear-gradient(
            90deg,
            #ffffff -23.33%,
            #ffffff -23.32%,
            rgba(236, 236, 236, 0.500402) 65.74%,
            rgba(208, 208, 208, 0) 123.33%
          ),
          linear-gradient(
            90deg,
            #575757 -23.33%,
            #d0d0d0 -23.32%,
            rgba(153, 153, 153, 0.500402) 56.57%,
            rgba(92, 92, 92, 0) 123.33%
          ),
          #d9d9d9;
      }
    }
    &.slider4 .slider {
      .rail {
        background: linear-gradient(
            90deg,
            #ffffff -23.33%,
            #ffffff -23.32%,
            rgba(236, 236, 236, 0.500402) 53.51%,
            rgba(208, 208, 208, 0) 123.33%
          ),
          linear-gradient(
            90deg,
            #575757 -23.33%,
            #f8f5f5 -23.32%,
            rgba(175, 175, 175, 0.500402) 56.57%,
            rgba(92, 92, 92, 0) 123.33%
          ),
          #d9d9d9;
      }
    }
    &.slider5 .slider {
      .rail {
        background: linear-gradient(
            90deg,
            #ffffff -23.33%,
            #ffffff -23.32%,
            rgba(236, 236, 236, 0.500402) 53.51%,
            rgba(208, 208, 208, 0) 123.33%
          ),
          linear-gradient(
            90deg,
            #575757 -23.33%,
            #f8f5f5 -23.32%,
            rgba(175, 175, 175, 0.500402) 56.57%,
            rgba(92, 92, 92, 0) 123.33%
          ),
          #d9d9d9;
      }
    }
    .slider-thumb {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(/i/p/thumb-white.svg);
      position: inherit;
      svg {
        margin-bottom: -2px;
        margin-right: 2px;
      }
      &.freq svg {
        margin-left: 1px;
        width: 30px;
        @media screen and (min-width: 700px) {
          height: 3vh;
          width: 4vh;
        }
      }
      &.duty svg {
        margin-left: 0px;
        width: 30px;
        @media screen and (min-width: 700px) {
          height: 3vh;
          width: 4vh;
        }
      }
      &.level svg {
        margin-left: -2px;
        height: 35px;
        width: 30px;
        @media screen and (min-width: 700px) {
          height: 4vh;
          width: 7vh;
        }
      }
      &.duty1 svg {
        height: 35px;
        width: 25px;
        @media screen and (min-width: 700px) {
          height: 3vh;
          width: 7vh;
        }
      }
      &.duty2 svg {
        margin-left: 0px;
        height: 35px;
        width: 25px;
        @media screen and (min-width: 700px) {
          height: 3vh;
          width: 3vh;
        }
      }
    }
  }
  .bottomContainer {
    .v-col {
      align-items: center;
      display: flex;
    }
    .start-btn-light {
      svg {
        height: 7vh;
        width: auto;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        // svg {
        //   height: 4vh;
        //   margin-top: calc(0.5vh + 0.5vw);
        // }
      }
      &.active {
        filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.2));
      }
      &:not(.active) {
        filter: drop-shadow(-1px 3px 3px rgba(0, 0, 0, 0.7));
      }
    }
    .timer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 34px;
      font-family: Rajdhani;
      font-weight: 600;
      div {
        font-size: 8vw;
        width: 28vw;
      }
    }
  }
  .presets {
    .v-list-item-group {
      display: initial;
    }
    .no-items {
      color: black;
    }
    background: linear-gradient(
        236.16deg,
        #ffffff -24.98%,
        rgba(189, 189, 189, 0) 104%
      ),
      #dadada !important;
    .container-main {
      .icon-bg {
        background-image: url(/i/p/preset-icon-bg-white.svg);
      }
      .list .activeItem .icon-bg {
        background-image: url(/i/p/preset-icon-bg-white-selected.svg);
      }
      .preset {
        background: linear-gradient(
            236.16deg,
            #ffffff -24.98%,
            rgba(189, 189, 189, 0) 104%
          ),
          #dadada;
        width: 100%;
      }
      .list .activeItem .preset {
        background: radial-gradient(
          165% 154.39% at -20% 112.28%,
          #c6c6c6 0%,
          rgba(184, 184, 184, 0.15) 100%
        );
        box-shadow: inset -2px 2px 4px rgba(0, 0, 0, 0.2);
      }
      .icon-dialog {
        background: linear-gradient(218.19deg, #e1e1e1 -21.73%, #cdcdcd 88.77%),
          #c4c4c4;
        box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);
        .container .item-bg {
          transform: rotate(180deg);
          svg {
            transform: rotate(180deg);
          }
          background-image: url(/img/p/preset-icon-dialog-bg-white.svg);
          .eyebrows1,
          .eyebrows2 {
            margin-right: 0px;
          }
        }
      }
    }
  }
  @media screen and (orientation: landscape) {
    
    .gear-bg .sin canvas {
      transform: scale(1.2) rotate(180deg);
    }
    .sin-btn {
      height: 5vw;
    }
    .slider-container {
      .value {
        font-size: 4vw;
      }
      .slider {
        width: 3vw;
        .rail {
          .track {
            width: 3vw;
          }
        }
      }
      .slider-thumb {
        width: 5vw;
        height: 8vw;
      }
    }
    .sin-buttons {
      width: 25vw;
      margin: 7vw auto;
      transform: scale(1.3);
    }
    .sin-wave {
      canvas {
        transform: scale(1.3);
        @media screen and (min-width: 1200px) {
          transform: scale(1.6) rotate(180deg);
        }
      }
    }
    .roller-picker {
      width: 40vw;
    }
    .bottomContainer {
      .timer {
        div {
          font-size: 6vw;
          width: 21vw;
        }
      }
    }
  }
  @media (orientation: portrait) {
    .sin-roller-container {
      margin-top: 8vh;
    }
  }
  @media (orientation: landscape) {
    .sin-roller-container {
      display: flex;
      align-items: center;
      height: 60vh;
      .roller-picker {
        width: 40vw;
        margin-top: 15vh;
        margin-left: 5vw;
        height: 50vh;
        &.sin-picker .select-wrap {
          height: 50vh;
          font-size: 4vw;
        }
        .highlight {
          font-size: 4vw;
        }
        position: relative;
        right: -9vw;
        @media screen and (max-width: 750px) {
          right: 0vw;
        }
      }
    }
    .sin-wave {
      margin-top: 15vh;
    }
    @media screen and (max-width: 750px) {
      .sin-wave canvas,
      .sin-buttons {
        transform: none;
      }
    }
    @media screen and (device-aspect-ratio: 16/9) {
      .drawer {
        padding-top: 10vh;
      }
      
      .sin-btn {
        height: 3.7vw;
        top: 2vw;
      }
      
      .sin-btn-container {
        display: none;
      }
      .bottomContainer .timer div {
        font-size: 8vh;
        width: 13vw;
      }
      .slider-container {
        .slider {
          width: 2vw;
          .rail {
            .track {
              width: 2vw;
            }
          }
        }
        .value {
          height: 10vh;

        }
      }
    }
  }
}
</style>