<template>
  <div class="mic-settings">
    <h3 v-if="orientation == 'landscape'" style="text-align: center;margin-bottom: 30px;">
      {{ $t("sound") }}
    </h3>
    <v-sheet class="pa-4 text-center mx-auto" elevation="12" max-width="800" rounded="lg" width="100%">

      <v-row style="position: relative" justify="space-around">
        <div v-if="state == 0" class="icon-container">
          <img v-if="this.$vuetify.theme.dark" src="/i/volume-off-l.svg" style="
              height: 30px;
              margin-top: 16px;
            " />
          <img v-else src="/i/volume-off.svg" style="
            position: relative;
            height: 30px;
            margin-top: 16px;
          " />
        </div>
        <div v-if="state == 1" class="icon-container">
          <img v-if="this.$vuetify.theme.dark" src="/i/voice-l.svg" style="
              height: 30px;
              margin-top: 16px;
            " />
          <img v-else src="/i/voice.svg" style="
            position: relative;
            height: 30px;
            margin-top: 16px;
          " />
        </div>
        <div v-if="state == 2" class="icon-container">
          <img v-if="this.$vuetify.theme.dark" src="/i/voice0-l.svg" style="
              height: 30px;
              margin-top: 16px;
            " />
          <img v-else src="/i/voice0.svg" style="
            position: relative;
            height: 30px;
            margin-top: 16px;
          " />
        </div>
        <div style="width: 185px;display: flex;justify-content: center;position: relative;">
          <!-- <v-switch class="switch" inset v-model="this.$store.state.voiceMode1" @change="(val) => (toogleVoiceMode(val))"></v-switch> -->
          <ThreeStateSwitch @change="toogleVoiceMode" :value="state"/>
        </div>
      </v-row>
    </v-sheet>
    <v-sheet v-show="false" style="margin-top: 20px" class="pa-4 text-center mx-auto" elevation="12" max-width="800" rounded="lg"
      width="100%">

      <v-row style="position: relative" justify="space-around">
        <div class="icon-container">
        <img v-if="this.$vuetify.theme.dark" src="/i/voice1-l.svg" style="
      position: relative;
      height: 26px;
      margin-top: 17px;
    " />
        <img v-else src="/i/voice1.svg" style="
    position: relative;
    height: 26px;
    margin-top: 17px;
  " />
        </div>
        <div style="width: 185px;display: flex;justify-content: center;">
          <v-switch class="switch"  inset v-model="$store.state.micOn" @change="(val) => (toogleMic(val))"></v-switch>
        </div>
      </v-row>
    </v-sheet>
    <!-- <v-sheet style="margin-top: 20px" class="pa-4 text-center mx-auto" elevation="12" max-width="800" rounded="lg"
      width="100%">

      <v-row style="position: relative" justify="space-around">
        <div class="icon-container" style="position: relative; left: 0px;">
                <img v-if="this.$vuetify.theme.dark" :src="volume > 1 ? '/i/volume-l.svg': '/i/volume-off-l.svg'" style="
              position: relative;
              height: 28px;
              margin-top: 17px;
            " />
                <img v-else :src="volume > 1 ? '/i/volume.svg' : '/i/volume-off.svg'" style="
          position: relative;
          height: 28px;
          margin-top: 17px;
        " />
        </div>
        <v-slider style="max-width: 185px; margin-top: 16px;" min="1" v-model="volume" thumb-label :thumb-size="30"
           @end="setVolume"></v-slider>

      </v-row>
    </v-sheet>
    <v-sheet v-if="voices.length > 0" style="margin-top: 20px" class="pa-4 text-center mx-auto" elevation="12" max-width="800" rounded="lg"
      width="100%">
      <v-row style="position: relative" justify="space-around">
        <div class="icon-container" style="position: relative; left: -7px;">
                <img v-if="this.$vuetify.theme.dark" src="/i/voice2-l.svg" style="
              position: relative;
              height: 30px;
              margin-top: 19px;
              margin-left: 5px;
            " />
                <img v-else src="/i/voice2.svg" style="
          position: relative;
          height: 30px;
          margin-top: 19px;
          margin-left: 5px;
        " />
        </div>
        <v-select style="max-width: 185px;" :items="voices" v-model="voice" @change="setVoice"></v-select>
      </v-row>
    </v-sheet> -->
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { DeviceOrientationMixin } from "../mixins";
import ThreeStateSwitch from '@/components/ThreeStateSwitch.vue'
    
export default Vue.extend({
  name: "SoundSettings",
  mixins: [DeviceOrientationMixin],
  components: {ThreeStateSwitch},
  data() {
    return {
      volume: 50,
      voice: "",
      voices: [],
      state: 0,
    }
  },
  async mounted() {
    this.volume = this.$store.state.volume;
    this.voice = this.$store.state.voice;
    if (this.$store.state.micOn) {
      if (this.$store.state.voiceMode1 == "0") {
        this.state = 1
      } else [
        this.state = 2
      ]
    } else {
      this.state = 0
    }
    // const defaultVoice = await this.loadVoices()
    // if (!this.$store.state.voice) {
    //   this.voice = defaultVoice
    //   this.$store.commit('setVoice', { value: defaultVoice })
    // }
  },
  computed: {
    color() {
      if (this.volume < 30) return 'indigo'
      if (this.volume < 50) return 'teal'
      if (this.volume < 80) return 'green'
      if (this.volume < 100) return 'orange'
      return 'red'
    },
  },
  methods: {
    toogleVoiceMode: async function (value) {
      this.state = value;
      value = value - 1;
      if (value < 0) {
        this.$store.commit('toogleMic', { on: false })
        window.localStorage.setItem('micOn', "0");
      } else {
        this.$store.commit('toogleMic', { on: true })
        window.localStorage.setItem('micOn', "1");
        this.$store.commit('toogleVoiceMode', { value: value ? "1" : "0" })
      }
    },
    setVoice: async function (value) {
      this.$store.commit('setVoice', { value: value })
      window.localStorage.setItem('voice', value);
    },
    setVolume: async function (value) {
      this.$store.commit('setVolume', { volume: value })
      window.localStorage.setItem('volume', value);
    },
  },
});
</script>

<style lang="scss">
.settings {

  .switch {
    margin-bottom: 8px;
  }

  .control-value {
    margin-top: 0px;
  }


  .v-messages {
    display: none;
  }

  .icon-container {
    position: absolute;
    width: 32px;
    height: 62px;
    left: 10px;
  }

  .mic-settings {
    padding: 2% 6% 14% 6%;
    overflow: scroll;

    @media (orientation: landscape) {
      height: 70vh;
    }
  }

  .v-slider__thumb {
    width: 17px;
    height: 17px;

    &::before {
      left: -9px;
      top: -10px;
    }
  }

  .v-slider__thumb-label {
    margin-left: 2px;
  }
}
</style>