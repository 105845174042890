import { DeviceOrientation, DeviceOrientationMixin } from "../mixins";
import Timer from "../timer.vue";
import RollerPicker from "../RollerPicker/RollerPicker.vue";
import Roller from "../RollerPicker/Roller.vue";
import Fan from "../icons/Fan.vue";
import Clock from "../clock.vue";
import fetchData from '@/fetchData'
import { isSafari } from "@/helpers";
import vueIosRollerPicker from '../vueIosRollerPicker'
import Vue from "vue";

Vue.use(vueIosRollerPicker)

export const RotorMixin = {
  components: { Roller, Fan, Timer, Clock },
  name: "Rotor",
  mixins: [DeviceOrientationMixin],
  data: () => ({
    activeRotor: 0,
    rotorKey: 0,
    r1Index: 100,
    r2Index: 100,
    r3Index: 100,
    r1Level: 10,
    r2Level: 10,
    r3Level: 10,
    direction: true,
    values: [],
    options: {loop:false, height: 250, width: 70, opacity: 12}
  }),
  created() {
    // if (!this.$cordova && isSafari()) {
    //   this.lockScreenOrientation('portrait')
    // }
    // this.$store.dispatch("loadState", { theme: "rotor", num: 2, loadPresets: false }).then((response) => {
    //   this.r1Level = response.data.l1;
    //   // this.$refs.rotor1.setSelectIndex(this.r1Level);
    //   this.r2Level = response.data.l2;
    //   // this.$refs.rotor2.setSelectIndex(this.r2Level);
    //   this.r3Level = response.data.l3;
    //   // this.$refs.rotor3.setSelectIndex(this.r3Level);
    //   this.direction = response.data.dr;
    //   this.activeRotor = response.data.c;
    //   if (response.pedal.on) {
    //     this.$store.dispatch("switchPedal");
    //   }
    // })
    this.values = this.getValues()
  },
  mounted() {
    this.calcInterface();
    this.roller1OnChange(this.r1Index);
    this.roller2OnChange(this.r1Index);
    this.roller3OnChange(this.r1Index);
    // const ydTopMask = document.getElementsByClassName("yd-root")[0];
    // const height = ydTopMask.getBoundingClientRect().top + ydTopMask.clientHeight + "px";
    // (document.getElementsByClassName("rotor-bg")[0] as any).style.height = height;
  },
  methods: {
    calcInterface() {
      if (this.orientation == DeviceOrientation.landscape) {
        this.options.height = 250;
        this.options.width = 70;
        if (window.innerHeight < 345) {
          this.options.height = 210;
        }
      } else {
        this.options.height = 250;
        this.options.width = 80;
      }
      (document.getElementsByClassName("rotor-bg")[0] as any).setAttribute("style", "width: " + (document.getElementsByClassName("roller-container")[0].clientWidth) + "px!important;");
      // debugger
      this.rotorKey++;
      (document.getElementsByClassName("fanContainer")[0] as any).style.height = (document.getElementsByClassName("rotor1-bg")[0].clientWidth) + "px";
      if (this.orientation == DeviceOrientation.landscape) {
        if (window.innerHeight < 345) {
          (document.getElementsByClassName("rotor-bg")[0] as any).setAttribute("style", "height: 215px!important;");
        }
        if (window.innerHeight >= 390) {
          (document.getElementsByClassName("rotor-bg")[0] as any).setAttribute("style", "height: 255px!important;");
        }
      } else {
        (document.getElementsByClassName("rotor-bg")[0] as any).setAttribute("style", "height: 420px!important;");
        if (window.innerHeight < 670) {
          (document.getElementsByClassName("rotor-bg")[0] as any).setAttribute("style", "height: 355px!important;");
        }
      }
    },
    toogleDirection(value) {
      this.direction = value
      fetchData.post("/api", {
        n: 2,
        "dr": this.direction
      })
    },
    roller1OnChange(index, final) {
      if (final) {
        this.r1Index = index;
        const value = this.values[index].text
        this.r1Level = value
        fetchData.post("/api", {
          n: 2,
          l1: value,
          "dr": this.direction
        })
      }
    },
    roller2OnChange(index, final) {
      if (final) {
        this.r2Index = index;
        const value = this.values[index].text
        this.r2Level = value
        fetchData.post("/api", {
          n: 2,
          l2: value,
          "dr": this.direction
        })
      }
    },
    roller3OnChange(index, final) {
      if (final) {
        this.r3Index = index;
        const value = this.values[index].text
        this.r3Level = value
        fetchData.post("/api", {
          n: 2,
          l3: value,
          "dr": this.direction
        })
      }
    },
    onOrientationChange() {
      const that = this;
      setTimeout(
        () => {
          that.calcInterface()
        },
        5
      );
    },
    rotorStart(num) {
      // if (this.$store.state.pedalOn && this.activeRotor != num) return
      if (!this.$store.state.pedalOn || this.activeRotor === num)
        this.$store.dispatch("switchPedal");
      this.activeRotor = num;
      this["roller" + num + "OnChange"](this["r" + num + "Index"])
    },
    getFanClass(num) {
      let res = (this.activeRotor == num && this.$store.state.pedalOn) && 'active';
      if (this.direction == false) res += ' spin-back';
      return res
    },
    onMoveStart(num) {
      if (!this.$store.state.pedalOn)
        this.activeRotor = num;
    },
    getValues() {
      const values = [];
      for (let i = 150; i > 0; i--) {
        values.push({
          value: i,
          text: String(Math.abs(i)),
        });
      }
      return values;
    },
  },
}
