<template>
    <div style="width: 100%; height: 100%;">
        <div class="splash-header">

            <div class="splash-inner-header splash-flex">
                <h1>Spica</h1>
            </div>

            <div>
                <svg class="splash-waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path id="gentle-wave"
                            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g class="splash-parallax">
                        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>

        </div>

        <div class="splash-content splash-flex">
            <p v-if="isWebBleNotSupported">
                Браузер не поддерживает установку соединения с устройством
            </p>
            <p v-else>{{ $t("fullscreen_ble") }}</p>
        </div>
        <!--Content ends-->
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Capacitor } from '@capacitor/core';

@Component
export default class Splash extends Vue {
    public isWebBleNotSupported = false;
    async mounted() {
        const splashHeader = document.getElementsByClassName("splash-header")[0].clientHeight;
        const splashContent = document.getElementsByClassName("splash-content")[0];
        const splashContentHeight = "calc(100% - " + (splashHeader - 1).toString() + "px)";
        (splashContent as any).style["height"] = splashContentHeight; 
        if (!Capacitor.isNativePlatform() && !("bluetooth" in window.navigator)) {
            this.isWebBleNotSupported = true;
        }
    }
}
</script>

<style lang="scss">

h1 {
  font-family: 'Lato', sans-serif;
  font-weight:300;
  letter-spacing: 2px;
  font-size:36px;
}
p {
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  font-size:18px;
  color: #333333;
}

.splash-header {
    position: relative;
    text-align: center;
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: white;
}

.splash-logo {
    width: 50px;
    fill: white;
    padding-right: 15px;
    display: inline-block;
    vertical-align: middle;
}

.splash-inner-header {
    height: 55vh;
    width: 100%;
    margin: 0;
    padding: 0;
}

.splash-flex {
    /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.splash-waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}

.splash-content {
    position: relative;
    text-align: center;
    background-color: white;
    margin-top: -1px;
}

/* Animation */

.splash-parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.splash-parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.splash-parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.splash-parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.splash-parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
    .splash-waves {
        height: 40px;
        min-height: 40px;
    }


}</style>