<template  v-slot:parentProps="{ parentProps }">
  <div :id="'roller' + id" ref="roller" :class="'roller'" />
</template>

<script lang="ts">
import IosSelector from "@/js/iosSelector";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

export interface RollerItemInterface {
  value: any;
  text: string;
}

@Component({
  // components: {
  //   RollerPicker,
  // },
})
export default class Roller extends Vue {
  @Prop({ required: true }) readonly values: Array<RollerItemInterface>;
  @Prop({ required: true }) readonly id: any;
  @Prop({}) readonly sensitivity: number;
  @Prop({}) readonly count: number;
  @Prop({}) readonly onMoveStart: Function;
  @Prop({}) readonly onChange: Function;
  @Prop({ required: true }) readonly value: any;
  @Prop({ required: false }) readonly minValue: any;
  @Prop({ required: false }) readonly maxValue: any;

  iosSelector: any;
  newVal: any = null;

  @Watch("value")
  setNewValue(value: any) {
    this.iosSelector.select(value);
    this.iosSelector.value = value;
  }

  @Watch("minValue")
  setMinValue(value: any) {
    this.iosSelector.minValue = value;
  }

  @Watch("maxValue")
  setMaxValue(value: any) {
    this.iosSelector.maxValue = value;
  }

  stop() {
    this.iosSelector._stop();
  }

  select(value: any) {
    this.iosSelector.select(value);
  }

  selectAnimated(value: any) {
    this.iosSelector.selectAnimated(value);
  }

  mounted() {
    const self = this;
    setTimeout(function () {
      self.iosSelector = new IosSelector({
        el: "#roller" + self.id,
        type: "normal",
        source: self.values,
        minValue: self.minValue || self.values[self.values.length - 1].value,
        maxValue: self.maxValue || self.values[0].value,
        sensitivity: self.sensitivity || 0.8,
        count: self.count || 15,
        onChange: (selected: any, final: boolean) => {
          self.onChange && self.onChange(self.id, selected, final);
        },
        onMoveStart: () => {
          self.onMoveStart && self.onMoveStart(self.id);
        },
      });
      self.iosSelector.select(self.value);
    }, 10);
  }
  beforeDestroy() {
    console.log("destroy");
    this.iosSelector.destroy();
  }
}
</script>