<template>
  <div class="slider-container">
    <div class="value" :style="{ color: color }">{{ value }}</div>
    <!-- <div class="value" :style="{ color: color }">{{ hValue }}</div> -->
    <div class="slider" @input="handleInput" :value="value">
      <div class="rail" ref="rail">
        <div
          class="track"
          :style="{
            height: trackHeight + 'px',
            background:
              `linear-gradient(0deg, ` + color + `, ` + color + `), #474747`,
          }"
        ></div>
      </div>

      <div class="thumb-container" ref="thumb">
        <slot>
          <div class="slider-thumb"></div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Draggabilly from "@/js/draggabilly";

export default Vue.extend({
  name: "slider",
  props: {
    color: {
      type: String,
      default: "#F38EEF",
    },
    value: Number,
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      timer: null,
      draggable: null,
      dragging: false,
      sliderValue: this.value,
      hValue: this.value,
      trackHeight: 0,
      orientation:
        window.screen.height > window.screen.width ? "portrait" : "landscape",
    };
  },
  watch: {
    value: function (newVal) {
      this.sliderValue = newVal;
      if (!this.dragging) {
        this.setThumbPosition();
      }
    },
  },
  methods: {
    handleInput() {
      this.$emit("input", this.sliderValue);
    },
    dragStart() {
      this.$emit("moveStart", this.sliderValue);
      this.dragging = true;
      this.timer = setInterval(
        () => this.$emit("input", this.sliderValue),
        150
      );
    },
    dragMove() {
      const y = Math.abs((this.draggable).position.y);
      this.trackHeight = y - (this.$refs.thumb as Element).clientHeight / 2;
      const value = this.map(y);
      this.setValue(value);
    },
    dragEnd() {
      this.$emit("moveEnd", this.sliderValue);
      this.dragging = false;
      clearInterval(this.timer);
    },
    setValue(value: number) {
      if (value == this.sliderValue) return;
      this.sliderValue = value;
      let v = this.sliderValue;
      // if (v > 30 && this.sliderValue % 5 > 0) {
      //   v = Math.round(this.sliderValue / 5) * 5
      //   if (value != v) {
      //     this.$emit("input", v)
      //   }
      // } else {
      //   this.$emit("input", v)
      // }
      //this.hValue = v
    },
    map(value: number) {
      const inMin = (this.$refs.thumb as Element).clientHeight;
      const inMax = (this.$refs.rail as Element).getBoundingClientRect().height;
      const outMin = this.min;
      const outMax = this.max;
      const res = Math.round(
        ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
      );
      if (res > outMax) return outMax;
      if (res < outMin) return outMin;
      return res;
    },
    mapBack(value: number) {
      const outMin = (this.$refs.thumb as Element).clientHeight;
      const outMax = (this.$refs.rail as Element).getBoundingClientRect()
        .height;
      const inMin = this.min;
      const inMax = this.max;
      const res = Math.round(
        ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
      );
      if (res > outMax) return outMax;
      if (res < outMin) return outMin;
      return res;
    },
    handleResizeEvent() {
      let orientation = "portrait";
      if (window.innerHeight > window.innerWidth) {
        orientation = "portrait";
      } else {
        orientation = "landscape";
      }
      if (this.orientation != orientation) {
        this.orientation = orientation;
        // this.setThumbPosition();
      }
      this.setThumbPosition();
    },
    setThumbPosition() {
      if (this.$refs.thumb == undefined) return;
      (this.$refs.thumb as HTMLElement).style.bottom =
        this.mapBack(this.sliderValue) + "px";
      this.trackHeight =
        this.mapBack(this.sliderValue) -
        (this.$refs.thumb as HTMLElement).clientHeight / 2;
      (this.$refs.thumb as HTMLElement).style.top = "auto";
      (this.$refs.thumb as HTMLElement).style.bottom = String(
        this.mapBack(this.sliderValue)
      );
    },
    initDraggabilly() {
      this.draggable = new Draggabilly(this.$refs.thumb as Element, {
        containment: true,
        axis: "y",
      });
      (this.draggable).on("dragStart", this.dragStart);
      (this.draggable).on("dragMove", this.dragMove);
      (this.draggable).on("dragEnd", this.dragEnd);
      setTimeout(() => {
        this.setThumbPosition();
      }, 200);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initDraggabilly();
    });
  },
  created() {
    window.addEventListener("resize", this.handleResizeEvent);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResizeEvent);
  },
});
</script>

<style lang="scss">
.slider-container {
  height: 100%;
  .value {
    font-size: 8vw;
    @media screen and (min-width: 700px) {
      font-size: 7vw;
    }
    font-family: Rajdhani;
    font-weight: 500;
    height: 50px;
    width: 12vw;
    padding-bottom: 20px;
  }
  .slider {
    height: calc(100% - 60px);
    width: 30px;
    @media screen and (min-width: 700px) {
      width: 5vw;
    }
    position: relative;
    .rail {
      height: 100%;
      margin: 0 auto;
      background: linear-gradient(
          90deg,
          #747474 -23.33%,
          rgba(116, 116, 116, 0.500402) 35.94%,
          rgba(139, 139, 139, 0) 123.33%
        ),
        linear-gradient(
          90deg,
          #575757 -23.33%,
          rgba(86, 86, 86, 0.500402) 44.35%,
          rgba(71, 71, 71, 0) 123.33%
        ),
        #3f3f3f;
      border-radius: 18px;
      position: relative;
      .track {
        position: absolute;
        width: 30px;
        @media screen and (min-width: 700px) {
          width: 5vw;
        }
        border-radius: 0px 0px 18px 18px;
        bottom: 0px;
        width: 100%;
      }
    }
  }
  .slider-thumb {
    width: 50px;
    height: 75px;
    @media screen and (min-width: 700px) {
      width: 8vw;
      height: 12vw;
    }
    // background-size: contain;
    background-image: url(/img/p/thumb.svg);
    -webkit-filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.45));
    filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.45));
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}
</style>