<template>
  <div :style="'--theme-color: ' +
    $store.getters.currentThemeShade.color +
    '; --theme-gradient: ' +
    getGradient()
    ">
    <div v-show="carouselIndex == 0" style="height: 100%">
      <v-container style="height: 100%">
        <v-row class="sliderContainer" :style="'--track-box-shadow: 0px 3px 15px 0px ' +
          $store.getters.currentThemeShade.color +
          ';'
          ">
          <v-col align="center" justify="center">
            <slider :max="150" :min="1" v-model="freqValue">
              <slot>
                <div class="slider-thumb freq"></div>
              </slot>
            </slider>
            <div class="slider-item-icon">
              <icon name="freq" style="height: 10px" />
            </div>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="20" :min="0" v-model="delayValue">
              <slot>
                <div class="slider-thumb"></div>
              </slot>
            </slider>
            <div class="slider-item-icon">
              <icon name="play-pause" style="height: 27px" />
            </div>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="100" :min="0" v-model="pointsValue">
              <slot>
                <div class="slider-thumb"></div>
              </slot>
            </slider>
            <div class="slider-item-icon" @click="pointsValue = 50">
              <icon name="rocket" style="height: 27px" />
            </div>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="100" :min="1" v-model="dutyValue">
              <div class="slider-thumb duty"></div>
            </slider>
            <div class="slider-item-icon">
              <icon name="duty" style="height: 15px" />
            </div>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="240" :min="1" v-model="levelValue">
              <div class="slider-thumb level"></div>
            </slider>
            <div class="slider-item-icon">
              <icon name="level" style="height: 25px" />
            </div>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="100" :min="0" v-model="duty1Value">
              <div class="slider-thumb duty1"></div>
            </slider>
            <div class="slider-item-icon">
              <icon name="duty1" style="height: 20px" />
            </div>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="100" :min="0" v-model="duty2Value">
              <div class="slider-thumb duty2"></div>
            </slider>
            <div class="slider-item-icon">
              <icon name="duty2" style="width: 20px" />
            </div>
          </v-col>
        </v-row>
        <v-row class="bottomContainer">
          <div class="btn-container v-col">
            <div class="startBtn" :class="$store.state.pedalOn && 'active'" v-on:click="onStart">
              <img v-if="!$store.state.pedalOn" src="/i/g/play-solid.svg" />
              <img v-else src="/i/g/pause-solid.svg" />
            </div>
          </div>
          <div v-if="!($store.state.serviceMode && $store.state.calibrationToogle)" class="timer">
            <timer :active="!!$store.state.pedalOn" />
          </div>
          <div v-else class="startBtn">
            <div style="font-size: 18px; font-weight: bold;" v-on:click="onCalibrationStart">
              <div v-if="!calibrationEnabled">
                {{ $t("calibration") }}
              </div>
              <div v-else>
                {{ $t("apply") }}
              </div>
            </div>
          </div>
        </v-row>
      </v-container>
    </div>
    <!-- <keep-alive> -->
    <div v-show="carouselIndex == 1" class="sin-roller-container">
      <div class="sin-wave sin-col" v-on:click="sinReset()">
        <sinus ref="sin" :s1="s1" :s2="s2" :s3="s3" :s4="s4" />
      </div>
      <div class="sin-buttons" style="display: flex; justify-content: space-between; width: 100%;">
        <v-btn class="gear-bg sin--btn" @touchstart="sinStart(1)" @mouseleave="sinStop(1)"
          @mouseup="sinStop(1)" @touchend="sinStop(1)" @touchcancel="sinStop(1)" @contextmenu.prevent>
          <sinus ref="sin_1" key="sin_1" :s1="$store.state.sin1PresetS1"
            :s2="$store.state.sin1PresetS2" :s3="$store.state.sin1PresetS3" :s4="$store.state.sin1PresetS4" :sinWidth="20"
            :showAxis="false" />
        </v-btn>
        <v-btn class="gear-bg sin--btn" @touchstart="sinStart(2)" @mouseleave="sinStop(2)"
          @mouseup="sinStop(2)" @touchend="sinStop(2)" @touchcancel="sinStop(2)" @contextmenu.prevent>
          <sinus ref="sin_2" key="sin_2" :s1="$store.state.sin2PresetS1"
            :s2="$store.state.sin2PresetS2" :s3="$store.state.sin2PresetS3" :s4="$store.state.sin2PresetS4" :sinWidth="20"
            :showAxis="false" />
        </v-btn>
        <v-btn class="gear-bg sin--btn" @touchstart="sinStart(3)" @mouseleave="sinStop(3)"
          @mouseup="sinStop(3)" @touchend="sinStop(3)" @touchcancel="sinStop(3)" @contextmenu.prevent>
          <sinus ref="sin_3" key="sin_3" :s1="$store.state.sin3PresetS1"
            :s2="$store.state.sin3PresetS2" :s3="$store.state.sin3PresetS3" :s4="$store.state.sin3PresetS4" :sinWidth="20"
            :showAxis="false" />
        </v-btn>
        <v-btn class="gear-bg sin--btn" @touchstart="sinStart(4)" @mouseleave="sinStop(4)"
          @mouseup="sinStop(4)" @touchend="sinStop(4)" @touchcancel="sinStop(4)" @contextmenu.prevent>
          <sinus ref="sin_4" key="sin_4" :s1="$store.state.sin4PresetS1"
            :s2="$store.state.sin4PresetS2" :s3="$store.state.sin4PresetS3" :s4="$store.state.sin4PresetS4" :sinWidth="20"
            :showAxis="false" />
        </v-btn>
      </div>
      <div class="sin-col">
        <div class="rollers" :style="cssVars">
          <rollerPickerBox class="rotor1-box">
            <rollerPicker ref="rotor1" :key="r1Key" class="rotor1" :data="values" :index="r1Index" @change="(index, final) => {rollerOnChange(1, index, final)}" :layer="false" :options="options"></rollerPicker>
          </rollerPickerBox>
          <rollerPickerBox class="rotor2-box">
            <rollerPicker ref="rotor2" :key="r2Key" class="rotor2" :data="values" :index="r2Index" @change="(index, final) => {rollerOnChange(2, index, final)}" :layer="false" :options="options"></rollerPicker>
          </rollerPickerBox>
          <rollerPickerBox class="rotor3-box">
            <rollerPicker ref="rotor3" :key="r3Key" class="rotor3" :data="values" :index="r3Index" @change="(index, final) => {rollerOnChange(3, index, final)}" :layer="false" :options="options"></rollerPicker>
          </rollerPickerBox>
          <rollerPickerBox class="rotor4-box">
            <rollerPicker ref="rotor4" :key="r4Key" class="rotor4" :data="values" :index="r4Index" @change="(index, final) => {rollerOnChange(4, index, final)}" :layer="false" :options="options"></rollerPicker>
          </rollerPickerBox>
        </div>
      </div>
    </div>
    <!-- </keep-alive> -->
  </div>
</template>

<script lang="ts">

import Vue from "vue";
import SphereScroll from "../SphereScroll.vue";
import sinus from "../sinus.vue";
import { GuruMixin } from "./GuruMixin";
import { GradientMixin } from "./GradientMixin";
import Icon from "../icons/Icon.vue";

export default Vue.extend({
  components: { sinus, Icon, SphereScroll },
  name: "GuruBlack",
  mixins: [GuruMixin, GradientMixin],
  computed: {
    cssVars() {
      return {
      "--color": this.$store.getters.currentThemeShade.color,
      }
    }
  }
});
</script>

<style lang="scss">
.sin--btn {
  margin: 6px;

  canvas {
    width: 35px;
  }
}

@media (orientation: portrait) {
  .sin--btn {
    margin-top: 2vh;
  }
}



.theme--dark.v-application.guru {
  background: #121212de;
}

.theme-guru-black {
  .sin-col {
    display: flex;
    justify-content: center;
  }

  @media (orientation: landscape) {
    .sin-btn-container {
      display: none;
    }
  }

  .roller-picker-item {
      font-size: 25px;
      color: var(--color)
  }

  .rollers {
    margin-top: 20px;
    .rotor-container .yd-item .listItem {
      color: var(--color);
      opacity: 0.5;
      padding-bottom: 1px;
      &.selected {
        opacity: 1;
      }
    }
    @media screen and (max-width: 700px) {
      margin-top: -15px!important;
    }
  }
  .sin-wave {
    display: flex;
    justify-content: center;
  }

  .sin-buttons {
    margin-top: 3vh;
    position: relative;
    z-index: 4;
    .sin--btn canvas{
      transform: rotate(180deg);
    }
    @media screen and (min-width: 700px) {
      margin-top: 8vh;
    }

    @media screen and (min-width: 700px) {
      margin-top: 12vh;
    }
  }

  .slider-container {
    @media screen and (min-width: 700px) {
      .slider {
        height: calc(100% - 8vh);
        margin-top: 2vh;
      }
    }

    .slider-thumb {
      transform: translate(-50%, -50%);
    }
  }

  @media screen and (min-width: 700px) {
    

    .sin-wave {
      canvas {
        transform: scale(1.7);
      }
    }

    .slider-item-icon {
      margin-top: 4vh !important;

      svg {
        height: 4vh !important;
        width: 4vh !important;
      }
    }

    .sliderContainer {
      height: calc(100% - 9vh) !important;
      margin-top: 10px;
    }

    .bottomContainer {
      height: 7vh !important;

      .startBtn {
        img {
          height: 3vh !important;
        }
      }
    }

    .brain-bg {
      svg {
        height: 6vw;
        width: 6vw;
      }
    }

    .gear-bg {
      .sin {
        canvas {
            transform: scale(1.7);
        }
        .slider-icon svg{
          margin: 0px -15px 0px 0vh;
        }
      }

      svg {
        height: 6vw;
        width: 6vw;
      }
    }
    .sin-buttons {
        z-index: 10000;
        position: relative;
        transform: scale(1.7);
        margin-top: 7vh;
        width: 45vw;
        margin: 12vw auto;
    }
    .rollers {
        transform: scale(1.7);
        padding-top: 45px;
        width: 45vw;
    }

    // .sin-btn {
    //   padding: 3vw 0vw 0vw 0vw;
    // }
  }

  @media screen and (min-width: 1000px) {
    .gear-bg {
        svg {
        margin: 0px 35px 20px 20px;
      }
      .sin .slider-icon svg{
        margin: 0px -15px 0px 0vh;
      }
    }
    .brain-bg svg {
        margin: 0px 35px 20px 55px;
    }
    .sin-wave canvas {
        transform: scale(2);
    }
    .sin-buttons {
        margin-top: 12vh;
    }
  }

  @media (orientation: portrait) {
    .sin-roller-container {
      margin-top: 8vh;
    }
  }

  @media (orientation: landscape) {
    .sin-roller-container {
      display: flex;
      align-items: center;
      height: 60vh;

      .roller-picker {
        width: 40vw;
        margin-top: 15vh;
        margin-left: 5vw;
        height: 50vh;

        &.sin-picker .select-wrap {
          height: 50vh;
          font-size: 4vw;
        }

        .highlight {
          font-size: 4vw;
        }
      }
    }

    .sin-wave {
      margin-top: 15vh;
    }
  }

  &.v-application .presets .preset {
    font-size: 20px;
  }

  .activeItem .preset div {
    font-weight: bold;
    color: #fff !important;
  }

  .highlight {
    border-top: 1px solid var(--theme-color);
    border-bottom: 1px solid var(--theme-color);
  }

  &.v-application.theme--light {
    background: none;
    background-color: #fff !important;
  }

  .gear-bg {
    background-image: none;

    canvas {
      width: 37px;
    }
  }

  .gear-bg.active {
    background-image: none;
  }

  .brain-bg {
    background-image: none;

    &.active {
      background-image: none;
    }
  }

  .slider-icon {
    transform: rotate(270deg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2px;

    svg {
      height: 30px;

      @media screen and (min-width: 700px) {
        height: 5vw;
      }
    }
  }

  .sin-btn {
    display: flex;
    justify-content: center;
    height: 50px;
    width: 100%;
    top: 10px;
    left: 0%;

    .sin {
      align-self: center;
    }
  }

  .presets.v-navigation-drawer {
    background-size: cover;

    .preset {
      background: none !important;
      box-shadow: none !important;
      font-family: initial !important;
    }

    .icon-bg {
      background-image: none !important;
    }

    .container-main .preset {
      width: 100%;

      .v-list-item-group {
        display: initial;
      }
    }

    .v-list-item-group {
      display: inline;
    }
  }

  .presets {
    .icon-dialog {
      background: #3c5261;

      .container {
        background: #323c46;

        .item-bg {
          background-image: none;
        }
      }
    }
  }

  .lockScreen {
    background: linear-gradient(220.86deg,
        #727272 -5.86%,
        rgba(112, 111, 111, 0) 89.41%),
      #272727;
  }

  @media (orientation: portrait) {
    .rollers {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  }

  background-size: cover;

  .sliderContainer {
    height: calc(100% - 50px);

    .col {
      padding: 0px;
    }
  }

  .slider-container {
    height: 90%;

    .value {
      font-family: "Myriad Pro", "Open Sans", sans-serif;
      font-size: calc(2vw + 2vh);
      background: var(--theme-gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      height: auto;
    }

    .slider {
      margin-top: 10px;

      .rail {
        width: calc(1vh + 1vw);
        -webkit-box-shadow: inset 1px 0px 5px 0px black;
        box-shadow: inset 1px 0px 5px 0px black;
        background: none;

        .track {
          width: calc(0.6vw + 0.6vh) !important;
          margin-left: calc(0.2vw + 0.2vh);
          box-shadow: var(--track-box-shadow);
          background: var(--theme-gradient) !important;
        }
      }
    }

    .slider-thumb {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(/i/g/thumb.png);
      margin-left: -9px;
      width: calc(3vh + 3vw);
      height: calc(3vh + 3vw);
      margin-left: 0px;
      box-shadow: 6px 0px 28px -6px rgb(0 0 0 / 75%);
      border-radius: 50%;

      svg {
        margin-bottom: -2px;
        // margin-right: 2px;
      }

      &.freq svg {
        margin-left: 1px;
        width: 30px;
      }

      &.duty svg {
        margin-left: 0px;
        width: 30px;
      }

      &.level svg {
        margin-left: -2px;
        height: 35px;
        width: 30px;
      }

      &.duty1 svg {
        height: 35px;
        width: 25px;
      }

      &.duty2 svg {
        margin-left: 0px;
        height: 35px;
        width: 25px;
      }
    }
  }

  .bottomContainer {
    height: 50px;
    position: absolute;
    bottom: 4vh;
    width: 85%;
    display: flex;
    justify-content: space-between;

    .col {
      display: flex;
    }

    .btn-container {
      display: flex;
    }

    .startBtn {
      width: calc(10vh + 15vw);
      background-size: contain;
      display: flex;
      justify-content: center;
      height: calc(3vh + 3vw);
      align-self: center;
      align-items: center;
      background-image: var(--theme-gradient);
      border-radius: 70vh;

      &.active {
        // background-image: url(/img/professional-screen/start-btn-active.svg);
        // height: 55px;
        filter: none;
      }

      img {
        height: 20px;
      }
    }

    .timer {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      div {
        font-size: calc(3vh + 2vw);
        background: var(--theme-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
      }
    }
  }

  .slider-item-icon {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
  }

  .slider-thumb {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-bottom: 5px;
    }

    &.freq svg {
      margin-left: 2px;
      width: 30px;
    }

    &.duty svg {
      margin-left: 2px;
      width: 30px;
    }

    &.level svg {
      height: 35px;
      width: 30px;
    }

    &.duty1 svg {
      height: 35px;
      width: 25px;
    }

    &.duty2 svg {
      margin-left: 3px;
      height: 35px;
      width: 25px;
    }
  }

  @media screen and (orientation: landscape) {

    .brain-bg svg,
    .gear-bg svg {
      height: 4vw;
      width: 4vw;
    }

    .slider-container {
      .slider {
        height: calc(100% - 12vh);
      }
    }

    .sin-roller-container {
      .roller-picker {
        position: relative;
        right: -9vw;

        @media screen and (max-width: 750px) {
          right: 0vw;
        }
      }
    }

    @media screen and (max-width: 750px) {

      .sin-wave canvas,
      .sin-buttons {
        transform: none;
      }
    }

    @media screen and (device-aspect-ratio: 16/9) {
     
      .gear-bg svg,
      .brain-bg svg {
        height: 5vh;
        width: 5vh;
      }

      .gear-bg .sin canvas {
        transform: scale(1.4);
      }

      .slider-container {
        .value {
          font-size: calc(1vw + 2vh);
        }

        .slider-thumb {
          width: calc(3vh + 2vw);
          height: calc(3vh + 2vw);
        }
      }

      .bottomContainer {
        bottom: 6vh;

        .startBtn {
          height: calc(3vh + 2vw);
          width: calc(10vh + 10vw);
        }

        .timer div {
          font-size: calc(2vh + 2vw);
        }
      }

      .sliderContainer {
        .slider-item-icon {
          margin-top: 1vh !important;
        }
      }
    }
  }
}
</style>