<template>
  <svg
    width="78"
    height="36"
    viewBox="0 0 78 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 18.4639C7.74786 17.3041 17.4594 10.7319 19.5408 8.60563C21.6222 6.47933 26.0817 2.22675 30.3431 2.22675C34.6045 2.22675 37.1164 3.74605 38.2713 4.83643C39.2043 5.71725 40.6119 5.68584 41.4829 4.83643C42.2707 4.06811 44.6271 2.22675 49.2701 2.22675C52.6189 2.22675 56.1872 6.01646 59.6743 8.60563C63.54 11.8917 72.2768 18.4639 76.2993 18.4639"
      :stroke="color"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M2.36865 18.4642C27.1371 32.429 30.8526 34.1145 38.5307 34.1145C46.2089 34.1145 51.3188 34.1145 75.9312 18.4642"
      :stroke="color"
      stroke-width="3"
    />
    <path
      d="M3.00969 17.0087C2.18854 16.8991 1.43404 17.4759 1.32446 18.2971C1.21488 19.1182 1.79172 19.8727 2.61287 19.9823L3.00969 17.0087ZM74.8819 20.3692C75.7081 20.3088 76.3289 19.59 76.2685 18.7638C76.2081 17.9376 75.4894 17.3167 74.6632 17.3771L74.8819 20.3692ZM2.61287 19.9823C9.76739 20.937 15.9476 20.5585 20.7319 19.9456C23.1201 19.6397 25.1659 19.2744 26.7862 18.9914C28.4717 18.697 29.5554 18.5211 30.1959 18.5211V15.5211C29.231 15.5211 27.8362 15.7626 26.27 16.0361C24.6388 16.321 22.66 16.6741 20.3507 16.9699C15.7399 17.5606 9.83316 17.9192 3.00969 17.0087L2.61287 19.9823ZM30.1959 18.5211C31.584 18.5211 32.8514 18.8375 34.2896 19.2103C35.6806 19.5709 37.2666 19.9955 39.0125 19.9955V16.9955C37.7122 16.9955 36.4955 16.6829 35.0424 16.3063C33.6365 15.9418 32.0187 15.5211 30.1959 15.5211V18.5211ZM39.0125 19.9955C40.728 19.9955 42.587 19.5833 44.2513 19.2246C45.9953 18.8487 47.5649 18.5211 48.9814 18.5211V15.5211C47.1872 15.5211 45.2954 15.9307 43.6192 16.292C41.8631 16.6705 40.343 16.9955 39.0125 16.9955V19.9955ZM48.9814 18.5211C49.5802 18.5211 50.3102 18.6701 51.5291 18.9539C52.7026 19.2271 54.2103 19.5921 56.1815 19.9141C60.1381 20.5605 65.902 21.0256 74.8819 20.3692L74.6632 17.3771C65.8894 18.0185 60.3631 17.5575 56.6652 16.9534C54.8092 16.6502 53.3987 16.3089 52.2093 16.032C51.0654 15.7657 49.988 15.5211 48.9814 15.5211V18.5211Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "lips",
  mixins: [IconMixin],
});
</script>
