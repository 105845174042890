<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 76 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      d="M0.861443 21.1939C0.322099 21.8227 0.394623 22.7697 1.02343 23.3091C1.65224 23.8484 2.59921 23.7759 3.13856 23.1471L0.861443 21.1939ZM72.8955 22.0196C73.4561 22.6296 74.405 22.6696 75.015 22.1091C75.625 21.5485 75.665 20.5996 75.1044 19.9897L72.8955 22.0196ZM3.13856 23.1471C9.82489 15.3517 19.5213 6.62402 31.3302 4.03444C42.9751 1.48084 57.0867 4.81729 72.8955 22.0196L75.1044 19.9897C58.7704 2.21578 43.632 -1.73449 30.6876 1.10407C17.9073 3.90666 7.67511 13.2501 0.861443 21.1939L3.13856 23.1471Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.0001 30.0267C45.953 30.0267 52.4001 23.5796 52.4001 15.6267C52.4001 7.67384 45.953 1.22675 38.0001 1.22675C30.0472 1.22675 23.6001 7.67384 23.6001 15.6267C23.6001 23.5796 30.0472 30.0267 38.0001 30.0267ZM33.2 16.8268C35.851 16.8268 38 14.6778 38 12.0268C38 9.37583 35.851 7.22679 33.2 7.22679C30.549 7.22679 28.4 9.37583 28.4 12.0268C28.4 14.6778 30.549 16.8268 33.2 16.8268Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "eye1",
  mixins: [IconMixin],
});
</script>
