<template>
  <svg
    width="76"
    height="31"
    viewBox="0 0 76 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.2114 1.69981C14.9015 5.58715 9.07046 12.7139 0 24.376C4.53523 19.8408 25.2677 8.82657 32.3945 8.17868C39.5213 7.53079 42.7607 8.82657 46.6481 11.4181C50.5354 14.0097 55.7185 17.2768 64.1411 17.263C68.0284 17.2566 77.0989 14.0097 75.1552 14.0097C73.2116 14.0097 68.0285 13.3756 60.2538 8.17866C51.2953 2.19043 39.5213 -2.18753 27.2114 1.69981Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.3581 30.2469C41.8834 30.2469 47.9838 24.1465 47.9838 16.6212C47.9838 9.09592 41.8834 2.99548 34.3581 2.99548C26.8329 2.99548 20.7324 9.09592 20.7324 16.6212C20.7324 24.1465 26.8329 30.2469 34.3581 30.2469ZM29.8166 19.0522C32.325 19.0522 34.3585 17.0188 34.3585 14.5103C34.3585 12.0019 32.325 9.96844 29.8166 9.96844C27.3081 9.96844 25.2747 12.0019 25.2747 14.5103C25.2747 17.0188 27.3081 19.0522 29.8166 19.0522Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "eye3",
  mixins: [IconMixin],
});
</script>
