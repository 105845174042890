export const GradientMixin = {
    methods: {
        getGradient() {
            const { r, g, b } = this.hexToRgb(this.$store.getters.currentThemeShade.color);
            const { h, s, v } = this.RGBtoHSV(r, g, b);
            const [r1, g1, b1] = this.HSVtoRGB(h, s, v)
            const [r2, g2, b2] = this.HSVtoRGB(h, s - 15, v)
            const [r3, g3, b3] = this.HSVtoRGB(h, s - 30, v)
            return `linear-gradient(180deg, rgb(${r1}, ${g1}, ${b1}) 33%, rgb(${r2}, ${g2}, ${b2}) 66%, rgb(${r3}, ${g3}, ${b3}));`
        },
        RGBtoHSV(r, g, b) {
            let rabs, gabs, babs, rr, gg, bb, h, s, v, diff, diffc, percentRoundFn;
            rabs = r / 255;
            gabs = g / 255;
            babs = b / 255;
            v = Math.max(rabs, gabs, babs),
                diff = v - Math.min(rabs, gabs, babs);
            diffc = c => (v - c) / 6 / diff + 1 / 2;
            percentRoundFn = num => Math.round(num * 100) / 100;
            if (diff == 0) {
                h = s = 0;
            } else {
                s = diff / v;
                rr = diffc(rabs);
                gg = diffc(gabs);
                bb = diffc(babs);

                if (rabs === v) {
                    h = bb - gg;
                } else if (gabs === v) {
                    h = (1 / 3) + rr - bb;
                } else if (babs === v) {
                    h = (2 / 3) + gg - rr;
                }
                if (h < 0) {
                    h += 1;
                } else if (h > 1) {
                    h -= 1;
                }
            }
            return {
                h: Math.round(h * 360),
                s: Math.round(percentRoundFn(s * 100)),
                v: Math.round(percentRoundFn(v * 100))
            };
        },
        hexToRgb(hex) {
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        },
        HSVtoRGB(h, s, b) {
            s /= 100;
            b /= 100;
            const k = (n) => (n + h / 60) % 6;
            const f = (n) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)));
            return [Math.round(255 * f(5)), Math.round(255 * f(3)), Math.round(255 * f(1))];
        }
    }
}
