<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26">
    <g
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="4"
      filter="url(#filter0_i8)"
    >
      <path d="M12.596 23.192V2M2 12.596h21.192"></path>
    </g>
    <defs>
      <filter
        id="filter0_i8"
        width="26.192"
        height="27.192"
        x="-1"
        y="0"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-1" dy="2"></feOffset>
        <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
        ></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow"></feBlend>
      </filter>
    </defs>
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "duty1",
  mixins: [IconMixin],
});
</script>
