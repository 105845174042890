<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="59"
    height="75"
    viewBox="0 0 59 75"
    fill="none"
    name="trash-closed"
    class="trash-closed"
    style="zoom: 1"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.1711 37.4405C22.2412 37.6307 21.4828 38.3591 21.3003 39.2373C21.2689 39.3887 21.9628 47.0568 22.8424 56.2775C24.6301 75.0177 24.4417 73.6222 25.2855 74.3809C25.5259 74.5971 25.8866 74.823 26.0869 74.883C26.6107 75.04 47.2705 75.04 47.7943 74.883C47.9946 74.823 48.3553 74.5971 48.5957 74.3809C49.4395 73.6222 49.2511 75.0177 51.0389 56.2775C51.9185 47.0568 52.6123 39.3887 52.5809 39.2373C52.4375 38.5471 51.9931 38.002 51.2609 37.618L50.8478 37.4013L37.1701 37.388C29.6473 37.3808 23.3478 37.4044 23.1711 37.4405ZM50.5254 39.0359C50.71 39.099 50.8906 39.2427 50.9517 39.375C51.037 39.5599 50.7491 42.8369 49.4708 56.2304C48.5981 65.3747 47.8374 72.9435 47.7803 73.0501C47.7232 73.1566 47.5977 73.286 47.5012 73.3377C47.2579 73.4679 26.6233 73.4679 26.38 73.3377C26.2835 73.286 26.158 73.1566 26.1009 73.0501C26.0439 72.9435 25.2831 65.3747 24.4104 56.2304C23.1321 42.8369 22.8442 39.5599 22.9296 39.375C22.9906 39.2427 23.1712 39.099 23.3558 39.0359C23.8183 38.8776 50.0629 38.8776 50.5254 39.0359ZM30.9238 47.0359C30.767 47.1926 30.6984 47.363 30.6984 47.5956C30.6984 47.7794 31.0512 52.1285 31.4824 57.2601C32.0831 64.4094 32.2988 66.6399 32.4052 66.8023C32.7137 67.2731 33.3109 67.2761 33.66 66.8086C33.8306 66.58 33.8223 66.4486 33.0426 57.0839C32.6079 51.8626 32.2326 47.499 32.2086 47.3868C32.0886 46.8263 31.3382 46.6213 30.9238 47.0359ZM41.9161 46.9967C41.8062 47.099 41.6963 47.2746 41.6719 47.3868C41.6475 47.499 41.2722 51.8626 40.8379 57.0839C40.059 66.4482 40.0507 66.5801 40.2212 66.8086C40.5704 67.2764 41.1675 67.2731 41.4763 66.8018C41.5831 66.6387 41.7974 64.419 42.3991 57.2403C42.8301 52.0983 43.1828 47.7494 43.1828 47.5762C43.1828 46.9266 42.3836 46.561 41.9161 46.9967Z"
      :fill="color"
    ></path>
    <path
      d="M44.3659,15.6865 C43.6361,15.333 42.8305,15.3098 42.2088,15.6244 C42.0492,15.7053 41.2143,16.4247 40.3534,17.2232 L38.7882,18.6751 L35.8269,15.4826 C34.0925,13.6128 32.7739,12.2447 32.6441,12.1805 C32.3668,12.0432 31.8437,12.1781 31.7001,12.4239 C31.6416,12.5242 31.5413,12.7691 31.5287,12.9225 L31.5479,13.1643 L41.8891,24.3128 L52.2303,35.4613 L52.5103,35.4595 C52.6643,35.4585 52.8757,35.416 52.98,35.3652 C53.2359,35.2404 53.4096,34.7289 53.2935,34.442 C53.2392,34.3079 51.9754,32.8918 50.2434,31.0246 L47.2861,27.8364 L48.9659,26.2391 C50.82,24.4762 50.8438,24.4429 50.8767,23.5682 C50.9129,22.6065 51.0142,22.7472 47.6512,19.0932 C45.4459,16.6972 44.5635,15.7823 44.3659,15.6865 M49.2968,23.3174 C49.3398,23.4724 49.3434,23.6634 49.3048,23.742 C49.2662,23.8206 48.5556,24.5147 47.7258,25.2844 L46.2171,26.6839 L43.0332,23.2515 L39.8494,19.8192 L41.3682,18.4104 C42.9497,16.9434 43.1299,16.8223 43.4834,16.9886 C43.591,17.0393 44.9255,18.4206 46.4489,20.0582 C48.7631,22.5459 49.2315,23.0821 49.2968,23.3174 "
      fill-rule="evenodd"
      :fill="color"
      clip-rule="evenodd"
      visibility="hidden"
    ></path>
    <rect
      x="0.770996"
      height="10"
      y="2.44919"
      width="10"
      rx="1"
      :fill="color"
      transform="rotate(-8.81603 0.770996 2.44919)"
      visibility="hidden"
    ></rect>
    <rect
      x="15.4697"
      height="6"
      y="5.33752"
      width="6"
      rx="1"
      :fill="color"
      transform="rotate(-13.6993 15.4697 5.33752)"
      visibility="hidden"
    ></rect>
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "trash",
  mixins: [IconMixin],
});
</script>
