<template>
  <div :style="'--theme-gradient: ' + getGradient()">
    <div v-show="carouselIndex == 0" style="height: 100%">
      <!-- <div v-if="!$store.state.lockInterface" class="gear-bg sin-btn">
        <div class="sin" v-on:click="carouselIndex = 1">
          <sinus
            ref="sin"
            :s1="s1"
            :s2="s2"
            :s3="s3"
            :s4="s4"
            :sinWidth="20"
            :showAxis="false"
            :color="$store.getters.currentThemeShade.color"
          />
        </div>
      </div> -->
      <v-container style="height: 100%">
        <v-row class="sliderContainer">
          <v-col align="center" justify="center">
            <slider :max="150" :min="1" v-model="freqValue" :color="$store.getters.currentThemeShade.color"
              class="slider1">
              <slot>
                <div class="slider-thumb freq">
                  <icon name="freq" />
                </div>
              </slot>
            </slider>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="100" :min="1" v-model="dutyValue" :color="$store.getters.currentThemeShade.color"
              class="slider2">
              <div class="slider-thumb duty">
                <icon name="duty" />
              </div>
            </slider>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="240" :min="1" v-model="levelValue" :color="$store.getters.currentThemeShade.color"
              class="slider3">
              <div class="slider-thumb level">
                <icon name="level" />
              </div>
            </slider>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="100" :min="0" v-model="duty1Value" :color="$store.getters.currentThemeShade.color"
              class="slider4">
              <div class="slider-thumb duty1">
                <icon name="duty1" />
              </div>
            </slider>
          </v-col>
          <v-col align="center" justify="center">
            <slider :max="100" :min="0" v-model="duty2Value" :color="$store.getters.currentThemeShade.color"
              class="slider5">
              <div class="slider-thumb duty2">
                <icon name="duty2" />
              </div>
            </slider>
          </v-col>
        </v-row>
        <v-row class="bottomContainer">
          <v-col class="v-col">
            <div v-on:click="onStart">
              <icon name="start-btn-dark" :active="$store.state.pedalOn" :class="$store.state.pedalOn && 'active'" />
            </div>
          </v-col>
          <v-col class="timer" :style="{ color: $store.getters.currentThemeShade.color }">
            <timer :active="$store.state.pedalOn" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- <keep-alive> -->
    <div v-show="carouselIndex == 1" class="sin-roller-container">
      <div class="sin-wave sin-col" v-on:click="sinReset()">
        <sinus ref="sin" :s1="s1" :s2="s2" :s3="s3" :s4="s4" />
      </div>
      <div class="sin-buttons" style="display: flex; justify-content: space-between; width: 100%;">
        <v-btn class="gear-bg sin--btn" @touchstart="sinStart(1)" @mouseleave="sinStop(1)"
          @mouseup="sinStop(1)" @touchend="sinStop(1)" @touchcancel="sinStop(1)" @contextmenu.prevent>
          <sinus ref="sin_1" key="sin_1" :s1="$store.state.sin1PresetS1"
            :s2="$store.state.sin1PresetS2" :s3="$store.state.sin1PresetS3" :s4="$store.state.sin1PresetS4" :sinWidth="20"
            :showAxis="false" />
        </v-btn>
        <v-btn class="gear-bg sin--btn" @touchstart="sinStart(2)" @mouseleave="sinStop(2)"
          @mouseup="sinStop(2)" @touchend="sinStop(2)" @touchcancel="sinStop(2)" @contextmenu.prevent>
          <sinus ref="sin_2" key="sin_2" :s1="$store.state.sin2PresetS1"
            :s2="$store.state.sin2PresetS2" :s3="$store.state.sin2PresetS3" :s4="$store.state.sin2PresetS4" :sinWidth="20"
            :showAxis="false" />
        </v-btn>
        <v-btn class="gear-bg sin--btn" @touchstart="sinStart(3)" @mouseleave="sinStop(3)"
          @mouseup="sinStop(3)" @touchend="sinStop(3)" @touchcancel="sinStop(3)" @contextmenu.prevent>
          <sinus ref="sin_3" key="sin_3" :s1="$store.state.sin3PresetS1"
            :s2="$store.state.sin3PresetS2" :s3="$store.state.sin3PresetS3" :s4="$store.state.sin3PresetS4" :sinWidth="20"
            :showAxis="false" />
        </v-btn>
        <v-btn class="gear-bg sin--btn" @touchstart="sinStart(4)" @mouseleave="sinStop(4)"
          @mouseup="sinStop(4)" @touchend="sinStop(4)" @touchcancel="sinStop(4)" @contextmenu.prevent>
          <sinus ref="sin_4" key="sin_4" :s1="$store.state.sin4PresetS1"
            :s2="$store.state.sin4PresetS2" :s3="$store.state.sin4PresetS3" :s4="$store.state.sin4PresetS4" :sinWidth="20"
            :showAxis="false" />
        </v-btn>
      </div>
      <div class="sin-col">
        <div class="rollers" :style="cssVars">
          <rollerPickerBox class="rotor1-box">
            <rollerPicker ref="rotor1" :key="r1Key" class="rotor1" :data="values" :index="r1Index" @change="(index, final) => {rollerOnChange(1, index, final)}" :layer="false" :options="options"></rollerPicker>
          </rollerPickerBox>
          <rollerPickerBox class="rotor2-box">
            <rollerPicker ref="rotor2" :key="r2Key" class="rotor2" :data="values" :index="r2Index" @change="(index, final) => {rollerOnChange(2, index, final)}" :layer="false" :options="options"></rollerPicker>
          </rollerPickerBox>
          <rollerPickerBox class="rotor3-box">
            <rollerPicker ref="rotor3" :key="r3Key" class="rotor3" :data="values" :index="r3Index" @change="(index, final) => {rollerOnChange(3, index, final)}" :layer="false" :options="options"></rollerPicker>
          </rollerPickerBox>
          <rollerPickerBox class="rotor4-box">
            <rollerPicker ref="rotor4" :key="r4Key" class="rotor4" :data="values" :index="r4Index" @change="(index, final) => {rollerOnChange(4, index, final)}" :layer="false" :options="options"></rollerPicker>
          </rollerPickerBox>
        </div>
      </div>
    </div>
    <!-- </keep-alive> -->
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import sinus from "../sinus.vue";
import { ProfessionalMixin } from "./ProfessionalMixin";
import { GradientMixin } from "./GradientMixin";
import Icon from "../icons/Icon.vue";

export default Vue.extend({
  components: { sinus, Icon },
  name: "ProfessionalBlack",
  mixins: [ProfessionalMixin, GradientMixin],
});
</script>

<style lang="scss" >
.roller-picker {
  &.sin-picker {
    .select-wrap {
      height: 24vh;
      .highlight {
        ul li {
          opacity: 1;
        }
      }
      ul li {
        opacity: 0.7;
      }
    }
  }
}
.theme-professional-black {

  .rotor-container .yd-item .listItem {
      color: var(--color);
      opacity: 0.5;
      padding-bottom: 1px;
      &.selected {
        opacity: 1;
      }
    }

    .roller-picker-item {
      font-size: 25px;
      color: var(--color)
    }

  
  .sin {
    canvas {
      @media screen and (min-width: 700px) {
        transform: scale(1.2);
      }
      @media screen and (min-width: 1000px) {
        transform: scale(1.6);
      }
    }
  }
  .sin-buttons {
    position: relative;
    z-index: 4;
    @media screen and (min-width: 700px) {
      transform: scale(1.7);
      margin: 7vh auto;
      .sin--btn {
        margin: 1.4vw;
      }
    }
  }
  .gear-bg {
    &.sin--btn {
      background-color: initial !important;
      box-shadow: none;
      height: 50px !important;
      width: 50px !important;
      min-width: 50px;
    }
    canvas {
      transform: rotate(180deg);
      width: 37px;
    }
    
  }

  .slider-icon {
    transform: rotate(270deg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -2px;
    @media screen and (orientation: landscape) {
      margin: -3px 0px 2px 2px;
    }
    svg {
      height: 30px;
      @media screen and (min-width: 700px) {
        height: 5vw;
      }
    }
  }
  .sin-wave {
    display: flex;
    justify-content: center;
  }
  .sin-btn {
    display: flex;
    justify-content: center;
    height: 49px;
    width: 100%;
    position: auto;
    @media screen and (min-width: 700px) {
      height: 8vw;
    }
    .sin {
      align-self: center;
    }
  }
  .presets {
    background: linear-gradient(
        220.86deg,
        #727272 -5.86%,
        rgba(112, 111, 111, 0) 89.41%
      ),
      #272727 !important;
    background-size: cover;
    .container-main .preset {
      width: 100%;
      .v-list-item-group {
        display: initial;
      }
    }
    .v-list-item-group {
      display: inline;
    }
  }
  .lockScreen {
    background: linear-gradient(
        220.86deg,
        #727272 -5.86%,
        rgba(112, 111, 111, 0) 89.41%
      ),
      #272727;
  }
  @media (orientation: portrait) {
    .rollers {
      display: flex;
      justify-content: space-around;
      margin-top: 25px;
    }
  }
  
  @media screen and (max-width: 700px) {
    .rollers {
      margin-top: -15px!important;
    }
  }

  &.v-application {
    background: linear-gradient(
        220.86deg,
        #727272 -5.86%,
        rgba(112, 111, 111, 0) 89.41%
      ),
      #272727 !important;
  }
  background-size: cover;
  .sliderContainer {
    height: calc(100% - 10vh);
  }
  .slider-container {
    .value {
      height: 8vh;
    }
    .slider {
      height: calc(100% - 10vh);
      .track {
        background: var(--theme-gradient) !important;
      }
    }
    &.slider1 .slider {
      .rail {
        background: linear-gradient(
            90deg,
            #5b5b5b -23.33%,
            rgba(77, 77, 77, 0.500402) 44.35%,
            rgba(50, 50, 50, 0) 123.33%
          ),
          #3f3f3f;
      }
    }
    &.slider2 .slider {
      .rail {
        background: linear-gradient(
            90deg,
            #626161 -23.33%,
            rgba(87, 87, 87, 0.500402) 44.35%,
            rgba(71, 71, 71, 0) 123.33%
          ),
          #3f3f3f;
      }
    }
    &.slider3 .slider {
      .rail {
        background: linear-gradient(
            90deg,
            rgba(114, 114, 114, 0.5) -23.33%,
            rgba(102, 102, 102, 0.250201) 45.11%,
            rgba(82, 82, 82, 0) 123.33%
          ),
          linear-gradient(
            90deg,
            #575757 -23.33%,
            rgba(86, 86, 86, 0.500402) 44.35%,
            rgba(71, 71, 71, 0) 123.33%
          ),
          #3f3f3f;
      }
    }
    &.slider4 .slider {
      .rail {
        background: linear-gradient(
            90deg,
            rgba(112, 112, 112, 0.5) -23.33%,
            rgba(116, 116, 116, 0.250201) 50.46%,
            rgba(149, 149, 149, 0) 123.33%
          ),
          linear-gradient(
            90deg,
            #636363 -23.33%,
            rgba(86, 86, 86, 0.500402) 62.68%,
            rgba(71, 71, 71, 0) 123.33%
          ),
          #3f3f3f;
      }
    }
    &.slider5 .slider {
      .rail {
        background: linear-gradient(
            90deg,
            #747474 -23.33%,
            rgba(116, 116, 116, 0.500402) 35.94%,
            rgba(139, 139, 139, 0) 123.33%
          ),
          linear-gradient(
            90deg,
            #575757 -23.33%,
            rgba(86, 86, 86, 0.500402) 44.35%,
            rgba(71, 71, 71, 0) 123.33%
          ),
          #3f3f3f;
      }
    }
    .slider-thumb {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(/i/p/thumb.svg);
      position: inherit;
      svg {
        margin-bottom: -2px;
        // margin-right: 2px;
      }
      &.freq svg {
        margin-left: 1px;
        width: 30px;
        @media screen and (min-width: 700px) {
          height: 3vh;
          width: 4vh;
        }
      }
      &.duty svg {
        margin-left: 0px;
        width: 30px;
        @media screen and (min-width: 700px) {
          height: 3vh;
          width: 4vh;
        }
      }
      &.level svg {
        margin-left: -2px;
        height: 35px;
        width: 30px;
        @media screen and (min-width: 700px) {
          height: 4vh;
          width: 7vh;
        }
      }
      &.duty1 svg {
        height: 35px;
        width: 25px;
        @media screen and (min-width: 700px) {
          height: 3vh;
          width: 7vh;
        }
      }
      &.duty2 svg {
        margin-left: 0px;
        height: 35px;
        width: 25px;
        @media screen and (min-width: 700px) {
          height: 3vh;
          width: 3vh;
        }
      }
    }
  }
  .bottomContainer {
    .v-col {
      align-items: center;
      display: flex;
    }
    .start-btn-dark {
      svg {
        height: 7vh;
        width: auto;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        // svg {
        //   height: 4vh;
        //   margin-top: calc(0.5vh + 0.5vw);
        // }
      }
      &.active {
        filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.2));
      }
      &:not(.active) {
        filter: drop-shadow(-1px 3px 3px rgba(0, 0, 0, 0.7));
      }
    }
    .timer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 34px;
      font-family: Rajdhani;
      font-weight: 600;
      div {
        font-size: 8vw;
        width: 28vw;
      }
    }
  }
  .slider-thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-bottom: 5px;
    }
    &.freq svg {
      margin-left: 2px;
      width: 30px;
    }
    &.duty svg {
      margin-left: 2px;
      width: 30px;
    }
    &.level svg {
      height: 35px;
      width: 30px;
    }
    &.duty1 svg {
      height: 35px;
      width: 25px;
    }
    &.duty2 svg {
      margin-left: 3px;
      height: 35px;
      width: 25px;
    }
  }
  @media screen and (min-width: 700px) {
    .sin-wave {
      canvas {
        transform: scale(1.7);
      }
    }
    .sin-buttons {
        z-index: 5;
        position: relative;
        transform: scale(1.7);
        margin-top: 7vh;
        width: 45vw;
        margin: 12vw auto;
    }
    .rollers {
        transform: scale(1.7);
        padding-top: 45px;
    }
  }
  @media screen and (min-width: 1000px) {
    .sin-wave {
      canvas {
        transform: scale(2);
      }
    }
  }
  @media screen and (orientation: landscape) {
    .brain-bg svg
    .gear-bg .sin canvas {
      transform: scale(1.2) rotate(180deg);
    }
    .sin-btn {
      height: 5vw;
    }
    .slider-container {
      .value {
        font-size: 4vw;
      }
      .slider {
        width: 3vw;
        .rail {
          .track {
            width: 3vw;
          }
        }
      }
      .slider-thumb {
        width: 5vw;
        height: 8vw;
      }
    }
    .sin-buttons {
      width: 25vw;
      margin: 7vw auto;
      transform: scale(1.3);
    }
    .roller-picker {
      width: 40vw;
      margin-top: auto;
    }
    .bottomContainer {
      .timer {
        div {
          font-size: 6vw;
          width: 21vw;
        }
      }
    }
  }
  @media (orientation: portrait) {
    .sin-roller-container {
      margin-top: 8vh;
    }
  }
  @media (orientation: landscape) {
    .sin-roller-container {
      display: flex;
      align-items: center;
      height: 60vh;
      .roller-picker {
        width: 40vw;
        margin-top: 15vh;
        margin-left: 5vw;
        height: 50vh;
        &.sin-picker .select-wrap {
          height: 50vh;
          font-size: 4vw;
        }
        .highlight {
          font-size: 4vw;
        }
        position: relative;
        right: -9vw;
        @media screen and (max-width: 750px) {
          right: 0vw;
        }
      }
    }
    .sin-wave {
      margin-top: 15vh;
    }
    @media screen and (max-width: 750px) {
      .sin-wave canvas,
      .sin-buttons {
        transform: none;
      }
    }
    @media screen and (device-aspect-ratio: 16/9) {
      .drawer {
        padding-top: 10vh;
      }
      
      .sin-btn {
        height: 3.7vw;
        top: 2vw;
      }
      .sin-btn-container {
        display: none;
      }
      .bottomContainer .timer div {
        font-size: 8vh;
        width: 13vw;
      }
      .slider-container {
        .slider {
          width: 2vw;
          .rail {
            .track {
              width: 2vw;
            }
          }
        }
        .value {
          height: 10vh;

        }
      }
    }
  }
}
</style>
