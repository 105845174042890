<template>
  <div style="text-align: center">
    <div class="themes" v-on:click="() => {
      showFirmwareVersion = true;
      _mounted();
    }
      ">
      Spica&trade;: 2.0
      <!-- <span style="font-size: 10px"><span v-if="showFirmwareVersion">{{ -->
      <span>
        <v-progress-circular v-if="$store.state.firmwareVersion == '*'" :size="15" indeterminate
          color="primary"></v-progress-circular>
        <span v-else style="font-size: 10px">
          {{ $store.state.firmwareVersion }}</span>
      </span>
    </div>
    <div v-if="this.$store.state.useBluetooth" style="font-size: 14px; margin-top: 10px;">
      {{ $t("switchToWifi") }}
    </div>
    <div v-else-if="newVersion">
      <div v-if="!installInProgress">
        <br />
        <div>
          {{ $t("newVersion") }}
        </div>
        <div>
          <br />
          <v-btn depressed v-on:click="startDeviceUpdate">
            {{ $t("installToDevice") }}
          </v-btn>
        </div>
        <br />
      </div>
      <div v-else>
        <br />
        {{ $t("installationToDevice") }}
        <br /><br />
        {{ $t(installMessage) }}
        <br />
        <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear>
        <div v-if="updateDeviceErrors">
          <span>{{ $t("installErrors") }}</span>
          <div>
            {{ updateDeviceErrors }}
          </div>
        </div>
        <br />
      </div>
    </div>
    <!-- <div v-else-if="$cordova">
      <br />
      <span>{{ $t("lastVersion") }}</span>
    </div> -->
    <div style="font-size: 12px">
      <br />
      Copyright &copy; {{ new Date().getFullYear() }}
    </div>
  </div>
</template>

<script lang="js">
import fetchData, { axiosFetch } from "@/fetchData";
import { Capacitor } from '@capacitor/core';
import { sleep } from "@/helpers";
import Vue from "vue";

const NEW_VERSION = "1.6.31";
const UPDATE_URL = "https://spica.tattootoys.ru/update/"

export default Vue.extend({
  name: "AboutSettings",
  data() {
    return {
      showFirmwareVersion: false,
      version: "",
      newVersion: null,
      deviceVersionData: "",
      appVersionData: "",
      updateDeviceErrors: "",
      installInProgress: false,
      fileCountToUpdate: 0,
      fileNumToUpdate: 0,
      installMessage: "",
      uploadAttempts: 1,
    };
  },
  mounted() {
    if (!this.$store.state.useBluetooth) {
      this.sync();
    }
  },
  methods: {
    async getVersionFromSite() {
      const d = new Date().toISOString().slice(0, 16);
      const msgUint8 = new TextEncoder().encode(d);
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
      const resp = (await fetch(UPDATE_URL + "v.json")).json();
      return resp;
    },
    sync() {
      fetchData.post("/api", {
        n: 1, a: 1
      })
        .then(response => {
         if (response.data.v) {
          this.$store.commit('setFirmwareVersion', response.data.v)
         } else {
          this.$store.commit('setFirmwareVersion', "0")
         }
         self._mounted();
        })
        .catch(reason => {
          console.log(reason);
        });
      const self = this;
      if (this.$store.state.firmwareVersion == "*" || this.$store.state.firmwareVersion == "0") {
        setTimeout(self.sync, 1000);
      }
    },
    async upload(url, blob) {
      let response = null;
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 20 * 60 * 1000
      };
      try {
        console.log(url, "Blob Size:", blob.size);
        response = await axiosFetch.post('FILE_UPLOAD' + url, blob, config);
      } catch (e) {
        if (this.uploadAttempts < 10) {
          console.log("Error: ", e);
          console.log(url, "Attemp1:", this.uploadAttempts);
          response = await this.upload(url, blob);
          this.uploadAttempts++;
          return response
        } else {
          console.log('Error uploading 1.', e);
          this.updateDeviceErrors += 'Error uploading.'
          this.uploadAttempts = 1;
          return response
        }
      }
      if (response.data.length != blob.size) {
        if (this.uploadAttempts < 10) {
          const newBlob = blob.slice(response.data.length)
          console.log(url, "Attemp2:", this.uploadAttempts, "Blob Size: ", blob.size, "Sent: ", response.data.length, "Will be send: ", newBlob.size);
          response = await this.upload(url, newBlob);
          this.uploadAttempts++;
          return response
        } else {
          this.uploadAttempts = 1;
          return response;
        }
      }
      this.uploadAttempts = 1;
      return response
    },
    async _mounted() {

      fetch("/app-version.json")
        .then(response => response.json())
        .then((appResponse) => {
          this.appVersionData = appResponse;
        })
        .catch((error) => {
          console.log("GET app-version.json ERROR", error);
        });
      
        if (Capacitor.isNativePlatform()) {
          if (this.$store.state.firmwareVersion == "*") {
            this.$store.state.firmwareVersion = "0";
          }
          console.log("_VERSIONS: ", parseInt(this.$store.state.firmwareVersion.replaceAll(".", "")), parseInt(NEW_VERSION.replaceAll(".", "")));
          if (parseInt(this.$store.state.firmwareVersion.replaceAll(".", "")) < parseInt(NEW_VERSION.replaceAll(".", ""))) {
            this.newVersion = NEW_VERSION;
          }
          if (this.$store.state.firmwareVersion == "0") {
            this.$store.state.firmwareVersion = "*";
          }
        }
      // }
    },
    async uploadFile(key, chunks) {
      chunks.unshift(key);
      let i = 0;
      if (this.installMessage != 'finishing') {
        this.installMessage = `${this.fileNumToUpdate} / ${this.fileCountToUpdate}`
      }
      for (const chunk of chunks) {
        try {
          let response = await axiosFetch({
            url: 'update' + chunk,
            responseType: 'blob',
            baseURL: ""
          });

          let url = key
          if (i > 0) {
            url = url + '.chunk'
          }
          if (key != "/firmware.bin") {
            url = '/dist' + url
            const blob = await new Blob();
            response = await this.upload(url, blob);  
          }
          const blob = await new Blob([response.data]);
          await sleep(100);
          response = await this.upload(url, blob)
          if (!response) return;
          this.fileNumToUpdate++;
          this.installMessage = `${this.fileNumToUpdate} / ${this.fileCountToUpdate}`
        } catch (error) {
          console.log("Upload Error:", key, error);
          this.updateDeviceErrors += error + '\n' + key + '\n'
        }
        i++;
      }
    },

    async startDeviceUpdate() {
      this.installInProgress = true;
      const filesToUpdate = [];
      let hasFirmware = false;
      this.fileCountToUpdate = 0;
      for (const [key, value] of Object.entries(this.appVersionData.files)) {
        if (key != "/firmware.bin") continue;
        filesToUpdate.push({ key: key, chunks: value.chunks });
        this.fileCountToUpdate++;
        if (value.chunks) {
          this.fileCountToUpdate += value.chunks.length;
        }
      }
      this.fileNumToUpdate = 1;
      console.log("UPLOAD FILES......2");
      for (const file of filesToUpdate) {
        if (file.key == '/firmware.bin') {
          hasFirmware = true;
        }
        console.log("=", file.key);
        await this.uploadFile(file.key, file.chunks ? file.chunks : [])
      }
      this.installMessage = 'finishing';
      if (this.updateDeviceErrors.length === 0) {
        if (hasFirmware) {
          this.installMessage = 'installingFirmware';
          setTimeout(() => {
            this.installMessage = 'finished';
          }, 20000);
          await fetchData.get('/flash_firmware');
          // try {
          //   if (response.data.status === 0) {
          //     console.log("FINISHING 1");
          //     this.installMessage = 'finishing';
          //     this.newVersion = null;
          //     await fetchData.post('/esp_reboot');
          //     setTimeout(() => {
          //       window.location.reload();
          //     }, 2000);
          //   } else {
          //     this.installMessage = '';
          //     this.updateDeviceErrors = 'Flash Error';
          //   }
          // } catch (e) {
          //   console.log("FINISHING 2", e);
          //   this.installMessage = 'finishing';
          //   await fetchData.post('/esp_reboot');
          //   setTimeout(() => {
          //     window.location.reload();
          //   }, 2000);
          // }
        }
      } else {
        alert("An errors occurred. Please reboot device and try again.")
      }
    },
  },
});
</script>
