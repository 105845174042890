<template>
  <div>
    <div id="preview" :style="'background-color: ' + backgroundColor"></div>
    <div class="row colour-slider">
      <div class="column">
        <input
          type="range"
          id="h"
          min="0"
          max="360"
          :style="'background-image: ' + hBackgroundImage"
          v-model="hVal"
          class="v"
          @input="setColor()"
        />
        <br /><br />
        <input
          type="range"
          id="s"
          min="0"
          max="100"
          :style="'background-image: ' + sBackgroundImage"
          v-model="sVal"
          class="h"
          @input="setColor()"
        />
        <br /><br />
        <input
          type="range"
          id="l"
          min="0"
          class="l"
          max="100"
          :style="'background-image: ' + lBackgroundImage"
          v-model="lVal"
          @input="setColor()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "color-picker",
  data() {
    return {
      backgroundColor: "#FFF",
      hVal: "#FFF",
      sVal: "#FFF",
      lVal: "#FFF",
      hBackgroundImage: null,
      sBackgroundImage: null,
      lBackgroundImage: null,
    };
  },
  props: ["hex", "onChange"],
  watch: {
    hex: function (value) {
      const { h, s, l } = this.hexToHsl(value);
      this.hVal = h;
      this.sVal = s;
      this.lVal = l;
      this.setHslSliders(h, s, l);
      this.setColor();
    },
  },
  methods: {
    hslToHex(h: any, s: any, l: any) {
      l /= 100;
      const a = (s * Math.min(l, 1 - l)) / 100;
      const f = (n: any) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
          .toString(16)
          .padStart(2, "0"); // convert to Hex and prefix "0" if needed
      };
      return `#${f(0)}${f(8)}${f(4)}`;
    },
    setColor() {
      const hex = this.hslToHex(this.hVal, this.sVal, this.lVal);
      this.backgroundColor = hex;
      this.onChange(hex);

      this.setGradient("h", [
        this.hsl(0, this.sVal, this.lVal),
        this.hsl(60, this.sVal, this.lVal),
        this.hsl(120, this.sVal, this.lVal),
        this.hsl(180, this.sVal, this.lVal),
        this.hsl(300, this.sVal, this.lVal),
        this.hsl(360, this.sVal, this.lVal),
      ]);
      this.setGradient("s", [
        this.hsl(this.hVal, 0, this.lVal),
        this.hsl(this.hVal, 100, this.lVal),
      ]);
      this.setGradient("l", [
        this.hsl(this.hVal, this.sVal, 0),
        this.hsl(this.hVal, this.sVal, 50),
        this.hsl(this.hVal, this.sVal, 100),
      ]);
    },
    setGradient(slider: string, steps: any) {
      let gradientString = "linear-gradient(to right,";

      const stepSize = 100 / (steps.length - 1);

      for (let i = 0; i < steps.length; i++) {
        gradientString += (i > 0 ? "," : "") + steps[i] + i * stepSize + "%";
      }

      this[slider + "BackgroundImage"] = gradientString + ")";
    },
    hsl(h: string, s: string, l: string) {
      return "hsl(" + h + "," + s + "%," + l + "%)";
    },
    setHslSliders(h: any, s: any, l: any) {
      this.hVal = h;
      this.sVal = s;
      this.lVal = l;
    },
    hexToHsl(hex: string) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);

      (r /= 255), (g /= 255), (b /= 255);
      const max = Math.max(r, g, b),
        min = Math.min(r, g, b);
      let h,
        s,
        l = (max + min) / 2;

      if (max == min) {
        h = s = 0; // achromatic
      } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
      }

      s = s * 100;
      s = Math.round(s);
      l = l * 100;
      l = Math.round(l);
      h = Math.round(360 * h);
      return { h, s, l };
    },
  },
  mounted() {
    const { h, s, l } = this.hexToHsl(this.hex);
    this.hVal = h;
    this.sVal = s;
    this.lVal = l;
    this.setHslSliders(h, s, l);
    this.setColor();
  },
});
</script>

<style lang="scss">
.colour-slider .v,
.colour-slider .h {
  border: 1px solid gray;
}

#preview {
  display: inline-block;
  float: left;
  width: 100%;
  height: 100px;
  margin-bottom: 5px;
  border: 1px solid gray;
}
input[type="range"] {
  float: left;
  width: 100%;
  -webkit-appearance: none;
}

.colour-slider {
  justify-content: center;
}

.column {
  display: inline-block;
  width: 100%;
  margin-right: 32px;
  float: left;
}

.column:nth-child(2n) {
  margin-right: 0;
}

.colour-slider input[type="range"] {
  float: left;
  width: 100%;
  -webkit-appearance: none;
  height: 50px;
}

input[type="range" i]::-webkit-slider-container {
  margin: -10px;
}

.colour-slider input[type="range"],
.colour-slider #preview {
  margin: 15px;
}

.colour-slider input[type="range"]::-moz-range-track {
  border: inherit;
  background: transparent;
  color: transparent;
}

.colour-slider input[type="range"]::-ms-track {
  border: inherit;
  background: transparent;
  color: transparent;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  position: relative;
  height: 20px;
  width: 20px;
  margin-top: 45px;
  cursor: pointer;
  border-style: solid;
  text-align: center;
  //border-width: 0 10px 20px 10px;
  // border-color: black transparent #9998a0 transparent;
  transform: rotate(-45deg);
  border: 1px solid #fff;
  background: #9998a0;
  border-radius: 100% 0% 100% 100%;
  // left: 10px;
  // right: 10px;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 60px 120px 60px;
  border-color: transparent transparent #4f46e5 transparent;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  height: 10px;
  width: 6px;
  border-style: solid;
  border-width: 0 60px 120px 60px;
  border-color: transparent transparent #4f46e5 transparent;
}
</style>