<template>
  <svg
    width="75"
    height="28"
    viewBox="0 0 75 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.72368 6.81211C5.18596 8.78772 1.94474 12.0804 0 23.2755C0 23.9341 0.129649 25.3828 0.648245 25.9097C1.29649 26.5682 2.59298 27.2267 3.24123 27.2267C5.18596 27.2267 11.9005 25.1333 16.8544 22.617C27.2263 17.3487 32.4123 12.0804 48.6184 12.0804C68.0766 12.0804 73.1731 23.3554 74.5482 21.9585C75.8447 20.6414 74.5482 16.6902 68.0658 10.7633C61.5833 4.8365 55.7491 0.226746 43.4324 0.226746C31.1158 0.226746 14.2614 4.8365 9.72368 6.81211Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "eyebrows",
  mixins: [IconMixin],
});
</script>
