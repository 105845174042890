<template>
  <div>
    <h3 v-if="orientation == 'landscape'" style="text-align: center;font-weight">
      {{ $t("hand_gesture") }}
    </h3>
    <div class="canvas-container">
      <canvas id="camera" ref="canvasRef"></canvas>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { DeviceOrientationMixin } from "../mixins";
import * as handpose from "@tensorflow-models/handpose";
import * as fp from "fingerpose";
import "@tensorflow/tfjs";
// import '@tensorflow/tfjs-backend-cpu';
// import '@tensorflow/tfjs-backend-webgl';

declare let window: any;


export default Vue.extend({
  name: "HandPositionGesture",
  mixins: [DeviceOrientationMixin],
  data() {
    return {
      wifiOn: false
    }
  },
  async mounted() {
    let canvasMain = document.getElementById("camera");
    console.log("canvasMain", canvasMain);
    (window as any).plugin.CanvasCamera.initialize(canvasMain);
    // define options
    var opt = {
      quality: 75,
      // destinationType: (window as any).plugin.CanvasCamera.DestinationType.DATA_URL,
      // encodingType: (window as any).plugin.CanvasCamera.EncodingType.JPEG,
      // saveToPhotoAlbum:true,
      correctOrientation: true,
      width: 200,
      height: 300
    };
    // (window as any).plugin.CanvasCamera.setCameraPosition((window as any).plugin.CanvasCamera.CameraPosition.FRONT);
    console.log("CanvasCamera Initialized.");
    (window as any).plugin.CanvasCamera.start(opt);
    console.log("CanvasCamera Started.");
    const net = await handpose.load();
    setInterval(async () => {
      const hand = await net.estimateHands(this.$refs.canvasRef);
      if (hand.length > 0) {
        console.log("this is", hand[0].landmarks)    
    }
    }, 1000);
  },
  methods: {
    
  }
});
</script>

<style lang="scss">
.settings {
  .control-value {
    margin-top: 0px;
  }
  .canvas-container {
    margin: 0 auto;
  }
}
</style>