var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"yd-root",style:({height:  (_vm.viewHeight) + 'px',width: _vm.viewWidth + 'px',}),on:{"touchstart":_vm.touchStart,"touchend":_vm.touchEnd}},[_c('div',{staticClass:"yd-top-mask yd-mask",style:({height: (_vm.viewHeight - _vm.scrollItemHeight - 1) / 2 + 'px', top: 0})}),_c('div',{ref:"scrollContainer",staticClass:"yd-scroll-container",style:({ 
      width: '100%',
      height: _vm.scrollHeight + 'px',
      transform: `translate(0, -${(_vm.scrollHeight) / 2 - _vm.viewHeight / 2}px)`
    })},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,ref:"item",refInFor:true,staticClass:"yd-item",style:({
        height: _vm.scrollItemHeight + 'px',
        width: _vm.viewWidth + 'px',
        top: `${(_vm.scrollHeight - _vm.scrollItemHeight) / 2}px`,
        opacity: _vm.itemDatas[index] && _vm.itemDatas[index].visible ? Math.abs(_vm.itemDatas[index].rotate) > 13 ? _vm.mapDGOpacity(index) : '1' : '0',
        transform: `rotateX(${_vm.itemDatas[index] ? _vm.itemDatas[index].rotate : 0}deg) translate3d(0, ${_vm.itemOffsetY(index)}px, ${_vm.itemOffsetZ(index)}px)`,
      })},[(!_vm.$scopedSlots.item)?_c('div',{staticClass:"yd-item-content"},[_vm._v(" "+_vm._s(Math.floor(_vm.itemDatas[index]))+" ")]):_vm._t("item",null,{"item":item})],2)}),0),_c('div',{staticClass:"yd-bottom-mask yd-mask",style:({height: (_vm.viewHeight - _vm.scrollItemHeight - 1) / 2 + 'px', bottom: 0})})])
}
var staticRenderFns = []

export { render, staticRenderFns }