<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 78 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      d="M1.99993 21.2317C15.0549 9.06506 28.3053 2.24186 43.268 6.22191C58.2308 10.202 62.0995 19.1976 75.371 19.6999"
      :stroke="color"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.3995 33.0267C43.3524 33.0267 49.7995 26.5796 49.7995 18.6267C49.7995 10.6738 43.3524 4.22675 35.3995 4.22675C27.4466 4.22675 20.9995 10.6738 20.9995 18.6267C20.9995 26.5796 27.4466 33.0267 35.3995 33.0267ZM30.5993 19.8267C33.2503 19.8267 35.3993 17.6777 35.3993 15.0267C35.3993 12.3758 33.2503 10.2267 30.5993 10.2267C27.9483 10.2267 25.7993 12.3758 25.7993 15.0267C25.7993 17.6777 27.9483 19.8267 30.5993 19.8267Z"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "eye2",
  mixins: [IconMixin],
});
</script>
