import Icon from "../icons/Icon.vue";
import { DeviceOrientationMixin } from "../mixins";
import slider from "../slider.vue";
import Timer from "../timer.vue";
import fetchData from '@/fetchData'
import vueIosRollerPicker from '../vueIosRollerPicker'
import Vue from "vue";
import { sleep } from '@/helpers';


Vue.use(vueIosRollerPicker)
const theme = "professional"

export const ProfessionalMixin = {
  props: ['bus'],
  components: { slider, Icon, Timer },
  name: "Professional",
  mixins: [DeviceOrientationMixin],
  data() {
    return {
      bleWaitingCount: 0,
      synced: false,
      currentSinBtn: -1,
      // audio: new Audio("sounds/tick.ogg"),\
      r1Key: 0,
      r2Key: 0,
      r3Key: 0,
      r4Key: 0,
      r1Index: 100,
      r2Index: 100,
      r3Index: 100,
      r4Index: 100,
      freq: 60,
      duty: 30,
      level: 90,
      duty1: 100,
      duty2: 0,
      carouselIndex: 0,
      rollerRange: [100, -100],
      s1: 0,
      s2: 0,
      s3: 0,
      s4: 0,
      r1: 0,
      r2: 0,
      maxValue1: 100,
      maxValue2: 100,
      sinRequest: "",
      counter: null,
      sinPickerKey: false,
      rollerWidth: 80,
      rollerHeight: 250,
      values: [],
      options: {loop:false, height: 300, width: 70, opacity: 6}
    };
  },
  created: function () {
    console.log("CREATED...........................")
    this.values = this.getValues()
    this.bus.$off('sin-click');
    this.bus.$on("sin-click", (data)=>{
      this.carouselIndex = data.carouselIndex;
      if (this.carouselIndex == 0) {
        this.bus.$emit("sin-sync", {
          s1: this.s1,
          s2: this.s2,
          s3: this.s3,
          s4: this.s4,
        })
      }
    })
    // if (this.deviceHeight / this.deviceWidth > 1.4) {
      this.lockScreenOrientation('portrait');
    // }
    
    // setTimeout(() => {
      
      this.sync();
  // }, 1000);
  },
  destroyed() {
    // this.bus.$off('sin-click')
  },
  mounted: function () {
    // if (this.deviceHeight / this.deviceWidth > 1.4) {
      this.lockScreenOrientation('portrait');
    // } 
  },
  watch: {
    '$store.state.lockInterface': function () {
      if (!this.synced) {
        this.sync();
      }
    },
    '$store.state.freqFromVoice': function (value) {
      this.freq = value;
    },
    '$store.state.levelFromVoice': function (value) {
      this.level = value;
    },
    '$store.state.freqNotification': function (value) {
      this.freq = value;
    },
    '$store.state.levelNotification': function (value) {
      this.level = value;
    },
    '$store.state.duty2Notification': function (value) {
      this.duty2 = value;
    }
  },
  methods: {
    onOrientationChange() {
      if (this.deviceHeight / this.deviceWidth > 1.4) {
        this.lockScreenOrientation('portrait');
      } 
    },
    async sync() {

      if (this.$store.state.useBluetooth && !this.$store.state.bleInitialized && this.bleWaitingCount < 25) {
        console.log("Waiting BLE Device. Proff, Attemp:", this.bleWaitingCount)
        await sleep(200);
        this.bleWaitingCount++
        await this.sync()
        return
      } else {
        console.log("Waiting BLE Device. Proff READY")
      }

      fetchData.post("/SAVE_DELAY_SETTINGS", {
        d: 0,
        p: 50,
      })

      this.$store.dispatch("loadState", { theme: "professional", num: 1 }).then(response => {
        if (!response.data.f) return;
        this.freq = response.data.f;
        this.duty = response.data.d;
        this.level = response.data.l;
        this.duty1 = response.data.d1;
        this.duty2 = response.data.d2;
        this.s1 = response.sin.s1;
        this.s2 = response.sin.s2;
        this.r1 = this.s1;
        this.r2 = this.s2;
        this.s3 = response.sin.s3;
        this.s4 = response.sin.s4;

        this.r1Index = this.getIndexByValue(this.r1);
        this.r2Index = this.getIndexByValue(this.r2);
        this.r3Index = this.getIndexByValue(this.s3);
        this.r4Index = this.getIndexByValue(this.s4);

        this.bus.$emit("sin-sync", {
          s1: this.s1,
          s2: this.s2,
          s3: this.s3,
          s4: this.s4,
        })

        if (response.pedal.on) {
          this.$store.dispatch("switchPedal");
        }
        this.synced = true;
      }).catch(reason => {
        console.log("Load state error: professional", reason)
      });

      this.$store.dispatch("saveSin", { btnNum: 1, sinNum: 1, value: window.localStorage.getItem('sin1PresetS1') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 1, sinNum: 2, value: window.localStorage.getItem('sin1PresetS2') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 1, sinNum: 3, value: window.localStorage.getItem('sin1PresetS3') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 1, sinNum: 4, value: window.localStorage.getItem('sin1PresetS4') || 0 });


      this.$store.dispatch("saveSin", { btnNum: 2, sinNum: 1, value: window.localStorage.getItem('sin2PresetS1') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 2, sinNum: 2, value: window.localStorage.getItem('sin2PresetS2') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 2, sinNum: 3, value: window.localStorage.getItem('sin2PresetS3') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 2, sinNum: 4, value: window.localStorage.getItem('sin2PresetS4') || 0 });

      this.$store.dispatch("saveSin", { btnNum: 3, sinNum: 1, value: window.localStorage.getItem('sin3PresetS1') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 3, sinNum: 2, value: window.localStorage.getItem('sin3PresetS2') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 3, sinNum: 3, value: window.localStorage.getItem('sin3PresetS3') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 3, sinNum: 4, value: window.localStorage.getItem('sin3PresetS4') || 0 });

      this.$store.dispatch("saveSin", { btnNum: 4, sinNum: 1, value: window.localStorage.getItem('sin4PresetS1') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 4, sinNum: 2, value: window.localStorage.getItem('sin4PresetS2') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 4, sinNum: 3, value: window.localStorage.getItem('sin4PresetS3') || 0 });
      this.$store.dispatch("saveSin", { btnNum: 4, sinNum: 4, value: window.localStorage.getItem('sin4PresetS4') || 0 });
    },
    sinReset() {
      this.s1 = 0;
      this.s2 = 0;
      this.s3 = 0;
      this.s4 = 0;
      this.r1 = 0;
      this.r2 = 0;
      this.r1Index = this.getIndexByValue(this.s1);
      this.r2Index = this.getIndexByValue(this.s2);
      this.r2Index = this.getIndexByValue(this.s3);
      this.r2Index = this.getIndexByValue(this.s4);
      setTimeout(() => {
        fetchData.post("/SAVE_SIN_SETTINGS", this.$refs.sin.req);
      }, 100);
    },
    onStart() {
      this.$store.dispatch("switchPedal");
    },
    
    sinStop(btnNum) {
      clearInterval(this.timer);
      btnNum = this.currentSinBtn;
      console.log("SIN STOP ------------------------------------")
      if (this.currentSinBtn == -1) {
        return false;
      }
      console.log("CLEAR INTERVAL")
      if (this.counter > 4) {
        console.log("***************************1", btnNum, this.counter);
        this.$store.dispatch("saveSin", { btnNum, sinNum: 1, value: this.s1 });
        this.$store.dispatch("saveSin", { btnNum, sinNum: 2, value: this.s2 });
        this.$store.dispatch("saveSin", { btnNum, sinNum: 3, value: this.s3 });
        this.$store.dispatch("saveSin", { btnNum, sinNum: 4, value: this.s4 });
        this.currentSinBtn = -1;
      } else {
        this['s1'] = this.$store.state['sin' + btnNum + 'PresetS1'];
        this['s2'] = this.$store.state['sin' + btnNum + 'PresetS2'];
        this['s3'] = this.$store.state['sin' + btnNum + 'PresetS3'];
        this['s4'] = this.$store.state['sin' + btnNum + 'PresetS4'];
        this.r1 = this.s1;
        this.r2 = this.s2;
        this.r1Index = this.getIndexByValue(this.s1);
        this.r2Index = this.getIndexByValue(this.s2);
        this.r3Index = this.getIndexByValue(this.s3);
        this.r4Index = this.getIndexByValue(this.s4);

        this.currentSinBtn = -1;
        setTimeout(() => {
          fetchData.post("/SAVE_SIN_SETTINGS", this.$refs.sin.req)
        }, 100);
        this.counter = 0;
        return false;
      }
      this.counter = 0;
    },
    sinStart(btnNum) {
      clearInterval(this.timer);
      this.currentSinBtn = btnNum;
      const that = this;
      this.timer = setInterval(() => {
        that.counter++;
        console.log(that.counter)
        if (that.counter > 4) {
          clearInterval(that.timer)
        }
      }, 100);
    },
    getIndexByValue(value) {
      let i = 0;
      for (const v of this.values) {
        if (v.value == value) {
          return i;
        }
        i++;
      }
    },
    rollerOnChange(key, index, final) {
      
      const value = this.values[index].value
      this["s" + key] = value;
      const max = Math.abs(this.rollerRange[0]);

      switch (key) {
        case 1:
          if (final) this.r1Index = index;
          if (Math.abs(value) >= max - Math.abs(this.s2)) {
            // this.$refs.roller1.stop();
            const s1 = max - Math.abs(this.s2);
            if (this.s1 < 0) {
              this.s1 = -s1;
            } else {
              this.s1 = s1;
            }

            this.r1 = this.s1;
            this.maxValue1 = this.r1;
            if (final) this.r1Index = this.getIndexByValue(this.r1);
          } else {
            if (this.s1 < 0) {
              this.maxValue1 = -max;
            } else {
              this.maxValue1 = max;
            }
          }
          if (final) this.r1Key++;
          break;
        case 2:
          if (final) this.r2Index = index;
          if (Math.abs(value) >= max - Math.abs(this.s1)) {
            // this.$refs.roller2.stop();
            const s2 = max - Math.abs(this.s1);
            if (this.s2 < 0) {
              this.s2 = -s2;
            } else {
              this.s2 = s2;
            }
            this.r2 = this.s2;
            this.maxValue2 = this.r2;
            if (final) this.r2Index = this.getIndexByValue(this.r2);
          } else {
            if (this.s2 < 0) {
              this.maxValue2 = -max;
            } else {
              this.maxValue2 = max;
            }
          }
          if (final) this.r2Key++;
          break;
        case 3:
          if (final) this.r3Index = index;
            break;
        case 4:
          if (final) this.r4Index = index;
          break;
        default:
          break;
      }
      if (final) {
        fetchData.post("/SAVE_SIN_SETTINGS", JSON.parse(this.$refs.sin.req))
      }
    },
    addPreset(iconName) {
      const preset = {
        iconName: iconName,
        freq: this.freq,
        duty: this.duty,
        level: this.level,
        duty1: this.duty1,
        duty2: this.duty2,
        s1: this.s1,
        s2: this.s2,
        s3: this.s3,
        s4: this.s4,
      }

      this.$store.dispatch("addPreset", { theme, preset });
    },
    selectPreset(preset, trashIsOpen) {
      const key = preset.key;
      this.$store.dispatch("selectPreset", { theme, key, trashIsOpen });
      this.freqValue = preset.freq;
      this.dutyValue = preset.duty;
      this.levelValue = preset.level;
      this.duty1Value = preset.duty1;
      this.duty2Value = preset.duty2;
      this.s1 = preset.s1;
      this.s2 = preset.s2;
      this.r1 = this.s1;
      this.r2 = this.s2;
      this.s3 = preset.s3;
      this.s4 = preset.s4;
      const sin = this.$refs.sin;
      if (sin) {
        setTimeout(() => {
          fetchData.post("/SAVE_SIN_SETTINGS", sin.req)
        }, 100);
      }
      
      if (trashIsOpen) {
        return
      }
      if (preset.selected && !this.$store.state.pedalOn) {
        this.onStart()
      }
      if (!preset.selected && this.$store.state.pedalOn) {
        this.onStart()
      }
    },
    removeSelectedPresets() {
      this.$store.dispatch("removeSelectedPresets", theme);
    },
    getValues() {
      const values = [];
      for (let i = this.rollerRange[0]; i >= this.rollerRange[1]; i--) {
        values.push({
          value: i,
          text:
            i < 0 ? '-' + String(Math.abs(i)) : i,
        });
      }
      return values;
    },
  },
  computed: {
    cssVars() {
      return {
      "--color": this.$store.getters.currentThemeShade.color,
      }
    },
    freqValue: {
      get() {
        return this.freq;
      },
      set(val) {
        this.freq = val
        fetchData.post("/api", {
          n: 1,
          f: val,
        })
      },
    },
    dutyValue: {
      get() {
        return this.duty;
      },
      set(val) {
        this.duty = val
        fetchData.post("/api", {
          n: 1,
          d: val,
        })
      },
    },
    levelValue: {
      get() {
        return this.level;
      },
      set(val) {
        this.level = val
        fetchData.post("/api", {
          n: 1,
          l: val,
        })
      },
    },
    duty1Value: {
      get() {
        return this.duty1;
      },
      set(val) {
        this.duty1 = val
        fetchData.post("/api", {
          n: 1,
          d1: val,
        })
      },
    },
    duty2Value: {
      get() {
        return this.duty2;
      },
      set(val) {
        this.duty2 = val
        fetchData.post("/api", {
          n: 1,
          d2: val,
        })
      },
    },
  },

}
