<template>
  <svg
    width="75"
    height="28"
    viewBox="0 0 75 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.36063 24.8625C0.390437 20.1513 1.59098 16.6172 2.45605 14.123"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.78049 27.1231C3.19188 18.9802 4.58326 13.9341 5.99976 9.62317"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.99976 25.623C6.05937 17.2475 8.76993 12.0572 10.5001 7.62305"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.9998 24.623C9.25411 16.1316 12.6537 10.4312 15.0001 6.12299"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.4999 23.6231C12.5002 14.1231 16.6582 8.97784 19.4573 4.90894"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.3216 22.2679C16.3213 13.1785 20.2392 8.39879 25.0988 3.68213"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.528 20.6709C20.1631 13.0153 25.6068 6.94898 29.685 3.00359"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.5694 18.7809C24.6591 11.173 30.3782 6.40933 34.1872 2.28692"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.8006 16.4695C30.3399 9.40265 34.4609 5.89135 38.7709 1.77753"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.6587 14.55C35.397 8.13812 39.3984 5.28302 43.3156 1.6604"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.7521 13.3355C39.6565 8.62425 44.2112 4.7679 47.3509 2.05229"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M42.1213 12.5128C44.0251 8.29817 46.9405 5.49988 51.2108 2.87489"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M45.8236 12.3462C48.1234 8.27845 51.5235 5.69638 55.3322 3.92966"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M49.3987 12.4613C51.3033 9.51679 54.1502 7.26099 57.9589 5.49427"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M53.1381 12.4405C54.8255 9.83146 57.6377 7.74425 60.4479 6.92328"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.8527 13.1582C57.6132 10.7965 59.524 9.55126 62.9376 8.62814"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M58.2428 13.8058C59.9272 12.0034 62.4346 10.7497 65.2949 10.4755"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M61.0201 14.726C62.5485 13.2958 64.5828 12.3952 67.2142 12.3952"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.9232 15.9604C65.5691 14.9419 67.6064 14.5502 70.1134 15.3335"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M67.0177 17.5697C69.023 16.9159 70.6207 17.2163 72.384 18.1175"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M69.6144 19.5727C70.7606 19.7051 72.479 20.2365 73.6245 21.2993"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "eyebrows1",
  mixins: [IconMixin],
});
</script>
