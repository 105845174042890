<template>
  <svg
    width="67"
    height="69"
    viewBox="0 0 67 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="33.6574"
      cy="35.0565"
      r="4.36246"
      :stroke="color"
      stroke-width="3"
    />
    <circle
      cx="33.6572"
      cy="35.0565"
      r="11.3974"
      :stroke="color"
      stroke-width="3"
    />
    <path
      d="M65.3145 35.0829C65.3145 40.2127 64.0659 45.2655 61.6762 49.8057C59.2866 54.3459 55.8276 58.2372 51.5975 61.1443C47.3675 64.0513 42.4933 65.8868 37.3952 66.4925C32.297 67.0982 27.128 66.4559 22.3336 64.621C17.5392 62.7861 13.2635 59.8137 9.87488 55.96C6.4863 52.1063 4.08661 47.487 2.8827 42.5002C1.67878 37.5135 1.70678 32.3091 2.96427 27.3356C6.10372 18.6416 8.19502 18.6416 9.03495 11.6067C9.70689 5.97872 8.14243 3.00841 7.27621 2.22675"
      :stroke="color"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>

<script lang="ts">
import Vue from "vue";
import { IconMixin } from "../mixins";

export default Vue.extend({
  name: "disk",
  mixins: [IconMixin],
});
</script>
