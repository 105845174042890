<template>
    <div class="three-state-switch">
        <div
            class="v-input switch v-input--is-label-active v-input--is-dirty v-input--selection-controls v-input--switch v-input--switch--inset 
            " :class="[state != 0 && 'primary--text', $vuetify.theme.dark ? 'theme--dark': 'theme--light']">
            <div class="v-input__control">
                <div class="v-input__slot">
                    <div class="v-input--selection-controls__input" @click="click">
                        <div class="v-input--switch__track theme--dark" :class="state != 0 && 'primary--text'"></div>
                        <div class="v-input--switch__thumb theme--dark" :class="state != 0 && 'primary--text'" :style="style">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    name: "ThreeStateSwitch",
    data() {
        return {
            state: 0,
            style: ""
        };
    },
    props: ["value"],
    methods: {
        click(e) {
            let _state = 1
            if (e.offsetX < 25) {
                _state = 0
                // this.state = 0
                // this.style = "transform: translate(0px, 0) !important;"
            } else if (e.offsetX > 40) {
                _state = 2
            }
            if ((this.state == 0 || this.state == 2) && (this.state != _state)) {
                _state = 1
            }
            this.state = _state
            this.style = "transform: translate(" + _state * 20 + "px, 0) !important;"
            this.$emit('change', _state)
        },
    },
    mounted() {
        let that = this;
        Vue.nextTick(function () {
            console.log("-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=---=-", that.$props.value);
            that.state = that.$props.value;
            that.style = "transform: translate(" + that.$props.value * 20 + "px, 0) !important;"
        });
    },

});
</script>

<style lang="scss">
.three-state-switch {
    .v-input--switch--inset .v-input--switch__track, .v-input--switch--inset .v-input--selection-controls__input {
        width: 68px;
    }
}
</style>